﻿@import "variables";
@import "mixins/ng-animate";

.force-invalid,
.ng-submitted .touched:not(.ng-pristine).ng-invalid, // jag lade till .ng-submitted så att datepicker inte ska visa röd linje när man clearat textboxen innan formuläret postats
.ng-submitted .required.not-full,
.ng-submitted .ng-invalid
// input.ng-dirty.ng-invalid:not([type='radio']) // ska vi verkligen visa röd linja innan submit? kommenterar ut
{
	border-bottom-color: $bright-colors_red !important;
}

.validation-errors {
	.ng-inactive {
		display: none;
	}

	@include animate-show;

	margin: 40px 0;

	.embedded {
		position: absolute;
		top: 100%;
		left: 0px;
		margin: 5px 0 0 0;
	}

	background-color: $bright-colors_salmon;
	padding: 15px;
	color: #b55848;
	font-size: 14px;
	font-weight: 400;
	z-index: 3;
	border-radius: 30px;
	text-align: center;

	a, a:hover, a:active, a:visited {
		color: #a04f41;
		border-bottom: dashed 1px #b55848bf;
		padding-bottom: 1px;
	}
}