﻿// Users index

@import "../mixins/material-deisgn-icons";

#organizationSettings-users-index-view > .page-content {
	max-width: 1100px;

	.add {
    	width: 42px;
	    padding: 0;
	    padding-left: 7px;
	    float: right;
	    margin-top: -20px;
	    line-height: 45px;
        margin-bottom: 20px;
	    @include mdi-icon(plus, 22px, 0, 2px);
    }
}

// Form

#organizationSettings-users-form-view > .page-content {
	width: 760px;

	> div > h1 {
		margin: 20px 0 70px;
	}

	form > section > h1 {
		width: 220px;
	}

	label.first-name {
		width: 160px;
		margin-right: 20px;
	}

	label.last-name {
		width: 220px;
	}

	form > section:first-of-type > .fields-container {
		justify-content: normal;
	}
}