﻿@use "sass:math";
@import "../variables";

@mixin size($w, $h) {
	height: $h;
	width: $w;
}

@mixin draw-progress($progress, $color) {
	.pie {

		@if ($progress <= 50) {
			.half-circle { border-color: $color; }

			.left-side {
				$rotate: $progress * 3.6;
				transform: rotate(#{$rotate}deg);
			}

			.right-side {
				display: none;
			}
		}

		@if ($progress > 50) {
		
			.half-circle { border-color: $color; }

			.left-side {
				$rotate: $progress * 3.6;
				transform: rotate(#{$rotate}deg);
			}

			clip: rect(auto, auto, auto, auto);

			.right-side {
				transform: rotate(180deg);
			}
		}

	}
}

$size: 90px;

.pie-wrapper {
	@include size($size, $size);
	//float: left;
	display: inline-block;
	margin: 15px;
	position: relative;
	box-sizing: border-box;
	font-size: 1em;

	*,
	*:before,
	*:after {
		box-sizing: border-box;
	}

	.pie {
		@include size(100%, 100%);
		clip: rect(0, $size, $size, math.div($size, 2));
		left: 0;
		position: absolute;
		top: 0;

		.half-circle {
			@include size(100%, 100%);
			border: math.div($size, 10) solid #3498db;
			border-radius: 50%;
			clip: rect(0, math.div($size, 2), $size, 0);
			left: 0;
			position: absolute;
			top: 0;
		}
	}

	.label {
		$font-size: math.div($size, 4);
		$font-size-redo: $size * 4;

		background: none;
		color: #7f8c8d;
		border-radius: 50%;
		bottom: 0;
		cursor: default;
		font-size: $font-size;
		left: 0;
		position: absolute;
		right: 0;
		text-align: center;
		top: 0;
		font-family: $muli;

		display: flex;
		flex-direction: column;
		justify-content: center;

		> .value {
			order: 2;
		}

		.unit {
			color: #bdc3c7;
			font-size: .45em;
			vertical-align: super;
			margin-left: .05em;
			position: absolute;
		}

		> .gauge-title {
			font-size: 12px;
			margin: 0 19%;
			margin-bottom: .5em;
			font-weight: 300;
			text-transform: uppercase;
			border-bottom: solid 1px silver;
			padding-bottom: .5em;
			margin-top: .1em;
			order: 1;
		}

		> .label-text {
			font-size: 14px;
			font-weight: 300;
			order: 3;
		}
	}

	.shadow {
		@include size(100%, 100%);
		border: math.div($size, 10) solid #bdc3c7;
		border-radius: 50%;
	}

	@for $i from 1 through 100 {
		&.progress-#{$i} {
			@include draw-progress($i, #3498db);
		}
	}
}