﻿#useraccount-view > .page-content {
	max-width: 820px;
	margin: 0 auto;

	> h1 {
		margin-bottom: 50px;
	}

	form > section:nth-of-type(3) > div:not(.fields-container) {
		margin-bottom: 40px;
	}
}