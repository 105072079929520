﻿@import "../mixins/webkitscrollbar";
@import "../font-awesome";
@import "../mixins/ng-animate";
@import "../treecontrol";
@import "../mixins/callout-arrow";
@import "../mixins/checkbox";
@import "../mixins/animations";
@import "../mixins/_responsive.scss";
@import "../mixins/impromptu-animation";

[id^="items-items-bulkcreate"] .page-content {
	$variants-background-color: #F2E8EF;

	> h1 {
		margin-bottom: 1.5em;
	}

	.wizard-step-indicator {
		margin: 0 auto;
		margin-top: 60px;
		margin-bottom: 40px;

		&.steps-2 { width: 180px; }

		&.steps-3 { width: 290px; }
	}

	section, form {
		@include animate-show(.2s, .3s);

		> h2 {
			text-align: center;
			margin-bottom: 30px;
		}
	}

	section#stock1 {
		text-align: center;
		width: 450px;
		margin: 80px auto;
		background: #fbfbfb;
		padding: 50px 100px 40px;
		border-radius: 20px;

		> h3 {
			line-height: 1.4;
			margin-bottom: 2em;
		}

		> button {
			min-width: 130px;
			margin: 5px;
		}
	}

	section#stock2 {
		margin-top: 60px;
		text-align: center;

		p {
			margin: 0 auto;
			margin-bottom: 50px;
			width: 400px;
			text-align: center;
		}

		ul {
			width: 500px;
			margin: 0 auto;
			overflow: hidden;
            text-align: left;

			> li {
				box-sizing: border-box;
				padding: 5px;
				width: 50%;
				display: inline-block;

				> label {
					width: 100%;
				}

				&:only-child {
					float: none;
				}
			}
		}

		a {
			@include mdi-icon(plus, 16px);
			margin-top: 20px;
			display: inline-block;
		}

		button {
			margin: 5px;
			margin-top: 40px;
		}
	}

	%items-table-section {
		margin: 0 auto;

		.tip {
			color: #579ABE;
			@include fa-icon($fa-var-question-circle);
		}

		> .headers {
			display: table;
			background-color: #f5f5f5;
			z-index: 2;
			padding-top: 5px;
			position: sticky;
			top: 44px;
			box-shadow: 0px -2px 1px 1px #f5f5f5;

			&.stuck {
				
			}

			> span {
				text-align: left;
				vertical-align: bottom;
				padding: 10px 7px 8px;
				font-size: 14px;
				color: #333;
				display: table-cell;
				border-bottom: solid 1px #dfdfdf;
				box-sizing: border-box;

				&.right-adjusted {
					text-align: right;
				}
			}
		}

		> table.lines {
			box-shadow: 0 1px 0 rgba(0, 0, 0, 0.02);
			line-height: unset;

			> tbody {
				> tr {
					&.animate-repeat.ng-enter {
						transition: opacity ease 0.2s 0.1s;
						opacity: 0;
					}

					&.animate-repeat.ng-enter.ng-enter-active {
						opacity: 1;
					}

					&.animate-repeat.ng-leave {
						transition: opacity ease 0.2s;
						opacity: 1;
					}

					&.animate-repeat.ng-leave.ng-leave-active {
						opacity: 0;
					}

					&:not(:last-child) {
						&:hover > td:last-child > .remove-row {
							transition: right .3s .1s, width .3s .1s;
							right: -30px;
							width: 30px;
						}

						> td:last-child {
							position: relative;

							> .remove-row {
								@include mdi-icon(trash-can-outline, $font-size: 20px, $margin-right: 0, $color: #7eb2ce, $vertical-align: -1px);
								position: absolute;
								top: 0;
								right: 0;
								width: 0;
								height: 36px;
								background: #e8e8e8;
								border-radius: 0 10px 10px 0;
								text-align: center;
								overflow: hidden;
								transition: right .2s, width .2s;
								line-height: 36px;
								cursor: pointer;

								&:hover:before {
									color: #606060;
								}
							}
						}
					}

					> td {
						&.right-adjusted > input,
						&.right-adjusted > .label,
						&.right-adjusted .selectize-input
						{
							text-align: right;
						}
					}
				}
			}
		}

		> .paging {
			margin-top: 40px;
			margin-bottom: -20px;
			text-align: center;
		}
	}

	#items {
		@extend %items-table-section;

		width: 1332px;

		position: relative;

		> #reset {
			position: absolute;
			right: 0;
			top: -120px;
		}

		$varugrupp-width: 222px;
		$artikelnummer-width: 130px;
		$namn-width: 250px;
		$beskrivning-width: 150px;
		$pris-width: 120px;
		$moms-width: 70px;
		$leverantör-width: 200px;
		$vikt-width: 70px;
		$varumärke-width: 140px;

		> .headers {
			> span {
				font-size: 14px;
				color: $bright-colors_dark-gray-text;
				line-height: 1;

				&.item-group {
					width: $varugrupp-width;
				}

				&.item-number {
					width: $artikelnummer-width;
				}

				&.item-name {
					width: $namn-width;
				}

				&.item-description {
					width: $beskrivning-width;
				}

				&.regular-price {
					width: $pris-width;
				}

				&.vat {
					width: $moms-width;
				}

				&.supplier {
					width: $leverantör-width;
				}

				&.weight {
					width: $vikt-width;
				}

				&.brand {
					width: $varumärke-width;
				}

				> i { font-weight: 300; color: #a1a1a1; }
			}
		}

		> table.lines {
			> tbody {
				> tr:not(.variants-row) {
					position: relative;

					> td {
						height: 35px;

						&:not(:last-child) {
							border-right: solid 1px #e4e4e4;
						}

						&.item-group {
							width: $varugrupp-width;
						}

						&.item-number {
							width: $artikelnummer-width;
						}

						&.item-name {
							width: $namn-width;
						}

						&.item-description {
							width: $beskrivning-width;
						}

						&.regular-price {
							width: $pris-width;
						}

						&.vat {
							width: $moms-width;
						}

						&.supplier {
							width: $leverantör-width;
						}

						&.weight {
							width: $vikt-width;
						}

						&.brand {
							width: $varumärke-width;
						}

						> input {
							position: absolute;
							top: 0;
							bottom: 0;
						}

						&.item-description > div {
							padding: 10px 7px 10px;
							cursor: pointer;
							position: absolute;
							background: white;
							width: 100%;
							box-sizing: border-box;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							top: 0;
							bottom: 0;

							&.active {
								box-shadow: inset 0 0 0 1px #bfe2f8;
							}
						}

						&.item-group {
							box-sizing: border-box;
							position: relative;
							vertical-align: top; // annars hamnar innehållet nedskjutet

							> .variants-callout {
								position: absolute;

								display: inline-flex;
								align-items: center;
								justify-content: center;
								padding: 0px 3.5px 1.5px 3px;

								right: 233px;
								min-width: 24px;
								background-color: #8cbddf;
								color: white;
								height: 24px;
								vertical-align: middle;
								cursor: pointer;
								border-radius: 6px;
								text-align: center;
								box-sizing: border-box;
								margin-top: 6px;
								overflow: visible;
								@include animate-inflate(.2s, 40px);

								> .add {
									@include mdi-icon(plus, 16px, 0, -2px);
								}

								> .count {
									font-size: 12px;
									line-height: 16px;
									vertical-align: top;
								}

								&:after {
									content: '';
									position: absolute;
									top: 9px;
									right: -10px;
									border: solid 3px #8cbddf;
									border-right-color: transparent;
									border-top-color: transparent;
									border-bottom-color: transparent;
									display: inline-block;
									transform: scalex(2.6);
								}
							}

							> div {
								padding: 9px 7px 11px;
								cursor: pointer;
								position: absolute;
								background: white;
								width: 100%;
								box-sizing: border-box;
								text-overflow: ellipsis;
								white-space: nowrap;
								overflow: hidden;
								direction: rtl;
								text-align: left;
								bottom: 0;
								top: 0;
								line-height: 16px;


								&.active {
									box-shadow: inset 0 0 0 1px #bfe2f8;
								}

								&:hover {
									@include mdi-icon(chevron-down, 18px, 0, $color: #444);
								}

								&:before {
									position: absolute;
									right: 5px;
									margin-top: -1px;
								}

								> span:not(:first-child) {
									@include mdi-icon(chevron-right, $margin-right: 0, $color: #B6B6B6, $font-size: 15px, $vertical-align: -1px);
								}

								> span:not(:last-child) {
									color: #B6B6B6;
								}
							}
						}

						&.weight {
							position: relative;

							&.has-value:after {
								content: 'g';
								position: absolute;
								right: 10px;
								line-height: 36px;
								color: gray;
								font-weight: 300;
								top: 0;
							}

							> input {
								padding-right: 20px;
							}
						}

						&.supplier, &.brand, &.vat {
							.label {
								background: white;
								position: absolute;
								top: 0;
								left: 0;
								right: 0;
								bottom: 0;
								cursor: pointer;
								padding: 9px 7px 11px;
								line-height: 16px;
							}
						}
					}
				}

				> tr.variants-row {

					> td {
						position: static;
						text-align: center;
						padding: 0;

						> div {
							box-sizing: border-box;
							background-color: #fafafa;
							text-align: left;
							max-height: 0;
							min-height: 0;
							overflow: hidden;
							padding: 0 20px;
							position: relative;
							margin: -15px 0 0 0;
							visibility: hidden;
							border-left: solid 4px #92c4e6;
							box-shadow: 0 0 12px rgba(0, 0, 0, 0.13);

							&:before { 
								content: '';
								display: block;
								position: absolute;
								left: 272px;
								background-color: #fafafa;
								width: 20px;
								height: 20px;
								transform: rotate(45deg);
								box-shadow: 0 0 12px rgba(0, 0, 0, 0.13);
								top: -10px;
							}

							&:after { 
								content: '';
								display: block;
								position: absolute;
								left: 262px;
								background-color: #fafafa;
								width: 38px;
								height: 16px;
								top: 0;
							}

							transition: min-height .4s cubic-bezier(0.020, 1, 0.795, 0.990),
										padding .4s,
										margin .4s,
										max-height .4s cubic-bezier(0.020, 1, 0.795, 0.990);

							&.expanded {
								min-height: 251px;
								max-height: 467px;
								padding: 30px;
								overflow: visible;
								margin: 20px 0;
								visibility: visible;

								&.editing-variants {
									max-height: 900px;
								}
							}

							> .variations {
								position: relative;

								> .switch {
									margin-bottom: 30px;
									display: inline-block;
								}

								> .variation {
									width: 700px;

									> .item-variation-control {
										margin-bottom: 22px;

										.add-variation {
											color: $bright-colors_dark-gray-text;
											@include mdi-icon(plus-circle-outline, 21px, $vertical-align: -2px, $color: #7a7a7a);
											display: inline-block;

											&[disabled], &[disabled]:before {
												color: silver;
											}
										}

										.variation-options .selectize-input {
											white-space: normal;
											height: auto;
										}

										.variation-name {
											@include animate-show($fade-out-duration: 0s);
											background-color: #f0f0f0;
											padding: 25px;
											display: flex;
											align-items: flex-end;
											margin: 20px 0;
											position: relative;

											> .form-field {
												margin: 0;
												width: 300px;
												margin-right: 30px;
											}

											> button {
												margin-right: 10px;
											}

											> .suggestions {
												position: absolute;
												@include animate-show(.4s);
												@include callout-arrow(top-left, 10px, $border-color: rgba(black, .15));
												background-color: white;
												border: solid 1px rgba(black, .15);
												box-shadow: 3px 3px 5px rgba(black, .07);
												top: 100px;
												z-index: 1;

												> p {
													padding: 20px;
													color: #5c5c5c;
												}

												> ul {
													overflow: hidden;

													> li {
														background-color: $bright-colors_baby-blue-2;
														padding: 10px 15px;
														border-top: solid 1px #E9E9E9;
														cursor: pointer;

														&:hover {
															background-color: $bright-colors_baby-blue-1;
														}

														> strong {
															font-weight: bold;
															color: #5c4158;
														}

														> span {
															color: #474747;
														}

														> .options {
															font-size: 14px;
															color: #9f9f9f;
														}
													}
												}
											}
										}

										.variation-options {
											@include animate-slide-in();

											> span {
												padding-bottom: 5px;

												> span.added {
													display: inline-block;
													@include animate-bounce(.6s, 1);
													
												}

												> .variation-commands {
													height: 18px;
													width: 18px;
													line-height: 18px;
													border: solid 1px #d6d6d6;
													border-radius: 18px;
													display: inline-block;
													text-align: center;
													margin-left: 7px;

													@include mdi-icon(dots-horizontal, 15px, $color: #929292, $margin-right: 0, $vertical-align: 0px);

													&:before {
														margin-left: 1px;
													}

													&:hover {
														border-color: #83c9f5;
														&:before {
															color: #76b4d7;
														}
													}
												}
											}

											> .selectize-control > .selectize-input {
												max-height: 75px;
												overflow-y: auto;
											}
										}
									}
								}

								> .edit-variants {
									margin-top: 20px;
									margin-bottom: 12px;
									@include animate-show;
								}

								> .variations-tools {
									display: inline-block;
									max-width: 188px;
									position: absolute;
									right: 20px;
									bottom: 20px;

									> ul {
										> li {
											padding: 8px;

											&:not(:last-child) {
												border-bottom: dotted 1px darken($variants-background-color, 10%);
											}

											> a {
												font-size: 14px;
												vertical-align: middle;

												&.video-help {
													@include fa-icon($fa-var-youtube-play, before, 21px, false, -3px);
												}

												&.item-number {
													@include mdi-icon(table-edit, 21px, 2px, middle);
												}

												&:before { 
													color: #C59EA9;
													width: 30px;
													margin-right: 0 !important;
												}

												&:hover {
													&:before { color: #a77986; }
												}
											}
										}
									}
								}

								> .close {
									position: absolute;
									right: 0px;
									top: 0px;
									@include mdi-icon(close, 21px, $vertical-align: -2px, $color: #7a7a7a, $margin-right: 5px);
								}
							}

							> .variants {
								background: #f1f6f9;
								border-top: solid 1px #d9e5ec;
								margin-top: 20px;
								position: relative;

								&:before { 
									content: '';
									display: block;
									position: absolute;
									left: 82px;
									width: 0;
									height: 0;
									transform: rotate(180deg) scaleY(.7);
									border: solid 16px #d9e5ec;
									border-left-color: transparent;
									border-bottom-color: transparent;
									top: -28px;
								}

								&:after { 
									content: '';
									display: block;
									position: absolute;
									left: 83px;
									width: 0;
									height: 0;
									transform: rotate(180deg) scaleY(0.7);
									border: solid 16px #f1f6f9;
									border-left-color: transparent;
									border-bottom-color: transparent;
									top: -26px;
								}

								> div {
									max-height: 400px;
									overflow-y: auto;
									box-sizing: border-box;
									padding: 20px;

									&::-webkit-scrollbar {
										width: 10px;
										height: 10px;
									}
 
									&::-webkit-scrollbar-track {
										box-shadow: inset 1px 0 1px 1px rgba(black, .02);
										background-color: rgba(black, .08);
									}

									&::-webkit-scrollbar-thumb {
										background-color: rgba(black, .2);
										border-radius: 8px;
									}

									> table {
										> tbody > tr {
											&.unwanted {
												color: rgba(black, .4);
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}

		> button {
			margin-top: 40px;
			min-width: 110px;
		}

		@at-root .item-menu {
			@extend .popup-menu;
			display: none;
		}

		@at-root .popup-content .item-menu {
			display: block;
		}
	}

	.dropdown-backdrop {
		@include animate-show();
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 4;
	}



	@at-root #item-group-dropdown {
		background-color: #f0f0f0;
		min-width: 400px;
		max-height: 400px;
		overflow-y: auto;
		box-shadow: 1px 4px 28px rgba(0, 0, 0, 0.1), 1px 2px 6px rgba(0, 0, 0, 0.07);
		border: solid 1px #C7C7C7;
		border-color: #d0d0d0 #cdcdcd #c5c5c5 #d0d0d0;
		z-index: 5;
		position: absolute;
		@include webkit-scrollbar;

		@include animate-show(.2s);

		/*ul {
			max-height: none;
		}*/

		> .tree-editor {
			background-color: #f0f0f0;

			.view > .name {
				cursor: pointer;
			}
		}
	}

	@at-root #description-editor {
		background-color: white;
		width: 500px;
		box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.11);
		border: solid 1px #C7C7C7;
		z-index: 5;
		position: absolute;
		padding: 10px;
		line-height: 1.2;

		@include animate-show(.2s);

		> .editable {
			width: 100%;
			box-sizing: border-box;
			border: none;
			resize: vertical;
			min-height: 20ex;
			max-height: 50ex;
			overflow-y: auto;
			@include webkit-scrollbar();
		}
	}

	#stock3 {
		width: 1152px;
		margin: 60px auto;

		$varugrupp-width: 222px;
		$artikelnummer-width: 170px;
		$namn-width: auto;
		$lagerställe-width: 190px;
		$lagersaldo-width: 150px;
		$styckkostnad-width: 150px;

		$contrast-background-color: #d5e1e4;
		$contrast-border-color: #e3edef;

		> h2 {
			margin-bottom: 60px;
		}

		> #flexible-area {
			display: flex;

			&.variants-visible {
				> #table > .headers > div > span:nth-child(1),
				> #table > .headers > div > span:nth-child(4) {
					display: none;
				}

				> #table > table > tbody > tr > td:nth-child(1),
				> #table > table > tbody > tr > td:nth-child(4) {
					display: none;
				}

				> #table > table > tbody > tr:not(.editing-variants) > td {
					color: #b4b4b4;
				}

				> #variants {
					margin-left: 40px;
					opacity: 1;
					transition: width, .3s, opacity .3s .2s;

					> div {
						background-color: #fafafa;
						border-left: solid 4px #a1c8e3;
						box-shadow: 0 0 15px rgba(0, 0, 0, 0.07)
					}
				}

				&.single-variation {
					> #variants {
						width: 360px;
					}
				}

				&.matrix {
					> #table > .headers > div > span:nth-child(2),
					> #table > table > tbody > tr > td:nth-child(2) {
						display: none;
					}

					> #variants {
						width: 50%;
					}
				}
			}

			> #table {
				flex: 1 1 auto;
				transition: width, .4s;

				.right-adjusted {
					text-align: right;

					> input {
						text-align: right;
					}
				}

				> .headers {
					width: 100%;
					display: table;
					position: sticky;
					top: 44px;
					background: $main-background-color;
					z-index: 1;

					> div {
						display: table-row;

						> span {
							display: table-cell;
							box-sizing: border-box;
							font-size: 14px;
							text-align: left;
							color: $bright-colors_dark-gray-text;
							vertical-align: bottom;
							border-bottom: solid 1px #dfdfdf;

							&:not(.call-to-input) {
								padding: 8px 5px;
							}

							&.right-aligned {
								text-align: right;
							}

							&:nth-child(1) {
								width: $varugrupp-width;
							}

							&:nth-child(2) {
								width: $artikelnummer-width;
							}

							&:nth-child(3) {
								width: $namn-width;
							}

							&:nth-child(4) {
								width: $lagerställe-width;
							}

							&:nth-child(5) {
								width: $lagersaldo-width;
							}

							&:nth-child(6) {
								width: $styckkostnad-width;
							}

							&.call-to-input {
								background-color: #f0f0f0;
								padding-top: 20px;
								padding-bottom: 8px;
								text-align: right;
							}

							&:not(.call-to-input) + .call-to-input {
								padding-left: 20px;
								padding-right: 7px;
							}

							&.call-to-input + &:not(.call-to-input),
							&.call-to-input:last-child {
								padding-right: 27px;
							}
						}
					}
				}

				> table.lines {
					> tbody {
						> tr {
							> td {
								box-sizing: border-box;

								&:nth-child(1) {
									width: $varugrupp-width;
								}

								&:nth-child(2) {
									width: $artikelnummer-width;
								}

								&:nth-child(3) {
									width: $namn-width;
								}

								&:nth-child(4) {
									width: $lagerställe-width;
								}

								&:nth-child(5) {
									width: $lagersaldo-width;
								}

								&:nth-child(6) {
									width: $styckkostnad-width;
								}

								/*input {
									width: 120px;
								}*/

								&.has-variants {
									> span > input {
										cursor: pointer;

										&:hover {
											background-color: $background-highlight;
										}
									}
								}
							}
						}
					}
				}
			}

			> #variants {
				width: 0;
				opacity: 0;
				transition: width, .3s, opacity .3s;

				> div {
					box-sizing: border-box;
					position: relative;
					transition: margin-top .2s;
					padding: 40px;

					> .close {
						position: absolute;
						top: 10px;
						right: 10px;
						color: $bright-colors_dark-gray-text;
						@include mdi-icon(window-close, 18px, 0);
						padding: 10px;
						border-radius: 100%;

						&:hover {
							background-color: #f0f0f0;
						}
					}

					> .arrow {
						position: absolute;
						margin-top: 8px;
						left: 0;
						@include callout-arrow(top-left, 15px, #a1c8e3, transparent);
						top: 100px;
						transition: top .1s;

						&:before {
							transform: rotate(-90deg);
							left: -23px;
						}
					}

					> h3 {
						text-align: center;
						font-size: 17px;
						margin-bottom: 5px;
						font-weight: 500;
					}

					> h4 {
						text-align: center;
						font-size: 14px;
						font-weight: 300;
						margin-top: 10px;
					}

					> .single-variation {
						margin-top: 30px;

						> table {
							width: 100%;
							border-spacing: 3px;

							> thead > tr > th {
								font-size: 14px;
								padding-bottom: 8px;
								font-weight: 400;
								color: $bright-colors_dark-gray-text;

								&:first-child {
									text-align: left;
								}

								&:last-child {
									text-align: right;
								}
							}

							> tbody > tr {

								&.unwanted {
									color: rgba(black, .3);
								}

								> td {
									border-top: solid 1px $bright-colors_silver-stroke-on-white;

									&:last-child {
										text-align: right;
										border-top: solid 1px $variants-background-color;
										width: 70px;
									}

									> input {
										border: none;
										width: 70px;
										text-align: right;
										border-radius: 0;
										box-shadow: 0 0 0px 1px #e4e4e4;

										&[disabled] {
											color: rgba(black, .3);
										}
									}
								}
							}
						}
					}

					> .matrix {
						margin-top: 30px;
						overflow: auto;
						max-height: 400px;

						&::-webkit-scrollbar {
							width: 10px;
							height: 10px;
						}
 
						&::-webkit-scrollbar-track {
							box-shadow: inset 1px 0 1px 1px rgba(black, .02);
							background-color: rgba(black, .08);
						}

						&::-webkit-scrollbar-thumb {
							background-color: rgba(black, .2);
							border-radius: 8px;
						}

						> table {
							width: 100%;
							border-spacing: 3px;

							tr {
								> th {
									font-size: 14px;
									padding-bottom: 12px;
									text-align: right;
									width: 70px;
									padding-right: 8px;
									vertical-align: bottom;
									font-weight: 400;
									color: $bright-colors_dark-gray-text;
									border-bottom: solid 1px $bright-colors_silver-stroke-on-white;

									&:first-child {
										text-align: left;
										padding-right: 30px;
										max-width: 140px;
										overflow: hidden;
										word-wrap: break-word;
										-ms-word-break: normal;
										word-break: normal;
									}
								}


								> td {
									border-bottom: solid 1px $bright-colors_silver-stroke-on-white;
									border-left: solid 1px $bright-colors_silver-stroke-on-white;
									background-color: #E9DDE6;
									vertical-align: middle;
									width: 86px;

									> input {
										border: none;
										width: 100%;
										text-align: right;
										border-radius: 0;
										box-sizing: border-box;
										min-width: 40px;
										box-shadow: 0 0 0px 1px #e4e4e4;

										&[disabled] {
											color: rgba(black, .3);
										}
									}
								}
							}
						}
					}
				}
			}
		}

		.paging {
			margin-top: 40px;
			text-align: center;
			margin-bottom: -20px;
		}

		> button {
			margin-top: 40px;
			min-width: 110px;
			margin-right: 10px;
		}
	}

	#execute {
		> div {
			width: 400px;
			height: 400px;

			> h2 {
				font-size: 19px;
				font-weight: 500;
				margin-top: 0;
			}

			> ul {
				text-align: left;
				border-left: solid 1px #d2d2d2;
				padding: 15px 30px;
				line-height: 2;
				margin: 2em 0 3em;
			}

			> button {
				min-width: 130px;
				margin: 5px;
			}
		}
	}

	#working {
		text-align: center;
		width: 450px;
		margin: 70px auto;
		padding: 50px 100px;
		border-radius: 20px;

		> div > .throbber {
			margin-bottom: 30px;
		}
	}

	#done {
		> div:first-of-type {
			> i {
				@include mdi-icon(check-circle-outline, 120px, 0, 0, #d2e4ef);
				margin-bottom: 1.5em;
				height: unset;
			}

			> a {
				@include mdi-icon(arrow-right, 16px, 6px, -2px, gray, 'after');
			}
		}

		&.publish {
			> div {
				background: none;
				width: 470px;
				text-align: center;

				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				position: relative;
				top: -30px;

				&:first-of-type {
					@include impromptu-animation(.3s, .7s, $fill-mode: both, $timing-function: ease-out) {
						0% {
							margin-top: 330px;
						}
						100% {
							margin-top: 0;
						}
					}

					> i:before {
						@include impromptu-animation(1s, 0, $fill-mode: both, $timing-function: ease-out) {
							0% {
								font-size: 90px;
								opacity: .1;
							}
							10% {
								font-size: 130px;
								opacity: .4;
							}
							20% {
								font-size: 120px;
							}
							40% {
								opacity: 1;
							}
							80% {
								font-size: 120px;
								opacity: 1;
							}
							100% {
								font-size: 80px;
								opacity: 1;
							}
						}
					}

					> h1 {
						@include impromptu-animation(.3s, .7s, $fill-mode: both, $timing-function: ease-out) {
							0% {
								font-size: 36px;
								margin-bottom: .8em;
							}
							100% {
								font-size: 17px;
								margin-bottom: .5em;
							}
						}
					}

					> i:before {
						color: #afd5ec;
					}

					> a {
						@include animate-fade-in(.5s, .3s);
					}
				}
			}

			> .pick-and-publish-backdrop {
				position: fixed;
				top: 0;
				width: unset;

				> div {
					width: 768px;
					height: 619px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;

					> h2 {
						margin-bottom: 2em;
					}

					> .table-wrapper {
						margin: 4em 0;
						text-align: left;
						overflow-y: auto;
						flex: 1;
						@include webkit-scrollbar;

						td > label:not(:first-child) {
							margin-left: 10px;
						}
					}

					> .button-row {
						text-align: left;
					}
				}
			}
		}

		> .publish-commands {
			opacity: 0;
			@include animate-fade-in($delay: 1s);
			margin-top: 50px;
			border-top: solid 1px $bright-colors_silver-stroke-on-smoke;
			padding-top: 50px;

			> img {
				height: 90px;
				opacity: .7;
				margin: 20px 0;
			}

			> p {
				width: 350px;
				margin: 0 auto;
			}

			> .button-row {
				margin-top: 40px;
			}
		}
	}
}

#bulk-new-stocklocation-form {
	> div {
		width: 358px;
		min-height: 120px;
	}

	> .loading {
		> .throbber {
			margin: 38px auto 0;
		}

		> p {
			text-align: center;
			color: #5E5E5E;
			font-size: 15px;
			margin-top: 10px;
		}
	}
}