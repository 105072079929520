.table td.has-variants {
	padding: 3px 7px 0px;
	
	//Eftersom vi inte vill breaka mellan itemnumber och bubblan
	white-space: nowrap;
	
	> .variant-count {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 0px 3.5px 1.5px 3px;
		font-size: 12px;
		height: 21px;
		border-radius: 6px;
		min-width: 22px;
		background-color: #a1c6dd;
		position: relative;
		color: #fff;
		box-sizing: border-box;
		overflow: visible;
		margin-left: 11px;
		top: -1px;

		&:after {
			content: '';
			position: absolute;
			top: 7px;
			left: -10px;
			border: solid 3px #a1c6dd;
			border-left-color: transparent;
			border-top-color: transparent;
			border-bottom-color: transparent;
			display: inline-block;
			transform: scalex(2.6);
		}
	}
}