﻿@import "../mixins/material-deisgn-icons";
@import "../variables";
@import "../mixins/icons";
@import "../mixins/responsive";
@import "../mixins/ng-animate";
@import "../mixins/impromptu-animation";
@import "../mixins/animations";

[id^="sales-orders-"] > .page-content {
	min-width: 700px;
	max-width: 1300px;

	.warehouse-indicator {
		position: absolute;
		transition: left .2s;
		will-change: left;
		
		left: 76px + $left-menu-width;
		@include when-left-menu-collapsed {
			left: 76px + $collapsed-left-menu-width;
		}

		top: 36px + $top-header-height;

		// Så att den hamnar framför center-contents-containern:
		z-index: 1;

		$breadcrumb-height: 69px;
		
		background: #fafafa;
		border-radius: 8px 10px 9px 8px;
		padding: 12px 17px;
		padding-right: 30px;
		border: solid 1px #e6e6e6;
		height: $breadcrumb-height;
		box-sizing: border-box;

		&:before {
			content: '';
			position: absolute;
			border-bottom: 33px solid transparent;
			border-left: 28px solid #e6e6e6;
			border-top: 33px solid transparent;
			top: 1px;
			right: -25px;
			display: block;
			border-bottom-right-radius: 8px;
			border-top-right-radius: 8px;
		}

		&:after {
			content: '';
			position: absolute;
			border-bottom: 33px solid transparent;
			border-left: 28px solid #fafafa;
			border-top: 33px solid transparent;
			top: 1px;
			right: -24px;
			display: block;
			border-bottom-right-radius: 8px;
			border-top-right-radius: 8px;
		}

		> h3 {
			font-family: $muli;
			font-size: 15px;
			margin-bottom: 7px;
			font-weight: 600;
			color: #3e3e3e;

			&:before {
				color: $bright-colors_dark-gray-text;
				content: '';
				height: 18px;
				width: 18px;
				background: url('../images/icons/warehouse.svg') no-repeat;
				background-size: 18px;
				display: inline-block;
				padding-right: 11px;
			}
		}

		> a {
			margin-left: 30px;
			font-weight: 300;
		}
	}

	> h1 {
		margin-bottom: 60px;
	}

	.call-to-actions {
		// Så att den hamnar framför center-contents-containern:
		z-index: 1;
		position: relative;
		margin-top: -60px;
		margin-bottom: 50px;
		text-align: right;

		&.main-action {
			position: relative;

			> #quarantined.big-button {
				position: absolute;
				left: 50%;
				top: calc(50vh - 243px);
				margin-left: -120px;
				font-size: 17px;
				height: 192px;
				width: 192px;
				padding: 54px 41px 0 41px;
				border-radius: 100%;
				box-sizing: border-box;
				text-align: center;
				line-height: 56px;

				> i {
					display: block;
					text-align: center;
					margin: 0 auto;
					width: 45px;
					height: 45px;
					border-radius: 100%;

					&:before {
						width: 26px;
						height: 29px;
						background-size: 22px;
						left: 8px;
						top: 7px;
					}

					> sup {
						width: 26px;
						height: 26px;
						top: -32px;
						left: 17px;
						line-height: 24px;
						border-radius: 26px;
					}
				}
			}
		}

		.big-button {
			@include animate-fade-in(.6s);
			margin-left: 7px;
		}

		#quarantined > i {
			background: #5babff;

			&::before {
				content: '';
				position: absolute;
				left: 6px;
				top: 5px;
				@include hand;
				width: 22px;
				height: 24px;
				background-size: 18px;
			}
		}
	}

	.more {
		width: 42px;
		padding: 9px 0 7px 8px;
		float: right;
		margin-top: -10px;

		@include mdi-icon(dots-horizontal, 22px, 0);
		// Så att den hamnar framför center-contents-containern:
		z-index: 1;
		position: relative;
	}

	.show-archive {
		// Så att den hamnar framför center-contents-containern:
		z-index: 1;
		position: relative;
	}

	.order-states {
		margin-bottom: 40px;
		box-shadow: inset 0 -1px 0px 0px $main-background-color, inset 0 -2px 0px 0px $bright-colors_silver-stroke-on-smoke;

		> a {
			display: inline-block;
			color: $bright-colors_dark-gray-text;
			padding: 15px 8px 11px 5px;
			margin-right: 25px;
			white-space: nowrap;

			> i {
				margin-right: 2px;
				height: 22px;
				vertical-align: -1px; // -1 ser bra ut på min laptop. Såg -3 bättre ut på skärm med textförstoring?

				&:before, svg {
					color: #868686;
				}
			}

			> sup {
				background: #9c9c9c;
				padding: 0px 6px 1px;
				display: inline-block;
				font-size: 13px;
				border-radius: 20px;
				line-height: 20px;
				text-align: center;
				color: #fff;
				margin-left: 4px;
				transition: opacity .1s,margin-left .2s;
			}

			&:hover {
				border-bottom: solid 2px #cecece;
				color: $bright-colors_black-text;
			}

			&.active {
				color: $bright-colors_black-text;
				border-bottom: solid 2px #9eceea;

				> i:before, > i > svg {
					color: $bright-colors_vibrant-blue;
				}

				> sup {
					opacity: 0;
					margin-left: -20px;
				}
			}

			&.pack {
				> i {
					@include mdi-icon(package-variant, 24px, $vertical-align: -3px);
				}
			}

			&.ship {
				> i {
					@include mdi-icon(package-variant-closed, 24px, $vertical-align: -3px);
				}
			}

			&.partially-shipped {
				> i {
					> svg {
						width: 25px;
						height: 25px;
						display: inline-block;
						vertical-align: -6px;
						margin-right: 7px;
					}
				}
			}

			&.waiting-for-payment {
				> i {
					@include mdi-icon(clock-outline, 24px, $vertical-align: -3px);
				}
			}
		}
	}

	.multi-options {
		@include animate-expand(76px, .2s);

		> button {
			margin-right: 10px;
			padding-top: 10px;

			&.print {
				@include mdi-icon(printer, 19px, $vertical-align: -2px);
				
				&[data-loading] {
					border-color: #cbcbcb;
					&:before {
						opacity: 0;
					}
				}
			}
			/*&.delivery { @include mdi-icon(truck-delivery, 20px, $vertical-align: -2px); }*/
			&.ready {
				@include mdi-icon(package-variant-closed, 20px, $vertical-align: -2px);
			}

			&.shipped {
				@include mdi-icon(truck-delivery, 20px, $vertical-align: -3px);
			}

			&:not(.has-icon) {
				vertical-align: 2px;
			}
		}
	}

	.patch-subtle {
		margin-top: 4em;
	}

	button.wand {
		@include mdi-icon(auto-fix, 19px, $vertical-align: -4px);
		margin-bottom: 30px;

		&:before {
			position: relative;
			top: -2px;
		}
	}

	.table {
		overflow: hidden; // Annars visas en horisontell scrollbar under ng-leave pga transform

		.sales-channel-order-number {
			font-weight: 300;
			color: $bright-colors_dark-gray-text;
			font-size: 13px;
			line-height: 1em;
		}

		> tbody {
			> tr {
				&.ng-enter {
					transition: opacity ease .5s, background-color .7s ease-out, color .3s .2s;
					opacity: 0;
					color: transparent;
					background-color: #61a0c7;

					&.ng-enter-active {
						opacity: 1;
						background-color: transparent;
						color: unset;
					}
				}
			
				&.ng-leave {
					background-image: linear-gradient(90deg, #dddddd 50%, transparent 50%);
					background-position-x: 100%;
					transition: background-position-x .28s linear, opacity .28s .35s, transform .4s .3s;
					background-size: 200% 200%;
					opacity: 1;

					&.ng-leave-active {
						background-position-x: 0%;
						opacity: 0;
						transform: translateX(80%);
					}

					@at-root .table:not(.checked) > tbody > tr > td.checkbox {
						background-color: #f5f5f5;
					}
				}
			}
		}
	}

	.centered-circle-container {
		> div {
			> object {
				opacity: .6;
				height: 160px;

				@include impromptu-animation(.4s) {
					0% {
						opacity: 0;
					}

					100% {
						opacity: .6;
					}
				}
			}

			> h2 {
				margin-top: 21px;
				font-size: 17px;
				color: #333;
			}
		}
	}
}

#sales_selectwarehouse-view > .page-content {
	.empty {
		margin-top: -50px;

		> p {
			margin-bottom: 50px;
		}
	}

	.tiles {
		width: 900px;
		margin: 70px auto;

		@include when-page-atmost(1300px) {
			width: 600px;
		}

		> li {
			width: 33%;

			@include when-page-atmost(1300px) {
				width: 50%;
			}
		}
	}
}

#register-shipment-menu {
	padding: 30px;

	div {
		margin: 40px 0;

		.radio {
			margin-left: 10px;
		}
	}
}
