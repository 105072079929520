﻿#items-stock-warehouse-stocktransfer-view .page-content {
	max-width: 1015px;

	h1 {
		margin-bottom: 1.5em;
	}

	.low-contrast {
		text-align: center;
		max-width: 50ch;
		margin: 0 auto 5em auto;
	}

	#occasion {
		width: 800px;
	}

	textarea {
		resize: vertical;
		min-height: 2.6em;
	}

	#table-headline {
		margin-bottom: 30px;
		overflow: hidden;

		h2 {
			float: left;
		}

		.filter {
			float: right;

			> .search-box {
				margin-left: 30px;
				position: relative;

				> input {
					padding-right: 30px;
					transition: width .3s;
					width: 70px;

					&.ng-not-empty, &:focus {
						width: 150px;
					}
				}

				@include mdi-icon(magnify, 18px, 0, middle, #606060);

				&:before {
					position: absolute;
					right: 8px;
					top: 3px;
				}
			}
		}
	}

	table td.input {
		width: 80px;
	}

	.button-row {
		margin-top: 60px;
		padding: 0;
	}
}