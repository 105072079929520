﻿@import "../mixins/webkitscrollbar";

email-composer {
	flex: 1;
	display: flex;
	flex-direction: column;
}

.email-editor, // todo: ta bort när alla använder email-composer
email-composer > .email-editor {
	flex: 1;
	display: flex;
	flex-direction: column;

	> .part {
		padding: 3px;
		background: white;
		box-shadow: 0 0 3px rgba(0, 0, 0, 0.07), 0 1px 2px rgba(0, 0, 0, 0.15);
		overflow: hidden;

		&:not(:last-child) {
			margin-bottom: 10px;
		}

		> .editable {
			overflow-x: hidden;
			margin: 8px;
			padding: 10px;
			border: dashed 1px #dddddd;
			line-height: 1.4em;
			background-color: transparent;
			transition: background-color .1s, flex-grow .2s;

			&:hover {
				background-color: #fbfeff;
				border-color: #d0d6da
			}

			&:focus {
				box-shadow: 0 0 5px rgba(black, .1);
				border: solid 1px #8ecdf5;
				background: white;
			}

			&:not(:first-child) {
				margin-top: 0;
			}
		}

		&:not(.single-line) {
			flex: 1;
			display: flex;
			flex-direction: column;

			> .editable {
				min-height: 57px; // tre rader
				@include webkit-scrollbar();
				overflow-y: auto;

				&:focus {
					@include webkit-scrollbar($thumb-color: #a4a4a4);
					flex-grow: 3;
				}

				&:first-child {
					// Body
					flex-basis: 57px;
					flex-grow: 1;
				}

				&:nth-child(2) {
					// Signatur
					flex-basis: 57px;
				}
			}
		}
	}

	> .button-row {
		margin-top: 30px;
	}
}