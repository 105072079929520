﻿@import "variables";
@import "mixins/_button-mixins.scss";
@import "mixins/checkbox";
@import "mixins/radio";
@import "mixins/material-deisgn-icons";
@import "mixins/webkitscrollbar";

// Radio buttons

label.radio-line {
    margin: 10px 0;
    display: block;
}

input[type="radio"] {
    @include radio;
}

// Buttons

.ladda-button[data-style=zoom-in], .ladda-button[data-style=zoom-in] .ladda-spinner, .ladda-button[data-style=zoom-in] .ladda-label, .ladda-button[data-style=zoom-out], .ladda-button[data-style=zoom-out] .ladda-spinner, .ladda-button[data-style=zoom-out] .ladda-label {
	transition: background-color ease .1s, border-color .2s !important;
}

.ladda-button:not(.contrast) > .ladda-spinner div {
	background-color: #ababab !important;
}

button, input[type=button], .button {
	font-family: $muli;
	display: inline-block;
	line-height: 1;
	height: 42px;
	background-color: transparent;
	border: solid 2px #b4b4b4;
	border-radius: 4px;
	color: #666666;
	padding: 11px 22px 13px;
	font-weight: 600;
	cursor: pointer;
	-moz-user-select: none;
	-ms-user-select: none;
	-webkit-user-select: none;
	user-select: none;
	outline: none;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
	transition: background-color ease .1s, border-color .2s;
	font-size: 14px;

	@at-root {
		@-moz-document url-prefix() {
			& {
				padding-top: 10px;
			}
		}
	}

	> .count {
		display: inline-block;
		background: rgba(#303030, .5);
		width: 18px;
		height: 18px;
		line-height: 18px;
		text-align: center;
		border-radius: 18px;
		font-size: 12px;
		color: #f5f5f5;
		margin-left: 3px;
	}

    &.has-icon:before {
        // Knappikoner
        margin-left: -8px;
        margin-right: 7px;
    }

	&.icon {
		width: 42px;
		padding: 8px 0;
		text-align: center;
	}

	&:hover {
		background-color: rgba(0,0,0,0.06);
		color: #303030;
		border-color: #9a9a9a;
	}

	&:active, &:active:hover {
		background-color: rgba(0,0,0,0.15);
		color: #303030;
	}

	&[disabled] { 
		cursor: default;
		color: #bfbfbf;
		border-color: #e1e1e1;
		background-color: transparent;
	}

    &.subtle {
        border-color: transparent;
		background-color: transparent;

        &:hover {
			color: #303030;
            border-color: #9a9a9a;
            background-color: rgba(0,0,0,0.04);
        }

		&:active {
			background-color: rgba(0,0,0,0.12);
		}

		&[disabled]:hover, &[disabled]:active {
			color: #bfbfbf;
			border-color: transparent;
			background-color: transparent;
		}
    }

	&.contrast {
		@include contrast;
	}

	&.add { @include mdi-icon(plus, 22px, 0); }
	&.more { @include mdi-icon(dots-horizontal, 22px, 0); }

	&.edit {
		@include mdi-icon(pencil, 18px, $vertical-align: text-bottom); // vid -2px offsettas kringliggande knappar

		&:before {
			margin-top: -1px;
		}
	}
}

// Knappar bakom popups ska dimmas
// Klassen stop-scrolling läggs på bodyn när en SweetAlert är synlig
.under-popup .button.contrast,
.stop-scrolling .button.contrast,
.under-popup button.contrast,
.stop-scrolling button.contrast {
	$desaturated: desaturate($button-color-contrast, 60%);
	background-color: lighten($desaturated, 15%);
}

// ...men inte knappar i popupen själv
.under-popup .popover .contrast,
.under-popup .popup .contrast {
	background-color: $button-color-contrast;

	&:hover {
		background-color: darken($button-color-contrast, 5%);
	}

	&:active {
		background-color: darken($button-color-contrast, 10%);
	}

	&[disabled] {
		background-color: #eaeaea;
		color: #d3d3d3;
	}
}

form {
	.tip, .help {
		@include mdi-icon(help-circle-outline, 22px, 7px, -3px, $bright-colors_vibrant-blue);
		color: #777777;
		font-weight: 300;
	}

	.info {
		@include mdi-icon(information-outline, 22px, 7px, -3px, $bright-colors_vibrant-blue);
		color: #777777;
		font-weight: 300;
	}
}

// Text-fält

.form-field {
    margin-bottom: 23px;
    display: block;
	break-inside: avoid;
	text-align: left;

	&.labelless {
		padding-top: 22px;
	}

	> a {
		line-height: 38px; // T ex plus-knappar "lägg till leverantör"
	}

	> h4:first-child {
		padding-bottom: 9px;
		margin: 0;
	}

    > span:first-child {
        color: $bright-colors_dark-gray-text;
        display: block;
        padding-bottom: 11px;
		font-size: 14px;
		
		// Checkboxar inuti inupt-labels ska vara något mindre
		> label > input[type=checkbox] + span {
			line-height: 14px;
			padding-left: 23px;

			&:before {
				width: 14px;
				height: 14px;
			}

			&:after {
				height: 5px;
				left: 6px;
			}
		}

		// Radios inuti inupt-labels ska vara något mindre
		> label > input[type=radio] + span {
			margin-right: 10px;
			&:before {
				width: 6px;
				height: 6px;
				border-width: 4px;
				vertical-align: -1px;
				position: relative;
				top: 1px;
			}
		}

		> .tip, > .help {
			@include mdi-icon(help-circle-outline, 18px, 0, 0, $bright-colors_vibrant-blue);
			display: inline-block;
			width: 18px;
			height: 18px;
			margin-left: 4px;
			margin-top: -2px;
			position: absolute;
		}

		> i {
			font-weight: 300;
			color: #9a9a9a;
		}
    }

    > input, .selectize-control.single > .selectize-input, textarea {
        width: calc(100% - 24px);
		box-sizing: content-box !important; // annars ser item-form > leverantör dålig ut
    }

	> textarea {
		@include webkit-scrollbar;
	}

	> input ~ .radio {
		// Radiobuttons nedanför formfält
		margin-top: 14px;
		display: inline-block;
		margin-right: 10px;

		> span:before {
			width: 6px;
			height: 6px;
			border-width: 4px;
			vertical-align: -2px;
		}
	}

	> input + .tip,
	> input + .info,
	> input + .help {
		padding-left: 12px;
	}

	> .editable {
		cursor: text;
		background: white;
		border: none;
		border-bottom: solid 1px #e4e4e4;
		padding: 9px 12px 9px;
		line-height: 1.3em;
		outline: none;
		-webkit-appearance: none;
		min-height: 1.3em * 2; // två rader
	}
}

.button-row {
	> button {
		margin-right: 14px;
			
		&.contrast {
			min-width: 120px;
		}
	}
}

// Checkbox

.checkbox {
    @include checkbox();
}