inline-bar {
	display: inline-flex;
	flex-direction: row;
	align-items: center;

	> .number {
		text-align: left;
		
		&.number-placement-right {
			order: 2;
			text-align: right;
		}
	}

	> .bar-container {
		flex: 1;
		background: rgba(233, 239, 241, 0.53);
		mix-blend-mode: multiply;
		border-radius: 3px;
		height: 4px;
		display: flex;

		&.number-placement-right {
			flex-direction: row-reverse;

			> .bar {
				clip-path: inset(0px 0px 0px calc(100% - var(--bar-width-percentage)) round 3px);
				--fade-direction: to right;
			}
		}

		> .bar {
			display: flex;
			height: 4px;
			border-radius: 3px;
			width: 100%;

			clip-path: inset(0px calc(100% - var(--bar-width-percentage)) 0px 0 round 3px);

			--color-faded: rgba(76, 169, 221, 0.4116);
			--color-vibrant: rgba(65, 173, 235, 0.84);
			--fade-direction: to left;

			&.red {
				--color-faded: rgba(249, 123, 101, 0.5);
				--color-vibrant: rgba(249, 123, 101, 0.9);
			}

			background: linear-gradient(var(--fade-direction), var(--color-vibrant) 0%, var(--color-faded) 100%);
		}
	}
}