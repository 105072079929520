﻿@import "variables";
@import "mixins/webkitscrollbar";

$open-dropdown-border-color: silver;

// Dessa rör väl endast selecter i tabeller? (Annars funkar ju border-bottom i validation.scss). Om inte, reverta ".table" på första och tredje selectorn
form:not(.ng-submitted) .table select.ng-invalid:not(.ng-pristine) + .selectize-control, // Ändrad men form ej submittad
form.ng-submitted .table select.ng-invalid + .selectize-control, // Form submittad
form.ng-submitted .table td.input select.ng-invalid + .selectize-control, // Samma som ovan, fast i tabell
form.ng-submitted .table select:required.empty + .selectize-control // Vi sätter klassen "empty" på select-elementet
{						  
	box-shadow: 0 0 0 1px #e4e4e4, inset 0 -1px 0 0 #f67d68;
}

.selectize-control, .selectize-control.single {
	margin: 0;
	border: none;

	&.in-popup {
		z-index: 24;
	}

	&.selectize-control.single {
		height: 37px;

		> .selectize-input {
			height: 36px;
			white-space: nowrap;
		}
	}
	&.on-white > .selectize-input {
		@include input-on-white;
	}

	> .selectize-input {
		margin: 0;
		border: none;
		box-sizing: border-box; // tidigare hade jag content-box här, men ändrar eftersom det inte lirade med width: 100% som den ju ärver. (syntes på publicera varugrupper > filterpopup)
		background: white;
		border-radius: 0;
		box-shadow: none;
		line-height: 35px;
		padding: 0 12px;
		border: none;
		border-bottom: solid 1px #e4e4e4;
		color: #232323;
		//min-height: 36px;
		white-space: normal;
		font-size: 14px;

		&.disabled {
			background: #fafafa;
			opacity: 1;
			color: #9a9a9a;

			&:after {
				border-top-color: #c1c1c1;
			}
		}

		&.dropdown-active {
			&:after {
				margin-top: -2px;
			}
		}

		&.focus:not(.dropdown-active) {
			border-bottom-color: #bfe2f8;
		}

		&:before {
			content: none;
		}

		&:after {
			margin-top: -1px;
		}

		> .item {
			font-size: 14px;


			// Item templates
			&.color {
				> i {
					display: inline-block;
					width: 10px;
					height: 10px;
					border-radius: 10px;
					margin-right: 8px;
				}
			}
		}

		&:not(.dropdown-active) > .item {
			// Om dessa regler appliceras när dropdownen är utfälld så hamnar det fokuserade input-fältet förskjutet nedåt
			text-overflow: ellipsis;
			overflow: hidden;
			max-width: calc(100% - 12px);
		}

		> input {
			font-size: 14px;
		}
	}
}

body>.selectize-dropdown,
body>.selectize-dropdown.single {
	box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.06), 1px 1px 1px rgba(1, 1, 0, 0.06);
	overflow: hidden;
	font-size: 14px;
	border: none;
	margin-top: 0px;
	border-radius: 0;
	z-index: 20;

	&.spreadsheet {
		margin-top: 1px;
	}

	&.in-popup {
		z-index: 25;
	}

	[data-selectable] {
		&:first-child {
			border-top: none;
		}
		border-top: solid 1px #f0f0f0;
		padding: 9px 16px 11px;
	}

	> .selectize-dropdown-content {
		max-height: 300px;
		overflow-y: auto;
		white-space: nowrap;
		
		@include webkit-scrollbar($thumb-color: rgba(black, .2));

		.optgroup-header {
			font-family: $muli;
			font-size: 14px;
			background-color: $bright-colors_baby-blue-2;
			font-weight: 700;
			color: $bright-colors_dark-gray-text;
			padding: 7px 16px 8px;
		}

		.create {
			&.active {
				color: white;
				background-color: $bright-colors_vibrant-blue;
			}

			> i {
				font-weight: 700;
			}
		}

		.option {
			word-wrap: break-word;

			&.active {
				background-color: $bright-colors_vibrant-blue;
				color: white;

				> .highlight {
					background-color: darken($bright-colors_vibrant-blue, 12%);
				}
			}

			&.email-address {
				> span {
					font-size: 14px;
					opacity: .6;
				}
			}

			&.color {
				> i {
					display: inline-block;
					width: 12px;
					height: 12px;
					border-radius: 12px;
					margin-right: 9px;
					position: relative;
					top: 1px;
					box-shadow: 0 0 0 2px white;
				}
			}
		}
	}
}

.selectize-control.multi {
	> .selectize-input {
		padding: 7px 10px 6px 10px !important;
        min-height: 52px; // Annars krymper den när placeholdern är borta (efter att man tagit bort det sista itemet)
		box-sizing: border-box;

		&.disabled {
			background: #fafafa;
			opacity: 1;

			> .item  {
				&:hover {
					background: none;
					box-shadow: none;
				}

				> .remove:hover {
					background: #fafafa;
					&:before {
						color: #C4C4C4;
					}
				}
			}
		}

		> .item {
			$option-color: #f9f9f9;

			font-size: 14px;
			background: $option-color;
			padding: 1px 0 0 11px;
			border-radius: 17px;
			text-shadow: none;
			box-shadow: 0 1px 0px 0px #e7e7e7;
			border: none;
			cursor: default;
			height: 26px;
			line-height: 25px;
			margin: 0 7px 3px 0;
			color: $bright-colors_black-text;
			vertical-align: middle;

			&:hover {
				background: $bright-colors_baby-blue-1;
				box-shadow: 0 1px 0px 0px darken($bright-colors_baby-blue-1, 5%);

				> .remove:before {
					color: #b0b0b0;
				}
			}

			&.active {
				background: $bright-colors_vibrant-blue;
				border: none;

				> .remove {
					background-color: $bright-colors_vibrant-blue;

					&:before {
						color: white;
					}

					&:hover {
						background-color: darken($bright-colors_vibrant-blue, 20%);

						&:before {
							color: white;
						}
					}
				}
			}

			> .remove {
				@include mdi-icon(window-close, 14px, 0, $color: #C4C4C4);

				&:hover {
					background-color: darken($bright-colors_baby-blue-1, 20%);

					&:before {
						color: white;
					}
				}

			    border-radius: 15px;
				cursor: pointer;
				font-size: 14px;
				margin-left: 5px;
				margin-right: 3px;
				display: inline-block;
				height: 20px;
				width: 20px;
				line-height: 20px;
				vertical-align: -1px;
				text-align: center;

				&:before {
					vertical-align: 1px;
				}
			}
		}
	}
}