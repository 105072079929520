﻿

@mixin throbber-1($bullet-diameter: 14px, $bullet-spacing: 4px) {
	display: block;
	text-align: center;

	> div {
		width: $bullet-diameter;
		height: $bullet-diameter;
		background-color: transparent;
		border: solid 2px #a0a0a0;
		margin-right: $bullet-spacing;
		border-radius: 100%;
		display: inline-block;
		animation: bouncedelay 1.2s infinite ease-in-out;
		/* Prevent first frame from flickering when animation starts */
		animation-fill-mode: both;
	}

	.bounce1 {
	  animation-delay: -0.32s;
	}

	.bounce2 {
	  animation-delay: -0.16s;
	}
}

@-webkit-keyframes bouncedelay {
  0%, 80%, 100% { 
	  -webkit-transform: scale(0.0) }
  40% { 
	  -webkit-transform: scale(1.0) 
  }
}

@-moz-keyframes bouncedelay {
	0%, 80%, 100% {
		-moz-transform: scale(0.0);
	}
	40% {
		-moz-transform: scale(1.0);
	}
}

@keyframes bouncedelay {
	0%, 80%, 100% {
		-moz-transform: scale(0.0);
		-ms-transform: scale(0.0);
		-o-transform: scale(0.0);
		-webkit-transform: scale(0.0);
		transform: scale(0.0);
	}
	40% {
		-moz-transform: scale(1.0);
		-ms-transform: scale(1.0);
		-o-transform: scale(1.0);
		-webkit-transform: scale(1.0);
		transform: scale(1.0);
	}
}

.throbber-1 {
	@include throbber-1;
}

.throbber-1-larger {
	@include throbber-1(20px, 6px);
}