﻿@import "../variables";
@import "../mixins/material-deisgn-icons";
@import "../mixins/webkitscrollbar";

.item-details-navigator {
	transition: background-color .2s;
	background-color: transparent;

	&.pinned {
		width: 320px;

		> .pin {
			@include mdi-icon(pin-off, $margin-right: 0, $font-size: 18px, $vertical-align: -4px, $color: $bright-colors_dark-gray-text);
		}

		> .pinned-header {
			max-width: 262px;
			overflow: hidden;
			display: inline-block;
			font-size: 14px;
			padding: 16px 0 2px 20px;
			font-family: $muli;
			font-size: 14px;
			font-weight: 600;
			color: $bright-colors_dark-gray-text;
			cursor: pointer;

			&:hover {
				color: $bright-colors_vibrant-blue-brighter;
			}
		}

		> item-details-navigator-menu {
			max-width: 320px; // annars fladdrar den till över skärmen när navigatorn stängs
		}
	}

	&.open {
		background-color: white;
		box-shadow: 0 0 12px rgba(0, 0, 0, 0.15), 0 0 1px 0px rgba(0, 0, 0, 0.19);
		border-radius: 3px;
		

		> .breadcrumb {
			--breadcrumb-background: white;
		}

		> .breadcrumb > a, > .breadcrumb > a:visited, > .breadcrumb > a:active {
			border-color: transparent !important;
			color: $bright-colors_dark-gray-text;

			&:hover {
				color: $bright-colors_vibrant-blue-brighter !important;
			}

			&:nth-last-child(2) {
				&:before, &:after {
					content: none;
				}

				> i {
					display: none;
				}
			}

			&:last-child {
				display: none;
			}
		}

		> .pin {
			display: block;
		}
	}

	> .breadcrumb {
		margin: 6px 0 0 3px;
		cursor: pointer;
		padding-right: 6px; // Annars klipps den av konstigt nog
		position: static; // Normalt sett är den absolute

		&:before, &:after {
			margin-top: 6px;
		}

		> a, > a:visited, > a:active  {
			margin-left: 0;
			
			&:not(:first-of-type) {
				padding-left: 24px;
			}
		}

		> a:last-of-type, a:last-of-type:visited, a:last-of-type:active {
			padding-right: 10px;
			padding-left: 9px;

			&:hover > i:before {
				transform: scale(1.2);
				color: $bright-colors_black-text;
			}

			> i {
				@include mdi-icon(chevron-down, 16px, 0, -1px);
				margin-left: 10px;
				color: #808080;
				line-height: 0;
			}

			&:before, &:after {
				content: none;
			}
		}
	}

	> .pin {
		display: none;
		width: 31px;
		height: 31px;
		border-radius: 4px;
		float: right;
		margin: 7px;
		box-shadow: 0 0 3px silver;
		line-height: 26px;
		text-align: center;

		@include mdi-icon(pin, $margin-right: 0, $font-size: 18px, $vertical-align: -4px, $color: #a0a0a0);

		&:hover {
			&:before {
				color: $bright-colors_vibrant-blue;
			}
		}
	}

	> item-details-navigator-menu {
		display: block;
		border-top: solid 1px #e8e8e8;

		a.show-more {
			margin: 20px 0;
			display: block;
			font-size: 14px;
		}
	 
		ol {
			width: 100%; // annars går den inte hela vägen ut till kanten, när opinnad och navigatorn är bred pga lång breadcrumb
			font-size: 14px;
			overflow-y: auto;
			overflow-x: hidden;
			max-height: calc(100vh - 170px);
			border-radius: 0 0 4px 4px;
			@include webkit-scrollbar($track-color: white, $thumb-color: rgba(black, .05));

			&:hover {
				@include webkit-scrollbar($track-color: white);
			}

			> li {
				&:not(:first-child) {
					border-top: solid 1px #ebebeb;
				}
				cursor: pointer;

				&:nth-last-of-type(2) {
					border-bottom: solid 1px #ebebeb;
				}

				> a, a:active, a:active {
					color: #7a7a7a;
					display: block;
					padding: 8px 20px 10px;

					max-width: 40ch;
					line-height: 1.2;
				}

				&:hover {
					background: #f7fbfd;
				}

				&.selected {
					background: $bright-colors_baby-blue-2;

					> a {
						color: $bright-colors_black-text;
					}
				}

				&.load-more {
					text-align: center;

					> a {
						padding: 20px;
					}
				}
			}
		}
	}
}