﻿@use "sass:math";

@import "variables";
@import "mixins/material-deisgn-icons";
@import "mixins/responsive";
@import "mixins/ng-animate";

.toasts-backdrop {
	position: fixed;
	z-index: 20;
	top: -80px;
	height: 80px;

	left: calc(50% - 375px);
    width: 750px;

	margin-left: math.div($left-menu-width, 2);
	@include when-left-menu-collapsed {
		margin-left: math.div($collapsed-left-menu-width, 2);
	}

	transition: box-shadow .2s;

	&.toasts-1 {
		box-shadow: 0 -60px 230px 140px rgba(0, 0, 0, 0.09);
	}

	&.toasts-2 {
		box-shadow: 0 -30px 240px 180px rgba(0, 0, 0, 0.09);
	}

	&.ng-enter {
		transition: opacity ease .4s, box-shadow .2s;
		opacity: 0;
	}

	&.ng-enter.ng-enter-active {
		opacity: 1;
	}

	&.ng-leave {
		transition: opacity ease .6s, box-shadow .2s;
		opacity: 1;
	}

	&.ng-leave.ng-leave-active {
		opacity: 0;
	}
}

#toast-container {
	padding-top: 20px;
    padding-bottom: 40px;
	background-color: unset;

	left: calc(50% - 350px);
    width: 700px;

	margin-left: math.div($left-menu-width, 2);
	@include when-left-menu-collapsed {
		margin-left: math.div($collapsed-left-menu-width, 2);
	}

	> .toast {
		box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.13), 2px 2px 1px rgba(0, 0, 0, 0.03), 0 0px 16px 0px rgba(0, 0, 0, 0.07);
		padding: 20px 20px 20px 50px;
		border-radius: 0;
		line-height: 1.4;
		position: relative;
		cursor: default;
		width: 700px !important;
		background-color: white;
		color: $bright-colors_dark-gray-text;
		vertical-align: middle;
		padding: 20px 20px 20px 30px;
		background-image: none !important;
		margin-bottom: 8px !important;
		display: flex;
		align-items: center;
		justify-content: space-between;

		> div {
			flex: 1;
			margin-right: 10px;
		}

		&:hover {
			
		}

		&.toast-success {
			border-top: solid 4px $bright-colors_mint-green;
		}

		&.toast-info {
			border-top: solid 4px $bright-colors_vibrant-blue-brighter;
		}

		&.toast-warning {
			border-top: solid 4px #f7c42c;
		}

		&.toast-error {
			border-top: solid 4px $bright-colors_vibrant-red;
			background-color: #fff5f3;
			color: $bright-colors_black-text;

			.toast-close-button:hover {
				background-color: darken(#fff5f3, 10%);
			}
		}

		.toast-close-button {
			height: 14px;
			width: 14px;
			position: static;
			right: unset;
			top: unset;
			font-size: unset;
			order: 3;
			line-height: 1em;
			color: white;
			box-sizing: content-box;
			padding: 7px;
			border-radius: 20px;
			text-align: center;
			@include mdi-icon(close, 16px, 0, 0px, $bright-colors_dark-gray-text);

			&:hover {
				opacity: 1;
				background-color: $bright-colors_baby-blue-1;
			}
		}

		&.has-buttons {
			cursor: default;

			.toast-message {
				display: flex;
				width: 100%;

				> p {
					max-width: 420px;
					display: flex;
					align-self: center;
				}

				> .button-container {
					flex: 1;
					text-align: right;

					> button {
						min-width: 100px;
						margin-left: 10px;
						padding-top: 12px;
						padding-bottom: 12px;
					}
				}
			}
		}
	}
}
