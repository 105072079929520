﻿
@import "../variables";

@mixin checkbox($top-offset: 0px, $left-offset: 0px) {
	$fill-color: #bbbbbb;
	$checked-fill-color: $bright-colors_vibrant-blue;
	$border-color: #b3b3b3;
	$checked-border-color: darken($bright-colors_vibrant-blue, 5%);
	$checkbox-left: $left-offset;
	$checkbox-top: $top-offset;

	label, span {
		box-sizing: border-box;
		display: inline-block;
		-moz-user-select: none;
		user-select: none;
		position: relative;
        padding-left: 25px;
        line-height: 17px;

        &:before {
            position: absolute;
            content: '';
            left: $checkbox-left;
            top: $checkbox-top;
            width: 16px;
            height: 16px;
            overflow: hidden;
            border-radius: 2px;
            background-color: $fill-color;
            border: solid 1px $border-color;
            border-radius: 1em;
        }

        &:after {
            position: absolute;
            left: $checkbox-left + 7px;
            top: $checkbox-top + 4px;
			content: '';
			display: block;
			width: 2px;
			height: 6px;
			border: none;
			transform: rotate(45deg);
		}

		&.hover:after, &:hover:after {
			border: solid lighten($fill-color, 10%);
			border-width: 0 2px 2px 0;
		}
	}

	input {
		box-sizing: border-box;
		display: none;

		&:checked + label, &:checked + span {
			&:before {
                border: solid 1px $checked-border-color;
			    background: $checked-fill-color;
			}

			&.hover:before, &:hover:before {
				border-color: darken($checked-border-color, 4%);
				background-color: darken($checked-fill-color, 6%);
			}

			&:after {
				content: '';
				display: block;
				width: 2px;
				height: 6px;
				border: solid white;
				border-width: 0 2px 2px 0;
				color: white;

				animation: boom 0.3s ease;
			}
		}

		&:disabled + label, &:disabled + span {
			color: #b6b6b6;

			&:before {
                background-color: transparent;
			    border-color: rgba(black, 0.1);
			}

			&:after {
				border-color: silver;
			}

            &:hover:not(:checked):after {
				border: none;
			}

			&:hover:before {
				background-color: transparent;
			    border-color: rgba(black, 0.1);
			}
		}
	}
}


@include keyframes(boom) {
	25% {
		transform: scale(1.4) rotate(45deg);
	}
}
