﻿

#organizationSettings-warehouses-view {
    .page-content{
        max-width: 1100px;

        .add {
	        float: right;
	        margin-top: -20px;
            margin-bottom: 20px;
	        @include mdi-icon(plus, 22px, 0);
        }

		.empty {
			margin-top: -50px;

			> p {
				margin-bottom: 50px;
			}
		}
    }
}