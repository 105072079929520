@use "sass:math";

@mixin torn-paper($number-of-zags, $zag-height: 10px, $filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.07)) drop-shadow(0 2px 1px rgba(0, 0, 0, .04))) {
	filter: $filter;
	
	> :first-child {
		$vertices: ();
		$numberOfJags: if($number-of-zags % 2 == 0, $number-of-zags, $number-of-zags + 1);

		@for $i from 0 to $numberOfJags {
			$x: #{math.div($i, $numberOfJags) *100}#{'%'};
			$y: if($i % 2 == 0, 0%, $zag-height);
			
			$vertices: $vertices, $x $y;
		}

		$vertices: $vertices, 100% 0;

		@for $i from $numberOfJags to 0 {
			$x: #{math.div($i, $numberOfJags) *100}#{'%'};
			$y: if($i % 2 == 0, 100%, calc(100% - #{$zag-height}));
			
			$vertices: $vertices, $x $y;
		}

		$vertices: $vertices, 0 100%;
		
		clip-path: polygon($vertices);
	}
}