﻿#organizationSettings-saleschannels_view-view > .page-content {
	width: 784px;

	.more {
		float: right;
		margin-top: -20px;
		position: relative;
    	right: -43px;
	}

	.details {
		margin: 0 auto;
		margin-top: 7em;
		max-height: 56px;
	}

	.buttons {
		margin: 4em auto;
		text-align: right;

		> button {
			margin-left: 10px;
		}
	}

	h2 {
		margin-bottom: 1.5em;
	}
}