﻿@import "../variables";

header.wizard-step-indicator-background {
	$background: #f1f1f1;
	
	padding: 60px 0 0;
	background: $background;

	z-index: 2;
	position: sticky;
	top: -100px;
	box-shadow: 0px 13px 27px 8px #f5f5f5cf;

	margin-bottom: 70px;

	> .breadcrumb {
		--breadcrumb-background: #f6f6f6;
		--breadcrumb-hover-background: #fcfcfc;
	}

	> .breadcrumb {
		margin-left: -$left-menu-width;
		margin-top: -50px;

		@include when-left-menu-collapsed {
			margin-left: -$collapsed-left-menu-width;
		}
	}

	> h1 {
		margin-bottom: 60px;
	}

	> .wizard-step-indicator {
		margin: 0 auto;
		position: relative;
		top: 10px;

		--background-color: #{$background};

		&.steps-2 {
			width: 330px;
		}
	
		&.steps-3 {
			width: 450px;
		}
	}
}

.wizard-step-indicator {
	$margin-top: 48px;
	$padding: 32px 0 0;
	$line-top: 7px;
	$circle-top: -31px;
	$done-circle-top: -32px;
	$done-line-top: -25px;

	&.flipped {
		$margin-top: 48px;
		$padding: 0 0 31px 0;
		$line-top: 32px;
		$circle-top: 26px;
		$done-circle-top: 25px;
		$done-line-top: 32px;
	}

	margin-top: $margin-top;
	margin-bottom: 80px;
	user-select: none;
	cursor: default;
	--background-color: #f5f5f5;

	--done-line-color: #9ec9e0;
	--done-circle-color: #98c4db;

	--active-fill-color: #7fbee6;
	--active-border-color: #81bbe0;
	--active-line-color: #9ec9e0;

	&.steps-2 > ol > li { min-width: 45%; }
	&.steps-3 > ol > li { min-width: 30%; }
	&.steps-4 > ol > li { min-width: 23.5%; }
	&.steps-5 > ol > li { min-width: 18.5%; }

	> ol {
		position: relative;
		display: flex;
		justify-content: space-between;
		padding: $padding;
		overflow: hidden;

		&:before {
			background: #dfdfdf;
			content: "";
			display: block;
			height: 2px;
			position: absolute;
			top: $line-top;
			width: 100%;
		}

		> li {
			> i {
				font-family: $muli;
				font-size: 13px;
				border: solid 2px #d3d3d3;
				background-color: var(--background-color);
				color: #929292;
				border-radius: 100%;
				text-align: center;
				vertical-align: middle !important;
				line-height: 24px !important;
				width: 10px;
				height: 10px;
				position: absolute;
				left: 50%;
				margin-left: -5px;
				top: $circle-top;
				z-index: 2
			}

			vertical-align: top;
			text-transform: uppercase;
			display: inline-block;
			text-align: center;
			position: relative;
			font-size: 11px;
			color: #b5b5b5;
			box-sizing: border-box;
			letter-spacing: .5px;
			font-family: $raleway;
			line-height: 13px;

			&.done {
				> i {
					border-color: var(--done-circle-color);
				}

				&:before {
					background: var(--done-line-color);
					content: "";
					display: block;
					height: 2px;
					position: absolute;
					top: $done-line-top;
					width: 150%;
					left: -50%;
				}
			}

			&.active {
				color: $bright-colors_black-text;
				font-weight: 500;

				> i {
					background-color: var(--active-fill-color);
					border-color: var(--active-border-color);
					width: 12px;
					height: 12px;

					margin-left: -6px;
					top: $done-circle-top;
				}

				&:before {
					background: var(--active-line-color);
					content: "";
					display: block;
					height: 2px;
					position: absolute;
					top: $done-line-top;
					left: -50%;
					width: 100%;
					z-index: 1;
				}
			}
		}
	}
}