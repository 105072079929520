﻿@mixin webkit-scrollbar($width: 10px, $track-color: rgba(black, .07), $thumb-color: rgba(black, .15), $border-radius: 8px, $on-hover-only: false) {
	// Firefox:
	
	@if $track-color == white {
		scrollbar-color: $thumb-color transparent;
	}
	@else {
		scrollbar-color: $thumb-color $track-color;
	}

	scrollbar-width: thin;

	&::-webkit-scrollbar {
		width: $width;
	}
 
	&::-webkit-scrollbar-track {
		@if $track-color != white {
			box-shadow: inset 1px 0 1px 1px rgba(black, .02);
		}
		background-color: $track-color;
	}

	&::-webkit-scrollbar-thumb {
		opacity: 0;
		background-color: $thumb-color;
		border-radius: $border-radius;
	}

	@if $on-hover-only == true or $on-hover-only == transparent {
		&:not(:hover) {
			scrollbar-width: none;

			&::-webkit-scrollbar-track {
				background-color: transparent;
				box-shadow: none;
			}

			&::-webkit-scrollbar-thumb {
				background-color: transparent;
			}
		}
	}
}