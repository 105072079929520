@import "../mixins/center-content";

.centered-circle-container {
	@include center-content;

	> div {
		background: #f1f1f1;
		border-radius: 100%;
		padding: 60px;

		width: 310px;
		height: 310px;
		text-align: center;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		> i {
			display: inline-block;
			width: 160px;
			height: 152px;
		}

		> h2 {
			margin-top: 26px;
			margin-bottom: 12px;
			font-size: 19px;
			line-height: 1.5;
		}

		> a {
			@include mdi-icon(arrow-right, 16px, 6px, -2px, gray, 'after');
		}
	}
}