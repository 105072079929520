﻿@import '../_variables.scss';
@import '../mixins/_material-deisgn-icons.scss';
@import "../mixins/ng-animate";
@import "../mixins/_center-content.scss";

#organizationSettings-saleschannels_wizard-view .page-content {
	padding: 0;
	padding-bottom: 50px;

	> header {
		$background: #f1f1f1;
		background: $background;
		padding: 60px 0 0;

		> h1 {
			margin-bottom: 55px;
		}

		> .wizard-step-indicator {
			width: 590px;

			&.steps-3 {
				width: 450px;
			}

			margin: 0 auto;
			position: relative;
			top: 10px;

			--background-color: #{$background};
		}
	}

	> section {
		width: 800px;
		margin: 0 auto;
		padding-top: 20px;

		@include animate-show(.3s);

		&.ng-leave, &.ng-hide-add {
			transition: none !important;
		}

		> h2:first-of-type,
		> form > h2:first-of-type {
			margin: 2.5em 0;
			text-align: center;
			font-weight: 500;
		}

		.button-row,
		> form > .button-row {
			text-align: right;
			padding: 0;

			> button {
				margin-right: 0;
				margin-left: 14px;
			}
		}

		&.transport {
			width: 700px;
		}

		&.email-notifications {
			width: 550px;
			text-align: center;

			> img {
				height: 80px;
				margin: 2.5em auto;
				opacity: .9;
				display: block;
			}

			> p {
				width: 400px;
				margin: 2.5em auto 3.5em
			}

			.notification {
				text-align: left;

				.switch {
					> span {
						font-family: $raleway;
						font-size: 15px;
						font-weight: 600;
						line-height: 20px;
						padding-left: 43px;
					}
				}

				> div {
					background: #f8f8f8;
					border-left: solid 3px #dadada;
					padding: 30px;
					padding-left: 40px;
					margin-top: 20px;

					> p {
						margin: 0 0 1.5em 0;
					}

					> .patch-info {
						margin: 26px 0 5px;
						text-align: left;

						> span {
							background: $bright-colors_baby-blue-2;
							font-weight: 300;
							color: #7b7b7b;
						}
					}
				}
			}

			hr {
				margin: 2.2em 0;
			}

			.button-row {
				margin-top: 3em;
			}
		}

		&.import-items {
			padding-top: 2em;

			text-align: center;
			width: 400px;

			> p {
				margin: 2.5em 0;
			}

			.switch {
				> span {
					font-family: $raleway;
					font-size: 15px;
					font-weight: 600;
					line-height: 20px;
					padding-left: 43px;
				}
			}

			.button-row {
				margin-top: 5em;
				text-align: center;

				> button {
					margin: 0 7px;
				}
			}
		}

		&.done {
			@include center-content();

			margin-top: -70px;

			> .check-circle {
				background-color: $bright-colors_background_green;
				width: 220px;
				height: 220px;
				border-radius: 220px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;

				font-family: $raleway;
				font-size: 21px;
				font-weight: 600;
				row-gap: 7px;

				@include mdi-icon(check, 100px, 0, 0, $bright-colors_mint-green);

				&:before {
					margin-top: -15px;
				}
			}

			> ul {
				border-top: solid 1px $bright-colors_silver-stroke-on-smoke;
				margin: 4em 0;
				

				> li {
					border-bottom: solid 1px $bright-colors_silver-stroke-on-smoke;
					padding: 30px;
					display: flex;

					@include mdi-icon(information-outline, 24px, 0, 0, #999999);

					> span {
						margin-left: 25px;
						max-width: 360px;
						line-height: 1.4;
						padding: 2px 0;
					}
				}
			}
		}
	}
}