﻿@use "sass:math";
@import "../variables";
@import "../mixins/responsive";

.breadcrumb {
	$base-border-color: #e6e6e6 !default;
	$base-border-radius: 4px !default;
	$base-background-color: #fafafa !default;
	$base-spacing: 1.5em !default;
	$action-color: #999 !default;
	$base-link-color: $action-color !default;
	$medium-gray: #999 !default;
	$light-gray: #DDD !default;
	$medium-screen: em(640) !default;
	$breadcrumb-border-color: $base-border-color;
	$breadcrumb-border: 1px solid $breadcrumb-border-color;
	$breadcrumb-height: $base-spacing * 1.5;
	$breadcrumb-arrow-color: $breadcrumb-border-color;
	$breadcrumb-background: $base-background-color;
	$breadcrumb-hover-background: white;
	$breadcrumb-color: $medium-gray;
	$breadcrumb-color-hover: $bright-colors_dark-gray-text;
	$breadcrumb-color-active: $breadcrumb-color;
	z-index: 0;

	display: inline-block;
	margin-bottom: $base-spacing;
	text-align: left;

	padding-right: 5px;
    white-space: nowrap;

	left: 76px + $left-menu-width;
	@include when-left-menu-collapsed {
		left: 76px + $collapsed-left-menu-width;
	}

	will-change: left;
    transition: left .2s;

	top: 36px + $top-header-height;
	position: absolute;

	--breadcrumb-background: #{$breadcrumb-background};
	--breadcrumb-hover-background: #{$breadcrumb-hover-background};

	> a, > a:visited, > a:active {
		background-color: var(--breadcrumb-background);
		border-left: 0 !important;
		border: $breadcrumb-border;
		color: $action-color; // Tar bort !important här, om den inte behövs
		display: inline-block;
		font-size: 14px;
		line-height: $breadcrumb-height;
		margin-bottom: 2px;
		margin-right: -3px; // Annars uppstår ett fult glapp mellan elementen
		padding: 1px 8px 2px 20px;
		position: relative;
		text-decoration: none;
		outline: none;

		&:nth-child(5) { z-index: 1 }
		&:nth-child(4) { z-index: 2 }
		&:nth-child(3) { z-index: 3 }
		&:nth-child(2) { z-index: 4 }
		&:nth-child(1) { z-index: 5 }

		&:not([href]) {
			font-weight: 300;
			color: #c5c5c5 !important;
			cursor: default;
		}

		&:not(:first-of-type) {
			margin-left: -2px; // För att slippa små glapp mellan länkarna
		}

		&:first-of-type {
			border-bottom-left-radius: $base-border-radius;
			border-left: $breadcrumb-border !important;
			border-top-left-radius: $base-border-radius;
			padding-left: math.div($breadcrumb-height, 2);
		}

		&:last-of-type {
			background-color: var(--breadcrumb-background);
			border-bottom-right-radius: $base-border-radius;
			border-top-right-radius: $base-border-radius;
			color: $breadcrumb-color-active;
			padding-right: 12px;

			&:before,
			&:after {
				left: calc(100% - 1px);
			}
		}

		&[href]:focus,
		&[href]:hover {
			background-color: var(--breadcrumb-hover-background);
			color: $breadcrumb-color-hover !important;

			&:after {
				border-left-color: var(--breadcrumb-hover-background);
			}
		}

		&:after,
		&:before {
			position: absolute;
			top: 0;
			right: auto;
			bottom: 0;
			left: 100%;
			border-bottom: math.div($breadcrumb-height, 2) solid transparent;
			border-left: math.div($breadcrumb-height, 3.5) solid transparent;
			border-top: math.div($breadcrumb-height, 2) solid transparent;
			content: '';
			display: block;
			margin: auto;
			z-index: 2;
		}

		&:before {
			border-left-color: $breadcrumb-arrow-color;
			margin-left: 1px;
			z-index: 1;
		}

		&:after {
			border-left-color: var(--breadcrumb-background);
		}
		/*@include media($medium-screen) {
      font-size: 1em;
      padding: 0 (math.div($breadcrumb-height, 2)) 0 ($breadcrumb-height / 1.5);
    }*/
	}

	&.item-details {
		&:not(.open) #item-group-navigator {
			display: none;
		}
	}
}
