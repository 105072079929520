﻿// dark colors
$dark-colors_midnight-blue: #243541;
$dark-colors_midnight-blue-brighter: #314556;
$dark-colors_sky-blue: #70b1d5;
$dark-colors_mint-green: #2db085;
$dark-colors_mint-green-text: #32c394;
$dark-colors_red: #cc6c5b;
$dark-colors_vibrant-red: #cf5b47;
$dark-colors_blue: #4d8bae;
$dark-colors_blue_text: #56a2cc;
$dark-colors_dark-teal: #135359;
$dark-colors_dim-white: #d2dada;

// bright colors
$bright-colors_mint-green: #2bc9a2;
$bright-colors_red: #f67d68;
$bright-colors_vibrant-red: #f86b52;
$bright-colors_baby-blue-1: #e3eff6;
$bright-colors_baby-blue-2: #eff5f8;
$bright-colors_blue-text-on-whitesmoke: #51a4d2;
$bright-colors_blue-text-on-white: #67b8e6;
$bright-colors_vibrant-blue: #64b2de;
$bright-colors_vibrant-blue-brighter: #75c4f6;
$bright-colors_dim-blue: #98c4db;
$bright-colors_yellow: #f9cc3b;
$bright-colors_yellow-bright: #fffad9;
$bright-colors_black-text: #232323;
$bright-colors_dark-gray-text: #606060;
$bright-colors_silver-stroke-on-white: #e3e3e3;
$bright-colors_silver-stroke-on-smoke: #dedede;
$bright-colors_unchecked-gray: #bbbbbb;
$bright-colors_turquoise: #d8f5ee;
$bright-colors_turquoise-bright: #e0fff7;
$bright-colors_pink-bright: #ffceea;
$bright-colors_pink: #fe9cd5;
$bright-colors_salmon: #f9dfdb;
// bakgrundstoner
$bright-colors_background_green: #ecf3f0;

$background-highlight: #F3FEFE;
$main-background-color: #f5f5f5;
$button-color: #a7a7a7;
$button-color-contrast: $bright-colors_mint-green;
$top-header-height: 50px;
$left-menu-width: 260px;
$collapsed-left-menu-width: 62px;

$muli: 'Muli', sans-serif;
$raleway: 'Raleway', sans-serif;

// Vanliga mixins

@mixin link {
	color: $bright-colors_blue-text-on-whitesmoke;
	text-decoration: none;
	cursor: pointer;
	outline: none;

	&:hover {
		color: darken($bright-colors_blue-text-on-whitesmoke, 15%);
	}
}

@mixin placeholder {
  $placeholders: ":-webkit-input" ":-moz" "-moz" "-ms-input";
  @each $placeholder in $placeholders {
    &:#{$placeholder}-placeholder {
      @content;
    }
  }
}

$prefix-for-webkit:    true !default;
$prefix-for-mozilla:   true !default;
$prefix-for-microsoft: true !default;
$prefix-for-opera:     true !default;
$prefix-for-spec:      true !default; // required for keyframe mixin

@mixin disable-prefix-for-all() {
  $prefix-for-webkit:    false;
  $prefix-for-mozilla:   false;
  $prefix-for-microsoft: false;
  $prefix-for-opera:     false;
  $prefix-for-spec:      false;
}

// Adds keyframes blocks for supported prefixes, removing redundant prefixes in the block's content
@mixin keyframes($name) {
  $original-prefix-for-webkit:    $prefix-for-webkit;
  $original-prefix-for-mozilla:   $prefix-for-mozilla;
  $original-prefix-for-microsoft: $prefix-for-microsoft;
  $original-prefix-for-opera:     $prefix-for-opera;
  $original-prefix-for-spec:      $prefix-for-spec;

  @if $original-prefix-for-webkit {
    @include disable-prefix-for-all();
    $prefix-for-webkit: true;
    @-webkit-keyframes #{$name} {
      @content;
    }
  }
  @if $original-prefix-for-mozilla {
    @include disable-prefix-for-all();
    $prefix-for-mozilla: true;
    @-moz-keyframes #{$name} {
      @content;
    }
  }

  $prefix-for-webkit:    $original-prefix-for-webkit;
  $prefix-for-mozilla:   $original-prefix-for-mozilla;
  $prefix-for-microsoft: $original-prefix-for-microsoft;
  $prefix-for-opera:     $original-prefix-for-opera;
  $prefix-for-spec:      $original-prefix-for-spec;

  @if $original-prefix-for-spec {
    @keyframes #{$name} {
      @content;
    }
  }
}

@function _linear-positions-parser($pos) {
  $type: type-of(nth($pos, 1));
  $spec: null;
  $degree: null;
  $side: null;
  $corner: null;
  $length: length($pos);
  // Parse Side and corner positions
  @if ($length > 1) {
    @if nth($pos, 1) == "to" { // Newer syntax
      $side: nth($pos, 2);

      @if $length == 2 { // eg. to top
        // Swap for backwards compatability
        $degree: _position-flipper(nth($pos, 2));
      }
      @else if $length == 3 { // eg. to top left
        $corner: nth($pos, 3);
      }
    }
    @else if $length == 2 { // Older syntax ("top left")
      $side: _position-flipper(nth($pos, 1));
      $corner: _position-flipper(nth($pos, 2));
    }

    @if ("#{$side} #{$corner}" == "left top") or ("#{$side} #{$corner}" == "top left") {
      $degree: _position-flipper(#{$side}) _position-flipper(#{$corner});
    }
    @else if ("#{$side} #{$corner}" == "right top") or ("#{$side} #{$corner}" == "top right") {
      $degree: _position-flipper(#{$side}) _position-flipper(#{$corner});
    }
    @else if ("#{$side} #{$corner}" == "right bottom") or ("#{$side} #{$corner}" == "bottom right") {
      $degree: _position-flipper(#{$side}) _position-flipper(#{$corner});
    }
    @else if ("#{$side} #{$corner}" == "left bottom") or ("#{$side} #{$corner}" == "bottom left") {
      $degree: _position-flipper(#{$side}) _position-flipper(#{$corner});
    }
    $spec: to $side $corner;
  }
  @else if $length == 1 {
    // Swap for backwards compatability
    @if $type == string {
      $degree: $pos;
      $spec: to _position-flipper($pos);
    }
    @else {
      $degree: -270 - $pos; //rotate the gradient opposite from spec
      $spec: $pos;
    }
  }
  $degree: unquote($degree + ",");
  $spec:   unquote($spec + ",");
  @return $degree $spec;
}

@function _position-flipper($pos) {
 @return if($pos == left, right, null)
         if($pos == right, left, null)
         if($pos == top, bottom, null)
         if($pos == bottom, top, null);
}


@mixin linear-gradient($pos, $G1, $G2: null,
                       $G3: null, $G4: null,
                       $G5: null, $G6: null,
                       $G7: null, $G8: null,
                       $G9: null, $G10: null,
                       $fallback: null) {
  // Detect what type of value exists in $pos
  $pos-type: type-of(nth($pos, 1));
  $pos-spec: null;
  $pos-degree: null;

  // If $pos is missing from mixin, reassign vars and add default position
  @if ($pos-type == color) or (nth($pos, 1) == "transparent")  {
    $G10: $G9; $G9: $G8; $G8: $G7; $G7: $G6; $G6: $G5;
     $G5: $G4; $G4: $G3; $G3: $G2; $G2: $G1; $G1: $pos;
     $pos: null;
  }

  @if $pos {
    $positions: _linear-positions-parser($pos);
    $pos-degree: nth($positions, 1);
    $pos-spec:   nth($positions, 2);
  }

  $full: $G1, $G2, $G3, $G4, $G5, $G6, $G7, $G8, $G9, $G10;

  // Set $G1 as the default fallback color
  $fallback-color: nth($G1, 1);

  // If $fallback is a color use that color as the fallback color
  @if (type-of($fallback) == color) or ($fallback == "transparent") {
    $fallback-color: $fallback;
  }

  background-color: $fallback-color;
  background-image: -webkit-linear-gradient($pos-degree $full); // Safari 5.1+, Chrome
  background-image: unquote("linear-gradient(#{$pos-spec}#{$full})");
}

@mixin input-on-white {
	background: #fafafa;

	&:-webkit-autofill {
		background: #fafafa !important;
		-webkit-box-shadow: inset 0 0 0 9999px #fafafa;

		&:focus {
			background: #f7fafc !important;
			-webkit-box-shadow: inset 0 0 0 9999px #f7fafc;
		}
	}

	&.focus, // selectize
	&:focus {
		background: #f7fafc;
	}
}