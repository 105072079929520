﻿@import "../throbbers/throbber-1";
@import "../font-awesome";
@import "../treecontrol";
@import "../mixins/checkbox";
@import "../mixins/ng-animate";
@import "../mixins/material-deisgn-icons";
@import "../mixins/animations";
@import "../mixins/icons";
@import "../mixins/webkitscrollbar";

#item-group-selection-backdrop {
	position: fixed;
	content: ' ';
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 3;

	visibility: hidden;
	background-color: rgba(0,0,0,0.07);
	opacity: 0;
	-moz-transition: opacity 0.2s ease, visibility 0s 0.2s;
	-o-transition: opacity 0.2s ease, visibility 0s 0.2s;
	-webkit-transition: opacity 0.2s ease, visibility 0s 0.2s;
	transition: opacity 0.2s ease, visibility 0s 0.2s;

	&.visible {
		visibility: visible;
		opacity: 1;
		-moz-transition: opacity 0.2s ease;
		-o-transition: opacity 0.2s ease;
		-webkit-transition: opacity 0.2s ease;
		transition: opacity 0.2s ease;
	}
}

#new-item {
	-moz-transition: opacity ease 0.1s;
	-o-transition: opacity ease 0.1s;
	-webkit-transition: opacity ease 0.1s;
	transition: opacity ease 0.1s;
	width: 860px;
	margin: 0 auto;

	> .crumbtrail {
		margin-left: 178px;
		margin-bottom: 20px;
	}

	> h1 {
		margin-bottom: 60px;
		text-align: center;
	}

	.accordion {
		background: #fafafa;
		box-shadow: 0 0 14px rgba(black, .07);

		> li {
			background: #fafafa;
		}

		> li > .head {
			font-family: $raleway;
			font-size: 15px;
			font-weight: 500;
		}
	}

	#item-form {
		.show-more-fields {
			color: $bright-colors_dark-gray-text;
			display: block;
			width: 100%;
			margin-top: 16px;
			margin-bottom: 5px;

			@include mdi-icon(plus-circle-outline, 21px, $vertical-align: -2px, $color: #7a7a7a);

			&.toggled {
				@include mdi-icon(minus-circle-outline, 21px, $vertical-align: -2px, $color: #7a7a7a);
			}

			&:before {
				background: #f5f5f5;
				box-shadow: 0 0 10px 10px #f5f5f5;
			}

			&:after {
				content: '';
				border-bottom: solid 1px #d4d4d4;
				display: block;
				margin-top: -10px;
			}

			> span {
				background: #f5f5f5;
				position: relative;
				padding-right: 9px;
			}
		}

		> #general {
			margin-bottom: 50px;

			.regular-price {
				> input {
					width: 100px;
				}

				> .checkbox {
					margin-left: 8px;
				}
			}

			.vat-rate {
				> div {
					width: 204px;
				}
			}

			> #general-more {
				@include animate-expand(387px);
			}

			.editable {
				max-height: 237px;
				overflow-y: auto;
				@include webkit-scrollbar;
			}

			#item-group {
				width: 100%;
				margin-bottom: 10px;

				> .form-field {
					width: calc(50% - 12.5px);

					> output {
						display: block;
						border-bottom: solid 1px #e0e0e0;
						padding: 7px 0 8px;
						cursor: pointer;

						&:hover {
							border-bottom-color: #b3d9ea;

							&:after {
								color: $bright-colors_vibrant-blue;
							}
						}

						@include mdi-icon(pencil, 16px, $color: #98c4db, $location: 'after');

						&:after {
							float: right;
						}
					}
				}

				#item-group-selection {
					&::before {
						content: ' ';
						width: 0;
						height: 0;
						border-style: solid;
						border-width: 0 11px 11px 11px;
						border-color: transparent transparent white transparent;
						position: absolute;
						top: -11px;
						left: 16px;
					}

					visibility: hidden;
					opacity: 0;
					position: absolute;
					background-color: white;
					z-index: 3;
					top: 66px;
					box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.09);

					-moz-transition: opacity 0.2s ease, transform 0s 0.2s, visibility 0s 0.2s;
					-o-transition: opacity 0.2s ease, transform 0s 0.2s, visibility 0s 0.2s;
					-webkit-transition: opacity 0.2s ease, transform 0s 0.2s, visibility 0s 0.2s;
					transition: opacity 0.2s ease, transform 0s 0.2s, visibility 0s 0.2s;

					-moz-transform: scale(.9);
					-ms-transform: scale(.9);
					-o-transform: scale(.9);
					-webkit-transform: scale(.9);
					transform: scale(.9);

					transform-origin: top left;

					&.visible {
						visibility: visible;
						opacity: 1;

						-moz-transform: none;
						-ms-transform: none;
						-o-transform: none;
						-webkit-transform: none;
						transform: none;

						-moz-transition: opacity .2s ease, transform 0.2s ease;
						-o-transition: opacity .2s ease, transform 0.2s ease;
						-webkit-transition: opacity .2s ease, transform 0.2s ease;
						transition: opacity .2s ease, transform 0.2s ease;
					}

					width: 400px;
					padding: 25px;

					> div {
						> h3 {
							font-size: 17px;
							font-weight: 500;
							margin-bottom: 20px;
						}

						> treecontrol {
							@extend %treecontrol;
					
							> ul {
								margin-bottom: 20px;
								max-height: 250px;
								min-height: 90px;
								overflow-y: auto !important; // Lös med specificitet när jag orkar
								@include webkit-scrollbar();
							}
						}

						> button {
							margin-right: 10px;
						}
					}
				}
			}
		}

		.purchase-order-reminder {
			.selectize-control {
				margin-left: 5px;
			}
		}

		#variations-section {
			white-space: nowrap;
			vertical-align: top;

			> div {
				transition: background-color ease .2s, opacity .2s, visibility .2s 0s;
				box-sizing: border-box;
				background-color: transparent;
				box-shadow: none;
				overflow: initial;
				position: relative;

				> .switch {
					display: block;
					margin-bottom: 30px;
				}

				> #variations {
					@include animate-expand(150px);

					> div {
						margin-bottom: 22px;

						.add-variation {
							color: $bright-colors_dark-gray-text;
                            @include mdi-icon(plus-circle-outline, 21px, $vertical-align: -2px, $color: #7a7a7a);
							display: inline-block;

							&[disabled], &[disabled]:before {
								color: silver;
                            }
						}

						.variation-options .selectize-input {
							white-space: normal;
							height: auto;
						}

						.rename-variation {
							@include animate-show(.5s);
						}

                        .rename-variation, .variation-name > variation-autocomplete {
							background-color: #f0f0f0;
							padding: 25px;
							display: flex;
							align-items: flex-end;
							margin: 20px 0;

							> .form-field {
								margin: 0;
								width: 300px;
								margin-right: 30px;
							}

							> button {
								margin-right: 10px;
							}
						}

                        .variation-options {
                            > span {
								padding-bottom: 7px;

								> span.added {
									display: inline-block;
									@include animate-bounce(.6s, 1);
								}

                                > .variation-commands {
                                    height: 18px;
                                    width: 18px;
                                    line-height: 17px;
                                    border: solid 1px #d6d6d6;
                                    border-radius: 18px;
                                    display: inline-block;
                                    text-align: center;
                                    vertical-align: 0px;
                                    margin-left: 8px;

                                    @include mdi-icon(dots-horizontal, $color: #929292, $margin-right: 0, $vertical-align: -1px);

                                    &:hover {
                                        border-color: #83c9f5;
                                        &:before {
                                            color: #76b4d7;
                                        }
                                    }
                                }
                            }
                        }
					}

					> button {
						margin-top: 20px;
						@include animate-show($fade-out-duration: 0s, $show-delay: .5s);
					}
				}

				> #variants {
					@include animate-expand(500px);
					margin-left: -200px;
					max-height: 500px;
					background: #fafafa;
					width: 800px;
					box-shadow: 0 0 23px rgba(black, .08);
					padding: 30px;
					padding-right: 25px;
					border-left: solid 4px #89c2ea;
					margin-top: 40px;
					position: relative;

					&.matrix {
						width: 940px;
						margin-left: -270px;
					}

					&.ng-hide-add-active {
						// Fulhack eftersom knappen tar upp plats när den fadar in, och därför trycker ner #variants
						margin-top: -44px;
					}

					> a {
						color: $bright-colors_black-text;
						position: absolute;
						right: 25px;
						top: 25px;
						@include mdi-icon(close, $color: $bright-colors_dark-gray-text, $font-size: 18px, $vertical-align: -2px);
					}

					> h3 {
						> i {
							background: #8dc0e4;
							display: inline-block;
							width: 50px;
							height: 50px;
							vertical-align: -17px;
							margin-right: 12px;
							border-radius: 50px;

							&:before {
								content: '';
								@include icon-tag-multiple();
								height: 100%;
								width: 100%;
								background-size: 32px;
							}
						}
					}

					> #variants-table-wrapper {
						margin-left: 7px;
						margin-right: 7px;
						margin-top: 25px;
						max-height: 422px;
						overflow-y: auto;
						@include webkit-scrollbar();

						> table {
							width: 100%;

							tr > th:last-child {
								width: 100px;
							}

							

							tr > td.option-name {
								padding-right: 15px;
							}
						}
					}
				}
			}
		}

		> #supplier {
			margin-bottom: -28px;

			.add-supplier-button {
				display: inline-block;
				color: $bright-colors_dark-gray-text;
				@include mdi-icon(plus-circle-outline, 21px, $vertical-align: -2px, $color: #7a7a7a);
				
				&.hint {
					margin-top: 20px;
				}
			}
		}

		> #stock {
			table {
				width: auto;
				margin: 23px 0 10px;
				tr > th:last-child { width: 190px; }
				tr > th:nth-child(2) { width: 90px; }
				tr > td:first-child { padding-right: 15px; }
			}

			.add-warehouse-button {
				display: inline-block;
				color: $bright-colors_dark-gray-text;
				@include mdi-icon(plus-circle-outline, 21px, $vertical-align: -2px, $color: #7a7a7a);
				margin-top: 20px;
				margin-bottom: 10px;
			}

			.variants {
				margin-top: 30px;

				.unit-cost > input {
					width: 80px;
					margin-bottom: 10px;
				}

				.split {
					.table {
						margin-top: 0;
						width: 256px;


						&:first-of-type {
							float: left;
							margin-right: 60px;
						}
					}
				}
			}
		}
	}
}

#new-supplier-form, #new-stocklocation-form {
	> div {
		width: 358px;
	}

	> .loading {
		> .throbber {
			margin: 38px auto 0;
		}

		> p {
			text-align: center;
			color: #5E5E5E;
			font-size: 15px;
			margin-top: 10px;
		}
	}
}