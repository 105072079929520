﻿@import "base";

.sweet-overlay {
	opacity: 1;
	background-color: rgba(black, 0.13);
}

.sweet-alert {
	padding: 30px;
	box-shadow: 2px 3px 3px rgba(black, 0.1);
	border-radius: 0;
	font-family: $muli;
	color: $bright-colors_black-text;

	> h2 {
		margin: 0;
		font-size: 17px;
        line-height: 1.4em;
		margin-bottom: 10px;
	}

	> p {
		font-size: 14px;
		font-weight: 400;
	}

	&:not(.show-input) {
		> fieldset {
			display: none;
		}
	}
	
	> fieldset > input {
		box-shadow: none !important;
		border: solid 1px #E7E7E7 !important;
		font-size: 14px !important;
	}

	> .sa-button-container {
		margin-top: 30px;
		display: flex;
		justify-content: center;
		margin-bottom: 10px;

		> button {
			margin: 0 10px;
			padding: 10px 20px 12px;
			min-width: 100px;
			font-family: $muli;
			font-weight: 600;
			font-size: 14px;
			margin: 0 7px;

			background-color: transparent  !important;
			border: solid 2px #b4b4b4  !important;
			border-radius: 4px !important;
			color: #666666 !important;

			-moz-box-sizing: border-box !important;
			-webkit-box-sizing: border-box !important;
			box-sizing: border-box !important;

			&:hover {
				background-color: rgba(0,0,0,0.06) !important;
				color: #303030 !important;
				border-color: #828282 !important;
			}

			&:active {
				background-color: rgba(0,0,0,0.15) !important;
			}

			&.confirm {
				order: 1;

				background-color: $button-color-contrast !important;
				color: white  !important;
				border-color: transparent !important;

				&:hover {
					background-color: darken($button-color-contrast, 5%) !important;
				}

				&:active {
					background-color: darken($button-color-contrast, 10%) !important;
				}
			}

			&.cancel {
				order: 2;
			}
		}
	}
}