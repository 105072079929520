﻿	@import "../font-awesome";
@import "../throbbers/throbber-1";
@import "../variables";
@import "../treecontrol";
@import "../mixins/material-deisgn-icons";
@import "../mixins/checkbox";
@import "../mixins/ng-animate";
@import "../mixins/responsive";
@import "../mixins/webkitscrollbar";

// Matchar både items-home och item-group
.item-groups {
	margin: 60px auto;
	min-width: 764px;

	> h2 {
		border-bottom: solid 1px $bright-colors_silver-stroke-on-smoke;
		padding-bottom: 17px;
		margin-bottom: 40px;

		.more {
			width: 42px;
			padding: 0;
			padding-left: 7px;
			float: right;
			margin-top: -20px;
			line-height: 43px;

			@include mdi-icon(dots-horizontal, 22px, 0);
		}
	}

	.tiles {
		margin-bottom: 30px;

		&.limited {
			overflow: hidden;
			max-height: 99px * 3;
			position: relative;

			> .expand {
				position: absolute;
				bottom: -3px;
				right: 0;
				background: #e8f1f7;
				padding-top: 24px;

				&:hover {
					background: $bright-colors_baby-blue-1;
				}

				> h3 {
					color: $bright-colors_vibrant-blue;
					margin-top: 10px;
					margin-bottom: 10px;

					@include mdi-icon(plus);
				}
			}
		}

		> li {
			width: 20%;

			@include when-page-atmost(1300px) {
				width: 25%;
			}

			@include when-page-atmost(1100px) {
				width: 33.33%;
			}

			> h3 {
				font-size: 15px;
				color: $bright-colors_black-text;
				margin-bottom: 3px;
				line-height: 1.3;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				padding-top: 2px;
			}

			> span {
				color: #8c8c8c;
				font-weight: 300;
			}
		}
	}
}

#items-items-itemgroup-view-view .page-content {
	max-width: 1300px;
}

#items-items-itemgroup-view-view .page-content > div {

	@include when-page-atmost(1450px) {
		> h1 {
			margin-top: 30px;
		}
	}

	> h1 > span { cursor: pointer; }

	> .add-subgroup {
		position: relative;
		left: 50%;
		margin-left: -76px;
		margin-bottom: 50px;
		display: inline-block;

		@include mdi-icon(folder-plus, 22px, $vertical-align: -2px);

		&:hover:after {
			border-color: darken($bright-colors_blue-text-on-whitesmoke, 15%);
		}

		&:after {
			content: '';
			border-left: solid 2px $bright-colors_blue-text-on-whitesmoke;
			border-bottom: solid 2px $bright-colors_blue-text-on-whitesmoke;
			position: absolute;
			left: -5px;
			height: 10px;
			width: 10px;
		}
	}

	> .breadcrumb {
		padding: 5px;
		margin-top: -22px;
		margin-left: -5px;
		z-index: 4;
		cursor: pointer;
		top: 86px + 22px;

		&:before, &:after {
			content: none;
			display: none;
		}

		> a {
			margin-left: 0;
		}

		> a:last-of-type {
			padding-right: 10px;
			padding-left: 9px;

			&:hover > i:before {
				transform: scale(1.2);
				color: $bright-colors_black-text;
			}

			> i {
				@include mdi-icon(chevron-down, 16px, 0, -1px);
				margin-left: 10px;
				color: #808080;
				line-height: 0;
			}

			&:before, &:after {
				content: none;
			}
		}

		&.open {
			background-color: white;
			border: solid 1px #D6D6D6;
			border-radius: 4px;
			box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.05);

			margin-top: -23px;
			margin-left: -6px;

			cursor: default;

			> a {
				background-color: white;
				border-color: white !important;
				color: #C1C1C1;

				&:after {
					border-left-color: white;
				}

				&:last-of-type > i { display: none; }
			}
		}

		> .item-group-quick-nav {
			z-index: 3;
			display: none;
			margin-top: 10px;

			&.visible {
				display: block;
			}

			min-width: 300px;
			max-width: 400px;
			margin-bottom: 10px;

			.treecontrol > ul {
				overflow-y: auto;
				@include webkit-scrollbar;
			}
		}
	}

	> .item-group-quick-nav-backdrop {
		position: fixed;
		content: ' ';
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 3;

		visibility: hidden;
		background-color: rgba(0,0,0,0.07);
		opacity: 0;
		-moz-transition: opacity 0.2s ease, visibility 0s 0.2s;
		-o-transition: opacity 0.2s ease, visibility 0s 0.2s;
		-webkit-transition: opacity 0.2s ease, visibility 0s 0.2s;
		transition: opacity 0.2s ease, visibility 0s 0.2s;

		&.visible {
			visibility: visible;
			opacity: 1;
			-moz-transition: opacity 0.2s ease;
			-o-transition: opacity 0.2s ease;
			-webkit-transition: opacity 0.2s ease;
			transition: opacity 0.2s ease;
		}
	}

	> .items-list {
		> h2 {
			border-bottom: solid 1px $bright-colors_silver-stroke-on-smoke;
			padding-bottom: 17px;
			margin-bottom: 40px;

			.more {
				float: right;
				margin-top: -20px;

				@include mdi-icon(plus, 22px, 0);
			}
		}

		> .empty {
			color: gray;
			font-weight: 300;
		}

		.load-more {
			text-align: center;
			margin-top: 30px;
		}

		> .table {
			> tbody {
				> tr {
					td:nth-of-type(2) > .item-number {
						word-break: break-word;
						overflow-wrap: break-word;
					}
				}
			}
			
		}
	}

	#move-items-dialog {
		position: fixed;
		top: 50%;
		left: calc((100% + #{$left-menu-width}) / 2 - 280px);
		margin-top: -280px;

		> .popup-content {
			padding: 30px;
			width: 500px;
			height: 500px;

			> .item-group-picker > .treecontrol  {
				background: #fafafa;
				margin: 30px 0;
			    height: 343px;
				overflow-y: auto;
				@include webkit-scrollbar($on-hover-only: true);
			}

			button {
				margin-right: 10px;
			}
		}
	}

	.paging {
		margin-top: 40px;
		text-align: center;
	}
}

#items-menu a {
	&.bulk { @include mdi-icon(table-edit, 18px, $vertical-align: -2px); }
	&.new { @include mdi-icon(plus, 19px, $vertical-align: -2px); }
	&.import { @include mdi-icon(paperclip, 18px, $vertical-align: -2px); }
}

#subgroups-menu a {
	&.l10n { @include mdi-icon(earth, 18px, $vertical-align: -1px); }
	&.tree { @include mdi-icon(file-tree, 18px, $vertical-align: -1px); }
	&.add { @include mdi-icon(plus, 19px, $vertical-align: -2px); }
}