@mixin background-blob($color, $aspect-ratio: 1) {
	&:before {
		content: '';
		background-color: $color;
		position: absolute;
		border-radius: 42.5%;
		transform: rotate(15deg);
		width: 100%;
		aspect-ratio: $aspect-ratio;
		z-index: -1;
	}
}