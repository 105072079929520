﻿

$base-background-color: white;
$base-font-color: #3b3b3b;
$base-accent-color: #a0a0a0;
$base-border-radius: 10px;
$base-font-size: 14px;

.button-group {
	$button-group-background: $base-background-color;
	$button-group-color: lighten($base-font-color, 30);
	$button-group-border: 1px solid #E8E8E8;
	$button-group-inner-border: 1px solid lighten(silver, 18);
	$button-group-background-checked: $base-accent-color;
	$button-group-color-checked: white;
	$button-group-border-checked: darken($button-group-background-checked, 10);

	input {
		display: none;
	}

	label {
		margin-bottom: 0;
		float: left;

		.button-group-item {
			background: $button-group-background;
			border-radius: 0;
			color: $button-group-color;
			cursor: pointer;
			display: inline-block;
			font-size: $base-font-size;
			font-weight: normal;
			line-height: 1;
			padding: .75em 1em;
			border-bottom: $button-group-border;
			border-left: 0;
			border-right: $button-group-inner-border;
			border-top: $button-group-border;
			width: auto;


			&:hover {
				background-color: darken($button-group-background, 3);
			}
		}

		&:first-child .button-group-item {
			border-top: $button-group-border;
			border-bottom-left-radius: $base-border-radius;
			border-left: $button-group-border;
			border-top-left-radius: $base-border-radius;
			border-top-right-radius: 0;
		}

		&:last-child .button-group-item {
			border-bottom-left-radius: $base-border-radius;
			border-bottom-right-radius: $base-border-radius;
			border-bottom: $button-group-border;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: $base-border-radius;
			border-right: $button-group-border;
			border-top-right-radius: $base-border-radius;
		}

		input:checked + .button-group-item {
			background: $button-group-background-checked;
			border: 1px solid $button-group-border-checked;
			box-shadow: inset 0 1px 2px darken($button-group-background-checked, 10);
			color: $button-group-color-checked;
		}
	}
}
