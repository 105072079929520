﻿@import "../variables";
@import "../mixins/webkitscrollbar";
@import "../mixins/material-deisgn-icons";

#organizationSettings-saleschannels_wizard-view > .page-content configure-sales-channel,
#organizationSettings-saleschannels_form-view > .page-content > configure-sales-channel {
	width: 860px;
	display: block;
    margin: 0 auto;
	
	> h1 {
		margin-bottom: 2em;
	}

	output.glow {
		background: #f0f0f0;
		padding: 12px 21px;
		border-radius: 100px;
		color: $bright-colors_dark-gray-text;
		margin-right: 5px;
	}

	#connection {
		> h1 {
			> a {
				display: block;
				font-size: 14px;
				font-weight: 300;
				margin-top: 20px;
				line-height: 1.3em;
				font-family: $muli;
			}
		}

		button {
			margin-top: 10px;
		}

		.verified {
			display: inline-block;
			padding: 12px 25px 12px 18px;

			@include mdi-icon(check-circle, 27px, 8px, -5px, $bright-colors_mint-green);

			color: $bright-colors_dark-gray-text;
			font-weight: bold;

			background: #f0f0f0;
			border-radius: 8px;
		}
	}

	#stock {
		.stock-settings {
			> hr {
				margin: 20px 0 40px;
			}

			> h3 {
				font-weight: 600;
				font-size: 16px;
			}

			> p {
				color: $bright-colors_dark-gray-text;
				margin: .7em 0 2.5em;
				max-width: 50ch;
			}

			> .options {
				display: grid;
				grid-template-columns: 1fr 1fr;
				column-gap: 20px;

				> div {
					> label {
						display: block;

						> span {
							margin-bottom: 2px;
							display: block;
						}

						> p.low-contrast {
							margin-left: 24px;
						}
					}
				}
			}
		}
	}

	.import-items-directions {
		> h4 {
			margin: 2.5em 0 1em;
		}
	}
}

.webshop-acidtest-popup {
	position: static;
	width: 840px;
	height: 600px;
	background: white;
	padding: 40px;
	box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.1);
	margin-top: -30px;

	h2 {
		margin-bottom: 25px;
		font-weight: 600;
	}

	> webshop-acidtest {
		height: 480px;
		overflow-y: auto;
		display: block;
		@include webkit-scrollbar($track-color: darken(#465160, 5%), $thumb-color: lighten(#465160, 15%));

		> output {
			min-height: 100%;
			box-sizing: border-box;
		}
	}

	button {
		margin-top: 30px;
		margin-right: 10px;
	}
}