﻿@mixin multiline-ellipsis($font-size: 14px, $line-height: 1.4, $lines-to-show: 2, $background-color: #f5f5f5) {
	box-sizing: border-box;
	display: block;
	max-height: $font-size*$line-height*$lines-to-show;
	font-size: $font-size;
	line-height: $line-height;
	overflow: hidden;
	position: relative;

	&:after {
		content: "";
		text-align: right;
		position: absolute;
		bottom: 0;
		right: 0;
		width: 50%;
		height: #{$line-height}em;
		top: #{($lines-to-show - 1) * $line-height}em;
		@include linear-gradient(left, transparent, $background-color 85%);
	}
}

@mixin multiline-ellipsis-maskbased($font-size: 14px, $line-height: 1.4, $lines-to-show: 2, $background-color: #f5f5f5) {
	box-sizing: border-box;
	display: block;
	max-height: $font-size*$line-height*$lines-to-show;
	font-size: $font-size;
	line-height: $line-height;
	overflow: hidden;
	position: relative;

	&:after {
		content: "";
		text-align: right;
		position: absolute;
		bottom: 0;
		right: 0;
		width: 50%;
		height: #{$line-height}em;
		top: #{($lines-to-show - 1) * $line-height}em;
		background-color: $background-color;
		mask-image: linear-gradient(to right, transparent 0%, black);
		-webkit-mask-image: linear-gradient(to right, transparent 0%, black);
	}
}