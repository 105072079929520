﻿@import "../mixins/material-deisgn-icons";
@import "../mixins/checkbox";
@import "../components/popup";
@import "../variables";
@import "../mixins/responsive";

#items-purchase-createpaymentfile-view .page-content {
	> h1 {
		margin-bottom: 60px;
	}

	> form {
		max-width: 1000px;
		margin: 0 auto;

		> table {
			tr {
				&.invalid > td { color: #aaaaaa; }
				&.invalid > td > label { color: #aaaaaa; }

				> td {
					> label { color: $bright-colors_black-text; }

					> .missing { color: $bright-colors_vibrant-red; }

					> .edit { 
						color: $bright-colors_blue-text-on-whitesmoke;
						@include mdi-icon(pencil, $margin-right: 3px);
						cursor: pointer;
					}
				}
			}

			> tfoot {
				> tr {
					> td {
						text-align: right;
						padding-top: 26px;

						span {
							display: block;
							font-size: 13px;
							text-transform: uppercase;
							color: #7a7a7a;
							margin-bottom: 10px;
						}

						output {
							color: black;
							font-weight: 600;
							font-size: 22px;
							letter-spacing: -.3px;
						}
					}
				}
			}
		}

		> button { margin-top: -30px; }
	}

	> #download-popup {
		@extend %popup;

		&:before {
			display: none;
		}

		&.visible {
			top: calc(50% - 232px);
			left: calc(50% - 319px);
		}

		> .popup-content {
			background-color: white;
			width: 555px;
			height: 344px;
			text-align: center;
			padding: 50px;

			> i {
				@include mdi-icon(download, 80px, $color: #B0B0B0);
			}

			> h2 {
				font-size: 16px;
				text-transform: none;
				color: black;
				margin-top: 25px;
				margin-bottom: 18px;
			}

			> span {
				color: #41a3d4;
				@include mdi-icon(help-circle, 20px, $color: #85b5d4, $vertical-align: middle);
			}

			> form {
				border-top: solid 1px #e6e6e6;
				margin-top: 50px;
				padding-top: 40px;

				> p {
					font-size: 15px;
					margin-bottom: 30px;
				}

				> button {
					min-width: 130px;

					&:not(:last-child) {
						margin-right: 10px;
					}
				}
			}
		}
	}

	#collect-bankgironummer {
		@include center-content-in-main-area-when-fits(680px);

		> div {
			width: 600px;

			> label {
				margin: 30px 0;
				width: 200px;
			}
		}
	}
}