﻿
@import "../variables";
@import "../mixins/material-deisgn-icons";
@import "../mixins/ng-animate";

#items-items-itemtree-view .page-content {
	width: 900px;
	margin: 0 auto;
	
	h1 {
		margin-bottom: 60px;
	}

	.explanation-text {
		margin: 0 auto 60px;
		max-width: 60ch;
		text-align: center;
		color: $bright-colors_dark-gray-text;
	}

	section#item-groups {
		> div {
			display: inline-block;
			width: 50%;
			box-sizing: border-box;
			vertical-align: top;

			> .tree-editor {
				margin-bottom: 50px;

				&.empty > button.add-root-node {
					@extend .contrast;
				}
			}

			> button {
				margin-right: 10px;
			}
		}

		> div:first-child {
			> p {
				margin: 40px 0 20px;
				font-size: 13px;
				color: gray;
			}
		}

		> div:last-child {
			padding: 30px 0 0 50px;

			> p:nth-of-type(1) {
				@include animate-show(.5s, 1s);
				@include mdi-icon(play-circle-outline, 24px, $color: $bright-colors_vibrant-blue, $margin-right: 7px);
				color: #7a7a7a;
				margin-bottom: 30px;

				&::before {
					float: left;
				}
			}

			> p:nth-of-type(2) {
				@include animate-show(.5s, 1s);
				@include mdi-icon(information-outline, 24px, $color: $bright-colors_vibrant-blue, $margin-right: 7px);
				color: #7a7a7a;

				&::before {
					float: left;
				}
			}
		}
	}
}