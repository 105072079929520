﻿@import "../variables";
@import "../mixins/material-deisgn-icons";
@import "../mixins/webkitscrollbar";

.accordion {
	list-style: none;
	margin: 0;
	padding: 0;

	> li {
		margin: 0;
		border-bottom: solid 1px $bright-colors_silver-stroke-on-smoke;
		transition: background .2s;
		will-change: contents;

		&:first-child {
			border-top: solid 1px $bright-colors_silver-stroke-on-smoke;
		}

		> .head {
			@include mdi-icon(chevron-right, 20px, 4px, -2px, $bright-colors_dark-gray-text);
			padding: 20px 20px 20px 10px;
			cursor: pointer;

			&:before {
				transition: transform .2s;
			}
		}

		> .body {
			padding: 0 20px 0 38px;
			max-height: 0;
			overflow: hidden;
			@include webkit-scrollbar();

			transition: max-height .2s, padding .2s;
		}

		&.expanded {
			background: #f1f1f1;

			> .head:before {
				transform: rotate(90deg);
			}

			> .body {
				max-height: 300px;
				overflow: auto;
				padding: 0 20px 20px 38px;
			}
		}
	}
}