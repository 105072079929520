﻿@import '../mixins/_animations.scss';
@import "../variables";
@import "../mixins/material-deisgn-icons";

#items-purchase-goodsreception-view .page-content {
	> form {
		> h2 {
			text-align: center;
			margin-top: 40px;
			margin-bottom: 30px;
		}

		max-width: 1000px;
		margin: 70px auto;

		section#occasion {
			width: 780px;
		}

		table {
			margin-bottom: 40px;

			tr > th:last-child,
			tr > td:last-child {
				width: 120px;
			}

			tr > th:nth-last-child(2),
			tr > td:nth-last-child(2) {
				padding-right: 12px;
			}

			input {
				width: 80px;
			}
		}

		.button-row {
			padding-left: 0;
		}

		> button {
			margin-right: 10px;
			min-width: 100px;
		}

		.complete-delivery-confirmation {
			background: white;
			padding: 30px;
			box-shadow: 0 0 0 100vw rgba(black, .04), 0 0 10px rgba(black, .04);
			z-index: 10;
			position: relative;

			@include animate-slide-up;

			> p {
				float: left;
				width: 32em;
				padding: 10px;
			}

			> div {
				display: flex;
				flex-direction: column;
				align-items: flex-end;

				> button {
					width: 240px;
					margin: 5px;
				}
			}
		}
	}
}