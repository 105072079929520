﻿@import "../mixins/material-deisgn-icons";
@import "../mixins/impromptu-animation";

#organizationSettings-saleschannels_emailnotifications-view > .page-content {
	width: 550px;
	text-align: center;
}

#organizationSettings-saleschannels_emailnotifications-view > .page-content > sales-channel-email-settings {
	> h1 {
		margin-bottom: 1rem;
	}

	> .email-designer-icon {
		width: 77px;
		margin: 2em 0 5em;
	}

	.notification {
		text-align: left;
		margin: 0;
		min-height: unset;

		.switch {
			> span {
				font-family: $raleway;
				font-size: 15px;
				font-weight: 600;
				line-height: 20px;
				padding-left: 43px;
			}

			> i {
				float: right;
				background: $bright-colors_turquoise;
				color: #469e8e;
				padding: 9px 16px 9px 13px;
				border-radius: 10px;
				font-weight: 600;
				line-height: 17px;
				margin-top: -9px;
				opacity: 0;
				transition: opacity .6s;
				@include mdi-icon(check, 18px, $vertical-align: -2px);
		
				&.active {
					@include impromptu-animation(.3s) {
						0% { transform: scale(.4); }
						60% { transform: scale(1.2); }
					   100% { transform: scale(1); }
					};
					transition: opacity .2s;
					opacity: 1;
				}
			}
		}

		> div {
			background: #f8f8f8;
			border-left: solid 3px #dadada;
			padding: 30px;
			padding-left: 40px;
			margin-top: 20px;
			margin-left: 0;

			> p {
				margin: 0 0 1.5em 0;
			}

			> label:last-of-type {
				margin-bottom: 2em;
			}
		}
	}

	hr {
		margin: 2.5em 0;
	}

	.button-row {
		padding-left: 0;
		margin-top: 4em;
		text-align: left;
	}
}