@mixin contrastedEventLogEntry {
	&:before {
		background-color: hsl(202, 29%, 85%);
		color: #67889f;
		border-color: #cad9e2;
	}
}

@mixin errorEventLogEntry { 
	&:before {
		background: #f78b78;
		color: #fff;
		border-color: #f28a78;
	}
}

@mixin warningEventLogEntry { 
	&:before {
		background: #f3d678;
		border-color: #f1d16b;
		color: #a97e31;
	}
}

order-event-log {
	> h2 {
		margin-bottom: 45px;
	}

	> .event-log {
		border-left: solid 2px #e1e1e1;
		margin-left: 21px;
	
		> li.show-previous {
			@include mdi-icon(dots-horizontal, 24px, 0, 0, #7E7E7E);
			margin-bottom: 41px !important;
			margin-top: -3px;
			user-select: none;
	
			&::before {
				background-color: #EAEAEA;
				border-radius: 100%;
				border: none;
				text-align: center;
				vertical-align: middle !important;
				line-height: 40px !important;
				width: 40px;
				height: 40px;
				transition: background-color .1s;
				top: -12px;
			}
	
			&::after {
				content: none;
			}
	
			> a {
				color: #8a8a8a;
			}
	
			&:hover {
				&:before {
					background-color: #e0e0e0;
				}
	
				> a {
					color: #444444;
				}
			}
		}
	
		> li {
			position: relative;
			padding-left: 33px;
	
			@include animate-show(.3s);
	
			&:not(:last-child) {
				margin-bottom: 32px;
			}
	
			&::before {
				background-color: hsl(0, 0%, 88%);
				border: solid 2px #dcdcdc;
				color: #8d8d8d;
				border-radius: 100%;
				text-align: center;
				vertical-align: middle !important;
				line-height: 36px !important;
				width: 36px;
				height: 36px;
				position: absolute;
    			left: -21px;
				top: -10px;
			}
	
			&::after {
				content: '';
				display: inline-block;
				height: 2px;
				width: 8px;
				background-color: #e0e0e0;
				position: absolute;
				top: 9px;
				left: 19px;
			}
	
			> span {
				color: #929292;
				display: block;
				font-weight: 300;
				margin-top: 6px;
				line-height: 0.8;
			}
	
			> p {
				line-height: 1.3;
				color: #444444;
				display: inline-block;
	
				&.has-tooltip {
					@include mdi-icon(more, 16px, $vertical-align: -2px, $color: $bright-colors_dim-blue, $location: 'after');
				}
			}
				
			> .message-wrapper {
				position: relative;
				padding-top: 15px;
	
				$background: #e1efeb;
				$hover-background: darken($background, 4%);
	
				&.outbound > .message {
					background: $background;
					border-color: $background;
					@include multiline-ellipsis($line-height: 1.3, $lines-to-show: 3, $background-color: $background);
					box-sizing: content-box;
				}
	
				&.outbound > i {
					border-color: transparent transparent $background transparent;
				}
	
				&.outbound:hover {
					> .message {
						background: $hover-background;
						border-color: $hover-background;
						@include multiline-ellipsis($line-height: 1.3, $lines-to-show: 3, $background-color: $hover-background);
						box-sizing: content-box;
					}
	
					> i {
						border-color: transparent transparent $hover-background transparent;
					}
				}
	
				&.inbound:hover {
					> .message {
						background: darken(#eaeaea, 4%);
						border-color: darken(#eaeaea, 4%);
						@include multiline-ellipsis($line-height: 1.3, $lines-to-show: 3, $background-color: darken(#eaeaea, 4%));
						box-sizing: content-box;
					}
	
					> i {
						border-color: transparent transparent darken(#eaeaea, 4%) transparent;
					}
				}
	
				> .message {
					background: #eaeaea;
					overflow: hidden;
					white-space: pre-line;
					border: solid 15px #eaeaea;
					@include multiline-ellipsis($line-height: 1.3, $lines-to-show: 3, $background-color: #eaeaea);
					box-sizing: content-box;
					cursor: pointer;
					transition: background-color .1s, border-color .1s;
					border-radius: 4px;
					margin-left: 2px;
	
					> br + br {
						display: none;
					}
				}
	
				> i {
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 25px 15px 0px;
					border-color: transparent transparent #eaeaea transparent;
					position: absolute;
					z-index: 1;
					top: 3px;
					left: 13px;
					right: auto;
	
					transition: border-color .1s;
				}
	
				> .reply {
					@include mdi-icon(reply);
					margin-top: 5px;
					display: block;
					text-align: right;
					margin-bottom: -15px;
				}
			}
		}
	}
}