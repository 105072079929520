﻿@import "../variables";
@import "../font-awesome";
@import "../mixins/multiline-ellipsis";
@import "../mixins/material-deisgn-icons";
@import "../mixins/responsive";

#items-items-itemgroup-item-view .page-content {
	padding: 85px 90px 40px;

	> .ng-scope {
		> .item-details-navigator {
			position: absolute;
			left: 76px + $left-menu-width;
	
			@include when-left-menu-collapsed {
				left: 76px + $collapsed-left-menu-width;
			}
	
			will-change: left;
			transition: left .2s;
	
			top: 86px;
			z-index: 4;
	
			&.pinned {
				bottom: 40px;
				height: calc(100vh - 123px);
				position: fixed;
			}
		}
	
		> .item-group-quick-nav-backdrop {
			position: fixed;
			content: ' ';
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 3;
	
			visibility: hidden;
			background-color: rgba(0,0,0,0.07);
			opacity: 0;
			-moz-transition: opacity 0.2s ease, visibility 0s 0.2s;
			-o-transition: opacity 0.2s ease, visibility 0s 0.2s;
			-webkit-transition: opacity 0.2s ease, visibility 0s 0.2s;
			transition: opacity 0.2s ease, visibility 0s 0.2s;
	
			&.visible:not(.pinned) {
				visibility: visible;
				opacity: 1;
				-moz-transition: opacity 0.2s ease;
				-o-transition: opacity 0.2s ease;
				-webkit-transition: opacity 0.2s ease;
				transition: opacity 0.2s ease;
			}
		}
	
		> article {
			min-width: 720px;
			max-width: 1300px;
			margin: 0 auto;

			@include when-page-atmost(1700px) {
				max-width: 1150px;
			}

			@include when-page-atmost(1500px) {
				max-width: 1000px;
			}
	
			&.items-navigator-pinned {
				max-width: #{'min(60vw, 1300px)'};

				@include when-page-atmost(2300px) {
					margin-left: 410px;
				}

				@include when-page-atmost(1900px) {
					margin-left: 390px;
				}

				@include when-page-atmost(1700px) {
					margin-left: 380px;
				}

				@include when-page-atmost(1580px) {
					margin-left: 370px;
				}

				@include when-page-atmost(1440px) {
					margin-left: 360px;
				}
			}
	
			> section {
				&#item-properties {
					margin-top: 60px;
			
					> div {
						margin: 0 auto;
			
						> .edit {
							float: right;
						}
			
						> .more {
							float: right;
							margin-left: 13px;
						}
			
						> p.description {
							@include multiline-ellipsis($background-color: $main-background-color);
							width: 30em;
							color: $bright-colors_dark-gray-text;
							margin-bottom: 2em;
							line-height: 1.5em;
						}
			
						.details {
							margin-top: 50px;
							width: 85%;
							max-width: 720px;
						}
					}
				}
		
				&.variants {
					margin-top: 2.5em;
		
					> h2 {
						margin-bottom: 2em;
					}
				}
			}
		}
	}
}