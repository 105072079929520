﻿@import "../mixins/material-deisgn-icons";

#sales-orderarchive-index-view .page-content {
	max-width: 1200px;
	min-width: 1085px;
	margin: 0 auto;

	#table-headline {
		margin-bottom: 30px;
		overflow: hidden;
		position: relative;

		.filter {
			float: right;

			> .search-box {
				> input {
					padding-right: 30px;
					transition: width .3s;
					width: 70px;

					&.ng-not-empty, &:focus {
						width: 150px;
					}
				}

				@include mdi-icon(magnify, 18px, 0, middle, #606060);

				&:before {
					position: absolute;
					right: 8px;
					top: 10px;
				}
			}
		}
	}

	.paging {
		margin-top: 40px;
		text-align: center;
	}
}