﻿@keyframes appear {
	0% { opacity: 0; }
	49.99% { opacity: 0; }
	50.01% { opacity: 1; }
}

@keyframes slide-up {
	0% { transform: translateY(100%); opacity: .2; }
	100% { transform: translateY(); opacity: 1; }
}

@keyframes fade-in {
	0% { opacity: 0; }
	100% { opacity: 1; }
}

@mixin animate-slide-up($duration: .6s, $delay: 0s) {
	animation: slide-up;
	animation-fill-mode: backwards;
	animation-duration: $duration;
	animation-delay: $delay;
	animation-iteration-count: 1;
	animation-timing-function: cubic-bezier(.16,.88,.24,1);
}

@function str-replace($string, $search, $replace: '') {
	$index: str-index($string, $search);
  
	@if $index {
	@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}
  
	@return $string;
}

@mixin animate-bounce($speed: 1s, $repeat: infinite, $scale: 1.2, $delay: 0s, $hidden-until: 0s) {
	$keyframesName: str-replace('bounce-' + $scale, '.', '');

	@keyframes #{$keyframesName} {
		  0% { transform: scale(1); }
		 45% { transform: scale(1); }
		 75% { transform: scale(($scale * 0.875)); }
		 85% { transform: scale($scale); }
		100% { transform: scale(1); }
	}

	@if $hidden-until == 0s {
		animation: #{$keyframesName} $speed linear $delay $repeat;
	}
	@else {
		animation: #{$keyframesName} $speed linear $delay $repeat, appear ($hidden-until * 2);
	}
}

@mixin animate-bounce-in($bounce-delay: 0s, $bounce-duration: 1s, $repeat: infinite, $scale: 1.2, $fade-in-delay: 0s, $fade-in-duration: .3s) {
	$keyframesName: str-replace('bounce-' + $scale, '.', '');

	@keyframes #{$keyframesName} {
		  0% { transform: scale(1); }
		 45% { transform: scale(1); }
		 75% { transform: scale(($scale * 0.875)); }
		 85% { transform: scale($scale); }
		100% { transform: scale(1); }
	}

	animation: #{$keyframesName} $bounce-duration linear $bounce-delay $repeat, fade-in $fade-in-duration $fade-in-delay backwards;
}

@mixin animate-fade-in($speed: .4s, $delay: 0s) {
	@if $delay == 0s {
		animation: fade-in $speed;
	}
	@else {
		opacity: 0;
		animation: fade-in $speed $delay;
		animation-fill-mode: forwards;
	}
}