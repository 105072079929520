@import "mixins/callout-arrow";
@import "font-awesome";
@import "variables";
@import "mixins/responsive";
@import "mixins/material-deisgn-icons";
@import "mixins/animations";
@import "mixins/impromptu-animation";

@include keyframes(slideBackground) {
	0% {
		background-position-x: -97vw;
	}
	100% {
		background-position-x: 103vw;
		background-repeat: repeat;
	}
}

@include keyframes(fadeIn) {
	0% {
		opacity: 0;
	}

	30% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-moz-document url-prefix() {
	#toggle-left-menu {
		line-height: 48px !important;
	}
}

body {
	&[loading-state] {
		> .root-view .page-content {
			opacity: 0.2;
			pointer-events: none;
			transition: opacity .1s;
		}

		> #page-load-progress {
			display: block;
			opacity: 1;
			animation: slideBackground 3s infinite linear, fadeIn .5s linear;
			animation-fill-mode: both;
		}
	}

	> header {
		background-color: white;
		color: #989898;
		z-index: 5;

        border-bottom: solid 1px #efefef;

        position: fixed;
        left: 0;
        top: 0px;
        height: $top-header-height;
        right: 0;

		> #utility {
			float: right;
			> ul {
				display: flex;

				> li {
					-moz-box-sizing: border-box;
					-webkit-box-sizing: border-box;
					box-sizing: border-box;
					margin-top: 0;
					vertical-align: top;
                    height: $top-header-height;
                    line-height: $top-header-height - 3px;
					padding: 1px 19px 0;
					display: inline-block;
					border-left: solid 1px #EBEBEB;
					font-size: 14px;
					cursor: pointer;
					-moz-user-select: none;
					-ms-user-select: none;
					-webkit-user-select: none;
					user-select: none;

					display: flex;
					align-items: center;

					&#organization-menu-link {
						> svg {
							height: 23px;
							width: 23px;
							margin-right: 7px;
						}
					}

					&#user-menu-link {
						> svg {
							height: 23px;
							width: 23px;
							margin-right: 8px;
						}
					}
					
					&#notifications-link {
						width: 62px;
						display: flex;
						justify-content: center;
						background-color: transparent;
						transition: background-color .06s, box-shadow .2s, color .06s;
						cursor: default;

						> svg {
							height: 23px;
							width: 23px;
						}

						color: #ccc;

						@at-root {
							@keyframes turn-red { 
								0%  { color: #ccc; } 
								100% { color: $bright-colors_red; }
							}
						}

						&.realtime-connecting {
							@include fa-icon($fa-var-circle-o-notch);
							@include fa-spin;
							animation: turn-red;
							animation-delay: 7s;
							animation-duration: 1s;
							animation-fill-mode: forwards;
							animation-play-state: running;

							> svg {
								display: none;
							}
						}

						&.realtime-error, &.offline {
							color: $bright-colors_red;

							> svg {
								filter: drop-shadow(0px 0px 10px #f67d68CC);
							}
						}

						&:before {
							margin: 0 !important;
						}
					}

					&:hover {
						background-color: #fafafa;
						color: #666;
						box-shadow: 0 1px 0px 0px #dadada;
					}

					> span {
						margin-right: 4px;
						font-size: 14px;
						text-align: center;
						line-height: 25px;
					}
				}
			}
		}

		#logo-area {
            float: left;
            background-color: #283B48;
            text-align: center;
            width: $left-menu-width;
			transition: width .2s, background-color .1s;
			height: $top-header-height;

			&.hover {
				background-color: lighten(#283B48, 1.5);
			}

			@include when-left-menu-collapsed() {
				width: $collapsed-left-menu-width;
			}
        }
	}

	> #main-nav {
        position: fixed;
        left: 0;
        top: $top-header-height;
        width: $left-menu-width;
        bottom: 0;
		z-index: 5;
		transition: width .2s;
		box-shadow: inset 0 4px 2px -4px #00000047;

		> .logo {
			position: absolute;
			left: 4px;
			top: -50px;
			transition: width .2s, left .2s, top .2s;

			fill: #2C404F;
			color: #2CA27C;
			cursor: pointer;

			&.hover {
				fill: lighten(#2C404F, 1.5);
				color: $dark-colors_mint-green-text;
			}

			@include when-left-menu-collapsed {
				width: 105px;
				left: 3px;
				top: -54px;
				clip: rect(0px, 56px, 68px, 0px);
			}
		}

		@include when-left-menu-collapsed() {
			width: $collapsed-left-menu-width;
		}

		background-color: $dark-colors_midnight-blue;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;

		> div {
			-moz-box-sizing: border-box;
			-webkit-box-sizing: border-box;
			box-sizing: border-box;
			margin: 70px auto 0;

			> ul {

				> li {
					display: block;
					padding: 1px 12px;
					transition: padding .2s;

					@include when-left-menu-collapsed {
						padding: 3px 7px;
					}

                    &.active {
						&:hover.has-children {
							@include when-left-menu-collapsed {
								padding-right: 0;
							}
						}

                        > a { 
							cursor: default;
							
							&:not(.has-children) {
								background: #243C4D;
								color: $dark-colors_dim-white;
							}

							@include when-left-menu-collapsed {
								background: #243C4D;
							}

							&:hover.has-children {
								border-top-right-radius: 0;
								border-bottom-right-radius: 0;
							}

							> i > svg {
								fill: $dark-colors_blue_text;
								stroke: $dark-colors_blue_text;
								opacity: 1;
							}
                        }

						> nav {
							opacity: 1;
							max-height: 120px;
							margin: 0 7px 25px 7px;
						}
					}

					@include when-left-menu-collapsed {
						&:hover.has-children > a {
							border-top-right-radius: 0;
							border-bottom-right-radius: 0;
						}								
					}

                    &:hover:not(.active) {
						&.has-children {
							padding-right: 0;
						}

                        > a { 
							color: $dark-colors_dim-white;

							> i > svg {
								fill: $dark-colors_blue_text;
								stroke: $dark-colors_blue_text;
								opacity: 1;
							}
                        }
					}

					&.orders > a > i > svg {
						transform: scale(.86) translateY(0.5px);
					}

					> a {
						display: block;
						color: darken($dark-colors_dim-white, 20);
						padding: 11px 12px;
						cursor: pointer;
                        font-weight: 600;
						-moz-user-select: none;
						-ms-user-select: none;
						-webkit-user-select: none;
						user-select: none;
						vertical-align: middle;
						transition: color .05s, padding .2s, background .07s;
						border-radius: 9px;

						@at-root body[loading-state]>#main-nav>div>ul>li>a:focus {
							background: #243C4D88;
						}

						> i {
							display: inline-flex;
							width: 31px;
							height: 33px;
							align-items: center;

							> svg {
								fill: #6892AA;
								stroke: #6892AA;
								opacity: .75;
								transform: scale(.86);
								transition: fill .07s, stroke .07s;
							}
						}

                        @include when-left-menu-collapsed() {
                            padding: 10px 1px 8px 8px;
                        }

						> span {
							margin-top: 3px;
							margin-left: 15px;
							font-size: 15px;
							font-family: $muli;
							opacity: 1;
							transition: opacity .2s .01s;
							position: absolute;
							left: unset;
							line-height: 25px;

							@include when-left-menu-collapsed() {
								left: -300px;
								opacity: 0;
								transition: opacity 0s;
							}
						}

						&:before {
							color: #6F7F7F;
							transition: color .05s;
						}
					}

					@include when-left-menu-collapsed {
						> nav {
							position: absolute;
							visibility: hidden;
							transition: opacity .16s, visibility 0s .16s, left 0s .16s;
							opacity: 0;
							max-height: none;
							padding: 18px 0 20px;
							left: -500px;
							background-color: #2C4456;
							border: none;
							margin: 0 0 20px 48px;
							margin-top: -53px;
							width: 220px;
							box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.16);
							border-radius: 0 3px 3px 0;
							overflow: visible;

							@include callout-arrow(left-top, 9px, #2C4456, none);

							&:before {
								content: none;
							}

							&:after {
								left: -18px;
								top: 18px;
							}

							> ul {
								> li {
									padding: 6px 6px 7px 25px;

									&.selected {
										background: none;
										color: lighten($dark-colors_dim-white, 20);
									}
								}
							}
						}

						&:hover > nav:not(.dismissed) {
							visibility: visible;
							transition: opacity .16s;
							opacity: 1;
							left: 14px;
						}
					}

					> nav {
						box-sizing: border-box;
						font-size: 14px;
						overflow: hidden;
						opacity: 0;
						max-height: 0;
						transition: max-height .1s, opacity .2s .1s;

						will-change: max-height;

						> ul {
							> li {
                                cursor: pointer;
								position: relative;
								padding: 7px 6px 9px 51px;
								border-radius: 9px;
								color: darken($dark-colors_dim-white, 20);
								transition: background-color .1s;

								&:hover {
									color: $dark-colors_dim-white;
								}

								&.selected {
									color: lighten($dark-colors_dim-white, 10);
									background: #243C4D;
								}
							}
						}
					}
				}
			}
		}

		> #toggle-left-menu {
			position: absolute;
			right: -25px;
			top: 50%;
			margin-top: -60px;
			width: 51px;
			height: 51px;
			background-color: #0e292b90;
			display: block;
			opacity: 0;
			border-radius: 100%;
			line-height: 51px;
			text-align: center;
			@include mdi-icon(chevron-left, 30px, 0, 1px, $color: #b5c1c1);
			font-size: 30px;
			transition: background-color .2s, opacity .2s, visibility 0s .2s;
			text-shadow: 0 0 7px #000, 0 0 2px #000, 0 0 2px #000;
			visibility: hidden;

			@include when-left-menu-collapsed() {
				@include mdi-icon(chevron-right, 30px, 0, $color: #b5c1c1);
				&:before {
					margin-left: 3px;
					vertical-align: 1px;
				}
			}

			&:hover {
				background-color: #15252f;
			}
		}

		&:hover > #toggle-left-menu {
			opacity: 1;
			transition: background-color .2s, opacity .3s .15s, visibility 0s;
			visibility: visible;
		}

		> #userlikeCustomTab {
			position: absolute;
			width: 70px;
			height: 70px;
			bottom: 50px;
			left: 96px;
			transition: left .3s, bottom .3s, background-color .1s;
			background-color: #1C464F;
			border-radius: 70px;
			cursor: pointer;

			&:before {
				content: '';
				background-image: url(/images/icons/help-chat.svg);
				background-size: 32px;
				display: block;
				background-repeat: no-repeat;
				width: 100%;
				height: 100%;
				background-position: center;
				opacity: .85;
			}

			&:hover {
				background-color: #135359;

				&:before {
					opacity: 1;
				}
			}
		
			@include when-left-menu-collapsed() {
				left: 8px;
				width: 46px;
				height: 46px;
				bottom: 8px;

				&:before { 
					background-size: 27px;
				}
			}
		}
	}

	> #page-load-progress {
		opacity: 0;
		display: none;
		position: fixed;
		top: $top-header-height + 1;
		height: 3px;
		left: $left-menu-width;
		right: 0;

		@include when-left-menu-collapsed {
			left: $collapsed-left-menu-width;
		}

		@include linear-gradient(90deg, #f5f5f5 0, #55aee2 89%, #d8eaf1 96%, #f5f5f5 100%);

		background-size: 100vw;
		background-repeat: no-repeat;
		background-position-x: -97vw;
		will-change: transform;
	}

	> .root-view {
		&:not(.ng-animate) {
			transition: margin-left .2s;
			will-change: margin-left;
		}

        margin-left: $left-menu-width;
        margin-top: $top-header-height;

		@include when-left-menu-collapsed() {
			margin-left: $collapsed-left-menu-width;
		}

		.page-content {
			margin: 0 auto;
			padding: 60px 85px 50px;
			transition: opacity 0s;

			.breadcrumb + h1 {
				transition: margin-top .2s;

				@include when-page-atmost(1300px) { // Godtycklig minimumbredd för både breadcrumb och h1 att rymmas
					margin-top: 25px;
				}

				@include when-page-atmost(1050px) { // Godtycklig minimumbredd för både breadcrumb och h1 att rymmas
					margin-top: 40px;
				}
			}
		}
	}
}

#UserMenu .utility-nav-menu.popup-menu,
#SettingsMenu .utility-nav-menu.popup-menu {
	width: 220px;
	padding: 9px 7px;

	> ol:not(:first-child) {
		padding-top: 7px;
	}

	> ol:not(:last-child) {
		margin-bottom: 7px;
	}

	> ol > li > a {
		padding: 10px 0px 11px 11px;
	}
}

#SettingsMenu {
	.utility-nav-menu.popup-menu {
		width: 270px;
	}

	.utility-nav-menu.popup-menu > ol > li > a {
		display: grid;
		grid-template-columns: 24px 1fr;
		align-items: center;
		column-gap: 14px;

		padding: 0px 0 1px 11px;
		box-sizing: border-box;
		height: 39px;

		> svg {
			grid-column: 1;
			justify-self: center;
			color: #7c7c7c;
		}

		&:hover > svg {
			color: $bright-colors_black-text;
		}

		> span > {
			grid-column: 2;
		}
	}
}