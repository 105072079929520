﻿@mixin callout-arrow($corner: top-left, $arrow-size: 7px, $color: white, $border-color: rgba(7, 7, 7, 0.05)) {
	$border-arrow-size: $arrow-size + 1;

	&::before {
		content: ' ';
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 0 $arrow-size $arrow-size $arrow-size;
		border-color: transparent transparent $color transparent;
		position: absolute;
		z-index: 1;

		@if	$corner == top-left {
			top: -$arrow-size;
			left: 15px;
			right: auto;
		}
		@if	$corner == top-right {
			top: -$arrow-size;
			right: 15px;
			left: auto;
		}
	}

	@if $corner == top-left and $border-color != none {
		&::after {
			content: ' ';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 $border-arrow-size $border-arrow-size $border-arrow-size;
			border-color: transparent transparent $border-color transparent;
			position: absolute;
			top: -$border-arrow-size;
			left: 14px;
			bottom: -$arrow-size;
		}
	}

	@if	$corner == bottom-left {
		&::before {
			content: ' ';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 $arrow-size $arrow-size $arrow-size;
			border-color: transparent transparent $border-color transparent;
			position: absolute;
			left: 15px;
			bottom: -$arrow-size - 2px;
			top: auto;
			z-index: 1;
			transform: rotate(180deg);
		}

		&::after {
			content: ' ';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 $border-arrow-size $border-arrow-size $border-arrow-size;
			border-color: transparent transparent $color transparent;
			position: absolute;
			bottom: -$border-arrow-size;
			left: 14px;
			top: auto;
			z-index: 2;
			transform: rotate(180deg);
		}
	}

	@if	$corner == bottom-right {
		&::before {
			content: ' ';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 $arrow-size $arrow-size $arrow-size;
			border-color: transparent transparent $border-color transparent;
			position: absolute;
			right: 15px;
			left: auto;
			bottom: -$arrow-size - 2px;
			top: auto;
			z-index: 1;
			transform: rotate(180deg);
		}

		&::after {
			content: ' ';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 $border-arrow-size $border-arrow-size $border-arrow-size;
			border-color: transparent transparent $color transparent;
			position: absolute;
			bottom: -$border-arrow-size;
			right: 14px;
			left: auto;
			top: auto;
			z-index: 2;
			transform: rotate(180deg);
		}
	}

	@if	$corner == right-top {
		&::before {
			content: ' ';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 $arrow-size $arrow-size $arrow-size;
			border-color: transparent transparent rgba(7, 7, 7, 0.05) transparent;
			position: absolute;
			left: auto;
			right: -20px;
			top: 15px;
			transform: rotate(90deg);
		}

		&::after {
			content: ' ';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 $arrow-size $arrow-size $arrow-size;
			border-color: transparent transparent $color transparent;
			position: absolute;
			right: -19px;
			left: auto;
			top: 14px;
			transform: rotate(90deg);
		}
	}

	@if	$corner == left-top {
		&::before {
			content: ' ';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: $arrow-size;
			border-color: rgba(7, 7, 7, 0.05) transparent transparent transparent;
			position: absolute;
			right: auto;
			left: -26px;
			top: 17px;
			transform: rotate(90deg);
		}

		&::after {
			content: ' ';
			width: 0;
			height: 0;
			border-style: solid;
			border-width: $arrow-size;
			border-color: $color transparent transparent transparent;
			position: absolute;
			left: -26px;
			right: auto;
			top: 14px;
			z-index: 1;
			transform: rotate(90deg);
		}
	}
}