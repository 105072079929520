@import "../variables";
@import "../font-awesome";
@import "../mixins/material-deisgn-icons";
@import "../mixins/center-content";
@import "../mixins/webkitscrollbar";
@import "../mixins/animations";
@import "../mixins/responsive";
@import "../mixins/callout-arrow";
@import "../mixins/multiline-ellipsis";
@import "../mixins/icons";
@import "../mixins/ng-animate";
@import "../mixins/_button-mixins.scss";

.order-details {
	display: flex;
	margin-top: 70px;

	article {
		flex-basis: 740px;
		flex-grow: 1;
		flex-shrink: 3;

		> section:first-child {
			margin-bottom: 35px;
		}

		> .customers-note {
			margin-bottom: 60px;

			> h4 {
				margin-bottom: 10px;
				font-weight: 600;
				font-family: raleway;
				color: lighten($bright-colors_dark-gray-text, 20%);

				@include mdi-icon(format-quote-close, 41px, 3px, $vertical-align: -13px, $color: lighten($bright-colors_dark-gray-text, 30%));
			}

			> .quote {
				margin-top: -18px;
				padding-left: 42px;
			}
		}

		> p {
			text-align: center;
			width: 500px;
			margin: 30px auto;
		}

		> .button-row {
			margin: 50px auto 0;

			> button {
				margin-right: 10px;

				&:first-of-type:not(.no-contrast) {
					@include contrast;
				}
			}
		}

		> .items-wrapper {
			margin: 50px 0;
			position: relative;

			.items {
				background: #fafafa;
				padding: 30px;
				transition: width .2s, box-shadow .2s;
				box-sizing: border-box;
				width: 100%;
				position: absolute;
				margin-bottom: 40px;

				> .table {
					margin-bottom: 0;

					> thead > tr > th:first-child {
						padding-top: 12px; // så att tabellen inte hoppar när man expanderar
					}

					.quantity-shipped-wrapper {
						background: #ececec;

						&.stocked {
							padding: 1px 8px 3px;
							border-radius: 20px;
						}

						&:not(.stocked) {
							padding: 4px 4px 1px;
							border-radius: 4px;
							position: relative;
							top: 1px;
							
							@media (min-resolution: 1.2dppx) {
								top: 2px;
							}
							
							> img {
								width: 17px;
								opacity: .6;
								margin-bottom: -1px;
								
								@media (min-resolution: 1.2dppx) {
									margin-top: -1px;
								}
								
							}

							&.contrast > img {
								opacity: .85;
							}
						}
						
						&.contrast {
							background: #d7e6ef;
						}
					}
				}

				&.expanded {
					transition: width .2s, box-shadow .2s;
					width: 1150px;
					z-index: 2;
					box-shadow: 0 0 40px rgba(black, 0.04), 0 0 11px rgba(black, 0.03);

					> .expand {
						@include mdi-icon(arrow-collapse, 18px, $location: 'after', $vertical-align: -2px);
					}

					> .table {
						margin-bottom: 7px;
						transition: margin-bottom .2s;

						> thead > tr > th:last-child {
							padding: 12px 15px 8px 15px;
							border-radius: 10px 10px 0 0;
						}

						> tfoot > tr:last-child > td:last-child {
							border-radius: 0 0 10px 10px;
						}

						.shipping-row {
							> :nth-last-child(1), > :nth-last-child(2), > :nth-last-child(3) {
								border-bottom: solid 1px var(--border-color);
								padding-bottom: 14px;
							}
						}

						.total-amount-row {
							> :nth-last-child(1), > :nth-last-child(2), > :nth-last-child(3) {
								padding-top: 11px;
							}
						}
					}

					> .table tr > :last-child {
						background-color: #F0F4F6;
						padding-left: 15px;
						padding-right: 15px;
						text-align: right;
						border-bottom-color: #d6dde0 !important;
						background-clip: padding-box;
					}
				}

				> .expand {
					float: right;
					@include mdi-icon(arrow-expand, 18px, $location: 'after', $vertical-align: -2px);
				}

				> h2 {
					margin-bottom: 30px;
				}

				.right-aligned {
					width: 80px;
				}

				th, td {
					&:not(:nth-of-type(2)) {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}

				.total-amount-row {
					> th, > td:not(:last-child) {
						color: $bright-colors_black-text;
						font-weight: 700;
					}
				}

				.total-amount {
					font-size: 18px;
					letter-spacing: -.03em;
					font-weight: 700;
				}
			}
		}
	}

	> aside {
		flex-basis: 350px;
		flex-grow: 1;
		flex-shrink: 1;
		margin-left: 85px;
		transition: opacity .2s;

		&.cart-expanded {
			opacity: .3;
			pointer-events: none;
		}

		> .patch-info {
			margin-left: -5px;

			.write {
				padding: 15px 29px 14px 26px;
				margin-bottom: 40px;
				color: #557066;
				border-radius: 18px;
				background: #e1efeb;

				&:hover {
					background: darken(#e1efeb, 4%);
					color: darken(#557066, 4%);
				}
	
				> svg {
					vertical-align: -5px;
					margin-right: 6px;
					color: #779c8f;
				}
			}
		}

		.event-log > li {
			&.Received { @include mdi-icon(inbox-arrow-down, 20px); }
			&.WarehouseChanged { @include mdi-icon(pencil, 20px); }
			&.OrderLineChanged { @include mdi-icon(pencil, 20px); }
			&.Unquarantined { @include mdi-icon(thumb-up, 20px); @include contrastedEventLogEntry; }
			&.MessageSent { @include mdi-icon(send, 20px); @include contrastedEventLogEntry; }
			&.MessageReceived { @include mdi-icon(email, 20px); @include contrastedEventLogEntry; }
			&.PickingListPrinted { @include mdi-icon(printer, 20px); }
			&.ReadyToShip { @include mdi-icon(package-variant-closed, 22px); }
			&.Unknown { @include mdi-icon(help, 20px); }
			&.ShipmentBooked { @include mdi-icon(truck-check, 24px); @include contrastedEventLogEntry; }
			
			&.Shipped { @include mdi-icon(cube-send, 24px); @include contrastedEventLogEntry; }
			&.ExcplicitlyMarkedAsFulfilled { @include mdi-icon(check, 22px); @include contrastedEventLogEntry; }
			
			&.Cancelled { @include mdi-icon(close, 21px); @include contrastedEventLogEntry; }
			
			&.BookShipmentFailed { @include mdi-icon(truck, 22px); @include errorEventLogEntry; }
			&.ItemsReturned { @include mdi-icon(undo-variant, 22px); @include warningEventLogEntry; }
			
			&.PaymentCaptured { @include mdi-icon(credit-card, 22px); @include contrastedEventLogEntry; }
			&.PaymentCapturedFailed { @include mdi-icon(sync-alert, 22px); @include errorEventLogEntry; }

			&.PaymentRefunded { @include mdi-icon(credit-card, 22px); @include contrastedEventLogEntry; }
			&.RefundFailed { @include mdi-icon(sync-alert, 22px); @include errorEventLogEntry; }

			&.PaymentCancelled { @include mdi-icon(credit-card, 22px); @include contrastedEventLogEntry; }
			&.CancelPaymentFailed { @include mdi-icon(sync-alert, 22px); @include errorEventLogEntry; }
			
			&.ReleasePaymentReservationFailed { @include mdi-icon(sync-alert, 22px); @include errorEventLogEntry; }

			&.Quarantined:before { 
				@include hand-gray;
				content: '';
				background-size: 16px;
				background-position: 9px 9px;
			}
		}
	}
}

#deny-order-popup-backdrop, #remove-order-popup-backdrop {
	@include center-content($position: fixed);

	> div {
		width: 800px;
		background-color: white;
		box-shadow: 0px 2px 15px rgba(0,0,0,0.1), 0px 1px 4px rgba(0,0,0,0.06), 0px 0px 25px rgba(0,0,0,0.04);
		overflow: hidden;
			
		> div {
			padding: 40px;

			&:first-of-type {
				padding-bottom: 30px;
			}

			&:last-of-type {
				padding-top: 0;
				transition: background-color .2s, padding-top .2s;

				&.expanded {
					background: #F8FBFA;
					padding-top: 30px;

					.email-editor > .part:last-child {
						flex-basis: 230px;
					}
				}
			}

			> h2 {
				font-weight: 600;
				margin-bottom: 30px;
			}

			> .message-to-customer {
				padding-right: 13px;
				transition: height .2s, padding .2s, margin .2s;
				height: 0;
				overflow-y: hidden;
				@include webkit-scrollbar($track-color: rgba(black, .12), $thumb-color: rgba(black, .22));
			}

			> .button-row {
				margin-top: 30px;
			}
		}
	}
}

#change-warehouse-popup-backdrop {
	@include center-content($position: fixed);

	> div {
		width: 744px;
		background-color: white;
		box-shadow: 0px 2px 15px rgba(0,0,0,0.1), 0px 1px 4px rgba(0,0,0,0.06), 0px 0px 25px rgba(0,0,0,0.04);
		overflow: hidden;
		padding: 40px;

		> h2 {
			font-weight: 600;
			margin-bottom: 30px;
		}

		> table tbody tr > td:last-child {
			@include mdi-icon(more, 18px, 0, 0, $bright-colors_vibrant-blue, $location: after);

			&:after {
				float: right;
			}
		}

		@at-root .warehouse-inventory-tooltip {
			border: solid 10px #4d585e;

			> thead > tr > th {
				color: $dark-colors_dim-white;
			}
		}

		> .button-row {
			margin-top: 40px;
		}
	}
}
