﻿@import "../mixins/background-blob";
@import "../mixins/responsive";
@import "../mixins/scroll-based";
@import "../variables";
@import "../mixins/material-deisgn-icons";

#items-items-home-view .page-content {
	padding: 0;
	$max-content-width: 1300px;

    #empty {
		display: flex;
		flex-direction: column;
		align-items: center;
		row-gap: clamp(22px,78vh - 620px,54px);
		padding-bottom: 40px; // så att innehållet aldrig slutar precis vid kanten av en liten webbläsare

		> main {
			width: 470px;
			aspect-ratio: 1;
			position: relative;
			margin-top: -80px;

			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			padding: 40px;
			box-sizing: border-box;

			text-align: center;

			@include background-blob(#F1F1F1, #{1 / .95});

			> h2 {
				font-size: 28px;
				margin-bottom: 32px;
				margin-top: 1.3em;
			}

			p {
				&:not(:last-of-type) {
					margin-bottom: 21px;
				}
				max-width: 40ch;
				color: $bright-colors_dark-gray-text;
			}

			a {
				font-weight: bold;
				&.arrow {
					@include mdi-icon(arrow-right, 18px, 5px, $location: 'after', $vertical-align: -2px);
				}
			}
		}

		> .actions {
			display: flex;
			height: 150px;
			column-gap: 40px;

			> i {
				display: block;
				width: 0px;
				height: 100%;
				border-left: solid 1px #00000030;
			}

			> div {
				flex: 1;
				display: grid;
				column-gap: 24px;
				align-items: center;
				row-gap: 6px;
				padding: 0 20px;
				cursor: pointer;

				&:hover {
					> i:before {
						background-color: #d6e4ec;
					}

					> p {
						color: darken($bright-colors_blue-text-on-whitesmoke, 20%);
					}
				}

				> i {
					grid-row: 1 / 3;
					grid-column: 1;
					display: flex;
					position: relative;
					width: 80px;
					height: 80px;
					@include background-blob(#DFEAF0);
					align-items: center;
					justify-content: center;
				}

				> h3 {
					font: 600 15px $muli;
					grid-column: 2;
					grid-row: 1;
					margin: 0;
					align-self: end;
					max-width: 180px;
				}

				> p {
					grid-column: 2;
					grid-row: 2;
					color: $bright-colors_blue-text-on-whitesmoke;
					margin: 0;
					line-height: 18px;
					align-self: start;
					max-width: 180px;
				}
			}
		}
    }

	#populated {
		$page-content-margin: 85px;

		> header {
			background-color: #F1F1F1;
			height: 431px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: start;
			box-sizing: border-box;
			min-width: 934px;
			padding: 58px 85px 50px;

			position: relative;
			
			> h1 {
				margin: 0;
				position: sticky;
				top: 108px;

				opacity: scrollBasedCutOut(287, 100);
			}

			&:before {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background: linear-gradient(180deg, #EEF2F4 73%, #ECF1F3 94%, #EAEEF1 100%);
				opacity: scrollBasedCutOut(100, 166);
			}

			> .teasers {
				z-index: 1;
				display: flex;
				margin-top: 80px;
				height: 190px;
				column-gap: 40px;
				align-items: center;

				opacity: scrollBasedCutOut(60, 100);
	
				> i {
					display: block;
					box-sizing: border-box;
					width: 1px;
					height: 100%;
					border-left: solid 1px #00000030;
				}
	
				> a {
					box-sizing: border-box;
					max-width: 406px;
					flex: 1;
					display: flex;
					align-items: center;
					padding: 0 20px;
					cursor: pointer;

					@include when-page-atleast(1060px) {
						&.analyze-item-groups {
							padding-left: 50px;
						}
					}

					> svg {
						display: flex;
						position: relative;
						width: 95px;
						height: 95px;
						align-items: center;
						justify-content: center;
						flex-shrink: 0;
						color: #C5DBE8;
						margin-bottom: 25px;
						margin-right: 22px;
					}

					> div {
						margin-top: 10px;

						> h3 {
							font-weight: 600;
							line-height: 20px;

							> span {
								font: 600 14px/18px $muli;
								color: $bright-colors_dark-gray-text;
								display: block;
							}
						}

						> p {
							color: $bright-colors_blue-text-on-whitesmoke;
							line-height: 18px;
						}
					}

					&:hover {
						> svg {
							color: darken(#C5DBE8, 10%);
						}

						> div > p {
							color: darken($bright-colors_blue-text-on-whitesmoke, 20%);
						}
					}
				}
			}

			&.collapsed {
				> .teasers {
					pointer-events: none;
				}
			}
		}

		.item-groups {
			padding: 0 $page-content-margin;
			max-width: $max-content-width;
			margin-top: 30px;
			min-height: calc(100vh - 298px);

			> h2#rootgroups {
				display: flex;
				justify-content: space-between;
				align-items: end;
				z-index: 1;
				padding-top: 26px;
				background: #f5f5f5;
				
				&.table-view {
					margin-bottom: 24px;
				}
	
				> toggle-button-group {
					margin-bottom: 4px;
				}
			}

			> .table {
				margin: 0 0 4em;

				th.right-aligned, td.right-aligned {
					padding-left: 30px;
				}

				inline-bar {
					width: 150px;
				}
			}

			> .links {
				display: flex;
				align-items: baseline;
				column-gap: 30px;
				margin-top: 3em;

				.tree {
					@include mdi-icon(file-tree, 22px, $vertical-align: -2px);
				}

				.import { @include mdi-icon(paperclip, 18px, 5px, $vertical-align: -2px); }
			}
		}
	}
}