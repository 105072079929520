﻿@import "../variables";

@mixin when-page-atmost($max-width) {
	@at-root html:not(.left-menu-collapsed) & {
		@media screen and (max-width: ($left-menu-width + $max-width)) {
			@content;
		}
	}
	
	@at-root html.left-menu-collapsed & {
		@media screen and (max-width: ($collapsed-left-menu-width + $max-width)) {
			@content;
		}
	}
}

@mixin when-page-atleast($min-width) {
	@at-root html:not(.left-menu-collapsed) & {
		@media screen and (min-width: ($left-menu-width + $min-width)) {
			@content;
		}
	}
	
	@at-root html.left-menu-collapsed & {
		@media screen and (min-width: ($collapsed-left-menu-width + $min-width)) {
			@content;
		}
	}
}

@mixin when-left-menu-collapsed {
	@at-root html.left-menu-collapsed & {
		@content;
	}
}