﻿@import "../variables";

@mixin contrast {
	background-color: $button-color-contrast;
	border-color: transparent;
	color: white;

	&:hover {
		background-color: darken($button-color-contrast, 10%);
		color: white;
		border-color: transparent;
	}

	&:active {
		background-color: darken($button-color-contrast, 14%);
	}

	&[disabled]:not([data-loading]) {
		background-color: #eaeaea;
		color: #d3d3d3;
	}

	&[disabled][data-loading] {
		background-color: $button-color-contrast;
		color: #d3d3d3;
	}

	&.dangerous {
		background-color: $bright-colors_vibrant-red;

		&:hover {
			background-color: darken(desaturate($bright-colors_vibrant-red, 11%), 14%);
		}

		&:active {
			background-color: darken(desaturate($bright-colors_vibrant-red, 11%), 20%);
		}
	}
}
