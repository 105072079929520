﻿@import "../variables";
@import "../mixins/material-deisgn-icons";
@import "../mixins/ng-animate";

#organizationSettings-transportationManagement-view > .page-content {
	form > h2 {
		font-size: 36px;
		font-weight: 600;
		margin: 0 0 60px;
	}
}

configure-unifaun > form {
	width: 500px;
	margin: 0 auto;

	transition: width .2s;

	> .button-row {
		margin-top: 5em;
		text-align: right;
		padding: 0;

		> button {
			margin-right: 0;
			margin-left: 14px;
		}
	}

	&.enabled {
		width: 700px;
	}

	> h2 {
		margin: 2.5em 0;
		text-align: center;
		font-weight: 500;
	}

	> .low-contrast {
		margin-top: -1em;
		margin-bottom: 3em;
		max-width: 60ch;
	}

	h3 {
		margin: 3em 0 1em;
	}

	> label:last-of-type {
		margin-bottom: 2em;
	}

	.developer-id {
		display: flex;
		margin-top: 1em;

		.form-field {
			width: 32ch;
			margin-right: 20px;

			> h4 {
				font-weight: 500;
				margin-top: 2em;
			}
		}

		> .help {
			margin-top: 51px;
			line-height: 1.4;

			&:before {
				float: left;
				margin-bottom: 20px;
			}
		}
	}

	.apikey {
		@include animate-expand(240px, .2s);

		> h3 {
			margin-top: 1em;
		}

		> div {
			background: #f1f1f1;
			padding: 30px;

			> .inputs {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				width: 490px;

				> label:first-child {
					flex: 210px;
					flex-grow: 0;
				}

				> label:last-child {
					flex: 260px;
					flex-grow: 0;
				}
			}
		}
	}

	.settings {
		.low-contrast {
			max-width: 60ch;
		}

		.table {
			margin: 2em 0 3em;

			.input {
				width: 10em;
			}
		}
	}

	.verified {
		display: inline-block;
		line-height: 42px;

		@include mdi-icon(check-circle, 27px, 8px, -5px, $bright-colors_mint-green);
		color: $bright-colors_dark-gray-text;
		font-weight: bold;
		border-radius: 8px;
	}

	.unverified {
		display: inline-block;
		line-height: 42px;

		@include mdi-icon(close-circle, 27px, 8px, -5px, $bright-colors_red);
		color: $bright-colors_dark-gray-text;
		font-weight: bold;
		border-radius: 8px;
	}
}
