﻿@import "../font-awesome";
@import "../variables";
@import "../mixins/center-content";
@import "../mixins/webkitscrollbar";
@import "../mixins/material-deisgn-icons";

#organizationSettings-saleschannels_emailsettings_template-view > .page-content {
	width: 856px;
	margin: 0 auto;

	$color-silver: #b0b0b0;
	$color-apricot: #f2a387;
	$color-skyblue: #9ec6e9;
	$color-mint: #8cdab6;
	$color-gray: #606060;
	$color-maroon: #8f5045;
	$color-darkblue: #496da4;
	$color-green: #4ab986;

	> h2 {
		text-align: center;
		margin-bottom: 5rem;
	}

	> form {
		> section > div {
			&.split {
				margin-bottom: -10px;

				> .color-themes-container {
					width: calc(65% - 15px);
				}
				
				> .form-field:not(.full-width) {
					&:nth-child(odd) {
						width: calc(35% - 15px);
					}

					&:nth-child(even) {
						width: calc(65% - 15px);
					}

					> span {
						position: relative; // Så att tip-ikonen inte hamnar utanför
					}
				}
			}

			> .low-contrast {
				margin-top: -3em;
				width: 30em;
			}
		}

		.trigger-event {
			> h4 {
				font-family: $muli;
				font-size: 14px;
				font-weight: 400;
				margin-bottom: 1.5em;
			}
		}

		.tip {
			right: 0;
		}

		.validation-errors {
			margin-top: 90px;
			margin-bottom: -20px;
		}
	}

	.color-themes-container {
		> .color-themes-picker {
			white-space: nowrap;

			> li {
				@mixin color($color) {
					box-sizing: border-box;
					border: solid 19px transparent;
					border-bottom: solid 17px $color;
					border-right: solid 23px $color;
				}
	
				display: inline-block;
				box-shadow: 0px 1px 2px #bbbbbb;
				margin: 0 11px 0 0;
				position: relative;
				border-radius: 3px;

				&:last-of-type {
					margin-right: 0;
				}

				&[class^="White"] {
					box-shadow: 0px 1px 2px #bbbbbbaa;
				}
	
				> label {
					position: absolute;
					top: -17px;
					left: -18px;
					width: 36px;
					height: 36px;
	
					> span {
						position: absolute;
						right: 5px;
						top: 1px;

						&:before {
							margin: 0;
							border-width: 4px;
						}
					}
				}
	
				&.WhiteSilver {
					background: white;
					@include color($color-silver);
				}
	
				&.WhiteApricot {
					background: white;
					@include color($color-apricot);
				}
	
				&.WhiteSkyblue {
					background: white;
					@include color($color-skyblue);
				}
	
				&.WhiteMint {
					background: white;
					@include color($color-mint);
				}
	
				&.BlackGray {
					background: #333333;
					@include color($color-gray);
				}
	
				&.BlackMaroon {
					background: #333333;
					@include color($color-maroon);
				}
	
				&.BlackDarkblue {
					background: #333333;
					@include color($color-darkblue);
				}
	
				&.BlackGreen {
					background: #333333;
					@include color($color-green);
				}
			}
		}
	}

	.preview {
		color: $bright-colors_black-text;
		@include fa-icon($fa-var-eye, $font-size: 20px, $vertical-align: -2px);
		padding: 8px;
		padding-left: 0;
		position: absolute;
		left: 0;
		top: -40px;
	}

	.edit {
		color: $bright-colors_black-text;
		@include fa-icon($fa-var-eye-slash, $font-size: 20px, $vertical-align: -2px);
		padding: 8px;
		padding-left: 0;
		position: absolute;
		left: 0;
		top: -40px;
	}

	.template-container {
		background: white;
		box-shadow: 0 0 16px #d2d2d2, 0 0 4px #00000021;
		position: relative;

		.loading {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: rgba(103, 103, 103, 0.4);
			display: flex;
			align-items: center;
			flex-direction: column;
			justify-content: center;
		}

		.email-layout {
			width: 100%;

			--accent-color: #{$color-apricot};
			--background-color: whitesmoke;
			--footer-image-background-color: white;

			&.WhiteSilver {
				--accent-color: #{$color-silver};
			}

			&.WhiteApricot {
				--accent-color: #{$color-apricot};
			}

			&.WhiteSkyblue {
				--accent-color: #{$color-skyblue};
			}

			&.WhiteMint {
				--accent-color: #{$color-mint};
			}

			&.BlackGray {
				--background-color: #333333;
				--footer-image-background-color: #393939;
				--accent-color: #{$color-gray};
			}

			&.BlackMaroon {
				--background-color: #333333;
				--footer-image-background-color: #393939;
				--accent-color: #{$color-maroon};
			}

			&.BlackDarkblue {
				--background-color: #333333;
				--footer-image-background-color: #393939;
				--accent-color: #{$color-darkblue};
			}

			&.BlackGreen {
				--background-color: #333333;
				--footer-image-background-color: #393939;
				--accent-color: #{$color-green};
			}

			background-color: var(--background-color);

			> tbody > tr > td:nth-child(1),
			> tbody > tr > td:nth-child(3){
				background: var(--background-color);
				width: 19px;
			}

			> tbody > tr > td:nth-child(2) {
				width: unset;

				> table {
					width: 100%;
				}
			}
		}
	}

	.template {
		width: 100%;
		background-color: white;
		line-height: 1.4;
		text-align: center;

		&.dark-theme {
			background-color: whitesmoke;
		}

		h1, h2, h3, h4 {
			font-family: inherit;
			color: inherit;
		}

		.strike-through {
			border-bottom: solid 1px #a5a5a5;
			position: relative;

			> span {
				position: relative;
				background: white;
				top: 7px;
				padding: 0 14px;
			}
		}

		&.dark-theme .strike-through > span {
			background-color: whitesmoke;
		}

		.header, .footer {
			color: white;
			background-color: var(--accent-color);
		}

		.header {
			padding: 14px 25px; text-align: center; font-size: 22px; word-break: break-word;
		}

		.greeting {
			padding: 40px 25px 5px; text-align: center; font-size: 17px; font-weight: 600;
		}

		.free-text {
			padding: 0px 50px; text-align: center; font-size: 16px; word-break: break-word;
			padding-bottom: 20px;
			line-height: 1.6;
		}

		.free-text > .editable {
			line-height: 1.6;
		}

		.footer-image {
			text-align: center;
			padding-bottom: 20px;

			&.enabled {
				background-color: var(--footer-image-background-color);
				padding: 10px 50px 10px;
			}

			> label {
				font-family: $muli;
				display: inline-block;
				margin-bottom: 20px;
			}

			> div {
				margin-bottom: 20px;

				> input {
					width: 100%;
					box-sizing: border-box;
				}
			}
		}

		&.dark-theme .footer-image.enabled {
			padding: 30px 50px 10px;

			> label {
				color: #b7b7b7;
			}

			> div > input[type=url] {
				background: #404040;
				border-bottom-color: #535353;
				color: #dfdfdf;
			}
		}

		.footer {
			padding: 14px 25px; text-align: center; font-size: 15px; word-break: break-word;
		}

		.track {
			text-align: center;
			padding: 20px 50px 0;

			&.enabled {
				padding: 20px 50px 20px;
			}

			> label {
				font-family: $muli;
				display: block;
				margin-bottom: 20px;
			}

			> span {
				background: var(--accent-color);
				color: white;
				padding: 19px;
				border-radius: 30px;
				display: inline-block;
			}
		}

		&.dark-theme .track {
			padding: 20px 50px 0;

			&.enabled {
				padding: 20px 50px 30px;
			}
		}

		.editable {
			margin: 8px;
			padding: 10px;
			border: dashed 1px #dadada;
			line-height: 1.4em;
			background-color: transparent;
			transition: background-color .1s;
			min-height: 19px;

			&:hover {
				background-color: rgba(black, .02);
				border-color: #d0d6da
			}

			&:focus {
				box-shadow: 0 0 5px rgba(black, .1);
				border: solid 1px #8ecdf5;
				background: white;
			}

			&.on-shade {
				border-color: rgba(black, .15);

				&:hover {
					border-color: rgba(black, .2);
					background-color: rgba(black, .03);
				}

				&:focus {
					box-shadow: 0 0 5px rgba(black, .15);
					border: solid 1px #8ecdf5;
					background-color: rgba(black, .04);
				}

				&:after {
					color: rgba(white, .5);
				}
			}

			&:after {
				color: rgba(black, .3);
			}
		}

		&.dark-theme .editable {
			border-color: rgba(black, .15);

			&:focus {
				box-shadow: 0 0 5px rgba(black, .15);
				border: solid 1px #8ecdf5;
				background-color: whitesmoke;
			}

			&.on-shade {
				border-color: rgba(white, .3);

				&:after {
					color: rgba(white, .3);
				}

				&:hover {
					border-color: rgba(white, .3);
					background-color: rgba(black, .05);
				}

				&:focus {
					box-shadow: 0 0 5px rgba(black, .15);
					border: solid 1px #8ecdf5;
					background-color: rgba(black, .08);
				}
			}
		}
	}

	#preview-frame {
		width: 100%;
		height: 560px;
		transition: opacity .1s; // Så att den inte blinkar vitt när vi sänker opaciteten
	}

	> form > .button-row {
		margin-top: 70px;
	}
}

#send-test-mail-dialog {
	@include center-content-in-main-area-when-fits(760px);

	> form {
		width: 680px;

		h2 {
			margin-bottom: 3rem;
		}

		input[name='first-name'] {
			width: 200px;
		}
	}
}