﻿.invoice-view {
	width: 900px;

	> h2 {
		font-weight: 600;
	}

	> .details {
		margin: 3em 0 0;
	}

	> .items {
		background: #fafafa;
		padding: 20px;

		> h3 {
			margin-bottom: 20px;
		}

		.summary {
			padding: 7px;
			padding-top: 14px;

			> div {
				margin-top: 4px;
				text-align: right;

				> span {
					width: 100px;
					display: inline-block;

					&.total-amount {
						font-weight: 600;
						font-size: 18px;
						letter-spacing: -.3px;
					}
				}
			}
		}
	}

	> button {
		margin-top: 3em;
	}

	confirm-button {
		float: right;
		margin-top: 3em;
	}
}