﻿@import "../mixins/callout-arrow";
@import "../variables";
@import "../mixins/webkitscrollbar";

.variation-autocomplete.suggestions {
	&.tether-element-attached-top {
		@include callout-arrow(top-left, 10px, $bright-colors_baby-blue-2, silver);
		margin-top: 12px;
	}

	&.tether-element-attached-bottom {
		@include callout-arrow(bottom-left, 10px, white, silver);
		margin-top: -12px;
	}

	background-color: $bright-colors_baby-blue-2;
	border: solid 1px rgba(black, .15);
	box-shadow: 1px 1px 7px rgba(black, .2);
	top: 100px;
	z-index: 1;

	> p {
		padding: 19px 16px 16px;
		color: #5c5c5c;
	}

	> ul {
		overflow-y: auto;
		max-height: 300px;
		@include webkit-scrollbar;

		> li {
			background-color: white;
			padding: 14px 16px 11px;
			cursor: pointer;

			&:not(:first-child) {
				border-top: solid 1px #E9E9E9;
			}

			&:hover {
				background-color: $bright-colors_dim-blue;

				> strong, > span, > .options {
					color: white;
				}
			}

			> strong {
				font-weight: 600;
				color: $bright-colors_black-text;
			}

			> span {
				color: #474747;
				font-weight: 300;
			}

			> .options {
				font-size: 13px;
				color: #9f9f9f;
				font-weight: 300;
			}
		}
	}
}