﻿@import "../variables";

.pagination {
	> li {
		display: inline-block;

		&:not(.active):not(.disabled):hover {
			background: #eaeaea;

			> a {
				color: $bright-colors_black-text;
			}
		}

		> a {
			border: solid 2px #b4b4b4;
			border-right: none;
			height: 37px;
			width: 37px;
			padding: 0;
			line-height: 32px;
			text-align: center;
			border-radius: 0;
			display: inline-block;
			box-sizing: border-box;
			color: $bright-colors_dark-gray-text;

			&:hover {
				border-color: #B4B4B4;
			}
		}

		&.disabled > a {
			cursor: default;
		}

		&.active > a {
			background-color: $bright-colors_vibrant-blue;
			border-color: $bright-colors_vibrant-blue;
			color: white;
		}

		&:last-of-type > a {
			border-radius: 0 4px 4px 0;
			border-right: solid 2px #B4B4B4;
		}

		&.active:last-of-type > a {
			border-radius: 0 4px 4px 0;
			border-right: solid 2px $bright-colors_vibrant-blue;
		}

		&:first-of-type > a {
			border-radius: 4px 0 0 4px;
		}

		&.active + li > a {
			border-left: none;
		}

		&:not(:last-of-type).active > a {
			padding-right: 1px;
		}
	}
}

.paging.on-white {
	.pagination > li > a {
		border-color: #d0d0d0;
	}

	.pagination>li.active>a {
		border-color: #84c3e6;
		background-color: #84c3e6;
	}
}

.table + .paging {
	margin-top: 40px;
	text-align: center;
}