﻿@import "../variables";
@import "../mixins/webkitscrollbar";
@import "../mixins/responsive";

receive-stock-transfer {
	> .popup-overlay {
		@include center-content-in-main-area-when-fits(800px);

		.receive-stock-transfer {
			width: 700px;

			> h2 {
				margin-bottom: 10px;
			}

			> form {
				display: grid;

				grid-template-columns: 260px 1fr;
				grid-template-rows: 1fr 42px;
				row-gap: 40px;

				> .form-field {
					grid-column: 1;
					grid-row: 1;

					display: inline-block;
					margin: 50px 0;
					margin-bottom: 50px;

					input {
						width: 200px;
					}
				}

				> aside {
					grid-column: 2;
					grid-row: 1;
					background: #{'hsl(202 40% 98% / 1)'};
					@include webkit-scrollbar();
					float: right;
					padding: 19px 26px 36px;
					border-radius: 8px;
					max-height: 300px;
					margin-top: 41px;
					overflow-y: auto;
					box-sizing: border-box;
				}

				> .button-row {
					grid-column: 1 / 3;
					grid-row: 2;
					padding: 0;

					> button {
						float: left;
					}

					> button:not(:last-of-type) {
						margin-right: 10px;
					}

					> confirm-button {
						display: block;
					}
				}
			}
		}
	}
}