﻿@import "../variables";
@import "../mixins/checkbox";

.label-checkbox {
	@include checkbox($top-offset: 11px, $left-offset: 11px);

	> span, > label {
		background-color: #f0f0f0;
		height: 41px;
		line-height: 40px;
		padding: 0 15px 0 36px;
	}

	&:hover {
		> span, > label {
			background-color: #E2E6E7;
		}
	}

	> input:checked + span, > input:checked + label {
		background-color: $bright-colors_baby-blue-1;
	}

	&.on-white {
		> span, > label {
			background-color: #f7f7f7;
		}

		&:hover {
			> span, > label {
				background-color: #f3f3f3;
			}

			> input:checked + span, > input:checked + label {
				background-color: darken(#e8f3fc, 1.5%);
			}
		}

		> input:checked + span, > input:checked + label {
			background-color: #e8f3fc;
		}
	}
}