﻿
@import "../variables";
@import "../font-awesome";
@import "../mixins/ng-animate";
@import "../mixins/material-deisgn-icons";

.tree-editor {
	background-color: #f0f0f0;
	padding: 30px;
	padding-right: 0px;
	box-sizing: border-box;

	.add-root-node {
		margin-top: 30px;

		@include mdi-icon(plus-circle-outline, 20px, $vertical-align: -3px);

		&:before {
			position: relative;
			top: -1px;
		}
	}

	&.empty > .add-root-node {
		margin-top: 0;
	}
}

.angular-ui-tree-empty {
	display: none;
}

.angular-ui-tree > .angular-ui-tree-nodes > .angular-ui-tree-node > .angular-ui-tree-handle:before {
    // Toppnivå
    content: none;
}

.angular-ui-tree-drag .angular-ui-tree-handle:before {
    // Medan noden dras
    content: none !important;
}

.angular-ui-tree-placeholder {
	background: $bright-colors_baby-blue-2;
	border: 1px dashed $bright-colors_dim-blue;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	margin: 3px 30px 4px 0;
}

.angular-ui-tree-nodes {
	$node-height: 35px;
	transition: opacity .2s, visibility 0s;
	overflow: hidden; // Förhindra bågar att synas utanför

	&.collapsed {
		transition: opacity .2s, visibility .2s;
		opacity: 0;
		visibility: hidden;

		.angular-ui-tree-node {
			margin-top: -($node-height + 1px);
		}
	}

	.angular-ui-tree-node {
		transition: margin-top .2s;

		&.ng-leave {
			display: none;
		}

        &:first-child > .angular-ui-tree-handle:before {
            top: calc(50% - 18px);
            height: 17px;
			margin-top: 0;
        }

		&[collapsed=true] {
			> .angular-ui-tree-handle > .view.has-children > .expand-button {
				&::before {
					transform: rotate(-90deg) translateY(1px);
				}
			}
		}

		> .angular-ui-tree-handle {
			font-weight: 400;
			font-size: 14px;
			color: black;
			display: block;
			width: 100%;
			padding-right: 30px;
			box-sizing: border-box;
			margin-bottom: 1px;
			//overflow: hidden; Varför hade jag denna?
            position: relative;
			cursor: default;

            &:before {
                content: ' ';
                position: absolute;
                width: 9px;
                height: 1000vh;
                border-width: 0 0 1px 1px;
                border-style: solid;
                border-color: #d4d4d4;
				border-left-color: #d8d8d8;
                left: -10px;
				margin-top: calc(-1000vh + 18px);
            }

			&.selectable {
				> .view > .name {
					cursor: pointer;
				}
			}

			> .view {
				height: $node-height;
				line-height: $node-height - 1px;
				user-select: none;
				clear: both;
				//overflow: hidden; // Behövs denna?
				background-color: white;
				border-radius: 4px;
				position: relative; // Denna
				z-index: 1;			// och denna är så att bågarna ska hamna bakom
				box-shadow: 1px 2px 1px #ECECEC;
				display: flex;

				&.selected {
					background: $bright-colors_vibrant-blue;
					color: white;

					&.has-children > .expand-button:before {
						color: rgba(white, .7);
					}

					&:hover {
						background-color: darken($bright-colors_vibrant-blue, 5%);
					}

					> a:not(.delete) {
						&:before {
							color: rgba(white, .7);
						}

						&:hover:before {
							color: white;
						}
					}

					> .add-child {
						stroke: rgba(white, .6);

						&:hover {
							stroke: white;
						}
					}
				}
			
				&.has-children {
					> .expand-button {
						@include mdi-icon(chevron-down, 18px, 0, $color: #525252);
						text-align: right;
						padding-right: 2px;

						&::before {
							transition: transform .2s;
						}
					}
				}

                &:hover {
					background-color: #fbfdfe;

                    > .delete {
                        transition: right .3s .1s, width .3s .1s;
                        right: -30px;
                        width: 30px;
                    }

                    > .edit-button:before, > .add-child, .move-button:before {
                        opacity: 1;
                    }

                    > .expand-button:before {
					    color: #242424;
				    }
                }

				> .expand-button {
					box-sizing: border-box;
					width: 22px;
					display: inline-block;
					vertical-align: top;

					&:before {
						line-height: $node-height !important;
					}
				}

				> .name {
					display: inline-block;
					vertical-align: 0;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					flex: 1;
				}

				> .edit-button {
					display: inline-block;
					vertical-align: top;	
					@include mdi-icon(pencil, 18px, $color: #98c4db, $margin-right: 5px, $vertical-align: -1px);
					padding-right: 4px;
					padding-left: 4px;

					&:before {
						line-height: $node-height !important;
                        opacity: 0;
                        transition: opacity .2s;
					}
				
					&:hover::before {
						color: #689cba;
					}
				}

				> .move-button {
					@include mdi-icon(drag, 24px, 0, -4px, $color: #98c4db);
					cursor: default;
					padding-left: 9px;

					&:before {
                        opacity: 0;
                        transition: opacity .2s;
					}

					&:hover::before {
						color: #689cba;
					}
				}

				> .add-child {
					width: 19px;
					padding-right: 4px;
					margin-left: -4px;
					padding-left: 2px;
                    opacity: 0;
                    transition: opacity .2s;
					cursor: pointer;
					margin-right: 2px;

					stroke: #98c4db;

					path {
						stroke: inherit;
					}

					&:hover {
						stroke: #689cba;
					}
				}

				> .delete {
					@include mdi-icon(trash-can-outline, $font-size: 20px, $margin-right: 0, $color: #7eb2ce, $vertical-align: -2px);
                    position: absolute;
                    right: 0;
                    width: 0;
                    background: #e8e8e8;
                    border-radius: 0 10px 10px 0;
                    text-align: center;
                    overflow: hidden;
                    transition: right .2s, width .2s;

					&:hover:before {
						color: #606060;
					}
				}
			}

			> .edit {
				$editing-background-color: #f7fafc;
				$editing-border-color: #bfe2f8;
				background-color: $editing-background-color;
				box-shadow: inset 0 0 0 1px $editing-border-color;
				height: $node-height;
				border-radius: 4px;
				position: relative; // Denna
				z-index: 1;			// och denna är så att bågarna ska hamna bakom
				display: flex;

				&.flash {
					@include impromptu-animation(2s, 0, $fill-mode: both, $timing-function: ease-out) {
						0% {
							background-color: $editing-background-color;
						}
						7% {
							background-color: #c0e3fa;
						}
						30% {
							background-color: #c0e3fa;
						}
						100% {
							background-color: $editing-background-color;
						}
					}
				}

				> input {
					vertical-align: 0;	
					height: $node-height - 1px;
					box-sizing: border-box;
					background-color: transparent;
					border: none;
					border-left: solid 1px $editing-border-color;
					border-radius: 4px 0 0 4px;
					padding: 0;
					padding-left: 21px;
					flex: 1;
				}

				> button {
					margin: 4px;
					line-height: 16px;
					width: 30px;
					padding: 0;
					vertical-align: top;	
					font-size: 14px;
					font-weight: 400;
					height: $node-height - 8px;
					box-sizing: border-box;
					float: right;
					border-color: #0000002e;

					&.ok {
						@include mdi-icon(check, $font-size: 20px, $margin-right: 0, $color: #8d9696, $vertical-align: -3px);
						margin-right: 0;
					}

					&.cancel {
						@include mdi-icon(close, $font-size: 20px, $margin-right: 0, $color: #8d9696, $vertical-align: -3px);
					}

					&:before {
						margin: 0;
					}

					&:disabled {
						border-color: #00000012;

						&:before {
							color: #00000022;
						}
					}
				}
			}
		}
	}
}