﻿@use "sass:math";
@import "../variables";

@mixin horizontal-band($height) {
    background-color: #f5f5f5;
    height: $height;
    position: absolute;
    top: 50%;
    margin-top: -#{math.div($height, 2)};
    left: 0;
    right: 0;
}

body.signed-out {
	background: #f9f9f9;
}

.singed-out-content {
	@include horizontal-band(360px);

	&.flipped > div {
		flex-direction: row-reverse;

		> main {
			justify-content: center;
			align-items: center;
			padding: 50px;
		}
	}

	> div {
		$box-height: 434px;
		$box-width: 670px;
		background-color: white;
		height: $box-height;
		width: $box-width;
		box-shadow: 1px 1px 4px rgba(0,0,0,0.05);
		position: absolute;
		top: 50%;
		left: 50%;
		margin-top: -#{math.div($box-height, 2)};
		margin-left: -#{math.div($box-width, 2)};

		display: flex;
		flex-direction: row;

		> aside {
			width: 252px;
			background-color: #23323e;
			text-align: center;
			color: $dark-colors_dim-white;
			padding-top: 60px;
			display: flex;
			flex-direction: column;

			> .logo {
				margin: 0 auto;

				&:not(:only-child) {
					background: #1e3e48;
					width: 124px;
					height: 124px;
					border-radius: 100%;

					&:after {
						content: '';
						background-image: url('../../images/logo/green_medium.svg');
						display: inline-block;
						width: 195px;
						background-size: cover;
						height: 230px;
						transform: scale(.32);
						transform-origin: 46px 41px;
					}
				}

				&:only-child {
					margin-top: 120px;

					&:after {
						content: '';
						background-image: url('../../images/logo/green_thin.svg');
						display: inline-block;
						width: 195px;
						background-size: cover;
						height: 230px;
						transform: scale(.5);
						transform-origin: 98px -45px;
					}
				}
			}

			> .content {
				text-align: left;
				padding: 45px;
				flex: 1;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				padding-bottom: 90px;

				> h2 {
					color: $dark-colors_dim-white;
					font-size: 17px;
					margin-bottom: 10px;
					font-weight: 500;
				}

				> p {
					color: $dark-colors_dim-white;
					font-family: $raleway;
					font-size: 13px;
					margin: 0 0 25px;
				}

				> .button {
					display: block;
					text-align: center;
					color: $dark-colors_dim-white;
					font-weight: 400;

					&:not(:hover) {
						border-color: rgba(white, .4);
					}
				}
			}
		}

		> main {
			flex: 1;
			display: flex;
			flex-direction: column;

			&.generic-message {
				> p {
					font-weight: bold;
				}

				> a {
					margin-top: 30px;
				}
			}

			> .message {
				height: 54px;
				line-height: 53px;
				border-top: solid 4px transparent;
				padding: 0 45px;
				margin-bottom: 40px;

				&.invalid {
					border-top-color: #f67d68;
					background-color: #fff5f3;
					color: #d15039;
				}
			}

			> form {
				flex: 1;
				padding: 0 45px;

				> h2 {
					font-size: 19px;
					margin-bottom: 40px;
				}

				input {
					@include input-on-white;
				}

				button {
					margin-top: 35px;
				}

				.reset-password {
					margin-top: 30px;
				}
			}

			> .disclaimer {
				position: absolute;
				top: 100%;
				margin-top: 25px;
				padding: 0 45px;
				line-height: 1.4;
				color: #989898;
			}
		}
	}
}