@import "../variables";
@import "../mixins/material-deisgn-icons";
@import "../mixins/animations";

#items-stock-warehouse-overview-view .page-content {
	max-width: 1015px;

	h1 {
		margin-bottom: 70px;
	}

	.details {
		width: 500px;
		margin-bottom: -30px;
	}

	.buttons {
		display: flex;
		justify-content: flex-end;
		column-gap: 2px;
		position: relative; // Så att de hamnar z-mässigt framför details

		> button {
			margin-left: 10px;

			&.settings {
				@include mdi-icon(settings, 20px, 0, -2px);
				width: 42px;
				padding: 10px 0 8px 8px;
			}

			&.stock-transfer-button {
				position: relative;

				> i {
					@include animate-bounce(.6s, 1, 1.5, .1s, .4s);

					color: white;
					background: #fc5f56;
					display: inline-block;
					width: 20px;
					height: 20px;
					border-radius: 20px;
					line-height: 21px;
					text-align: center;
					position: absolute;
					top: -8px;
					right: 4px;
					font-family: $muli;
					font-size: 11px;
				}
			}
		}
	}

	section > div {
		margin-top: 0;
	}

	#table-headline {
		margin-bottom: 12px;
		overflow: hidden;
		position: relative;

		h2 {
			float: left;
		}

		.filter {
			float: right;

			> .search-box {
				> input {
					padding-right: 30px;
					transition: width .3s;
					width: 70px;

					&.ng-not-empty, &:focus {
						width: 150px;
					}
				}

				@include mdi-icon(magnify, 18px, 0, middle, #606060);

				&:before {
					position: absolute;
					right: 8px;
					top: 10px;
				}
			}
		}
	}

	thead > tr > th {
		&:nth-child(1) { width: 120px; }
	}

	td.right-aligned {
		width: 110px;
	}
}