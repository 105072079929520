﻿
@import "../mixins/material-deisgn-icons";
@import "../variables";

#items-purchase-outbox-view .page-content {
	//position: relative; - behövdes denna? breadcrumb funkar inte med den.

	> a {
		position: absolute;
		top: 50px;

		color: gray;
		@include mdi-icon(arrow-left);

		&:hover {
			color: black;
		}
	}

	> p {
		@extend .low-contrast;
		text-align: center;
		width: 400px;
		margin: 50px auto;
		line-height: 1.3;
	}

	> table {
		@extend .clickable;

		max-width: 1000px;
		margin: 0 auto;

		> thead > tr > th:nth-child(4) {
			text-align: right;
		}

		> tbody > tr > td {
			&:nth-child(4) {
				text-align: right;
			}
		}
	}
}