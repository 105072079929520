﻿@import '../mixins/_animations.scss';
@import "../variables";
@import "../mixins/material-deisgn-icons";

#items-purchase-supplierinvoice-view .page-content {
	h1 {
		margin-bottom: 70px;
	}

	> h3 {
		font-weight: 400;
		width: 200px;
		margin: 0 auto 50px;
	}

	> form {
		max-width: 1000px;
		margin: 0 auto;

		&.wide {
			max-width: 1200px;
		}

		.expiration-date {
			margin-top: 30px;
		}

		.fields-container {
			max-width: 650px;
		}

		.bankgironummer {
			input {
				width: 175px;
			}
		}

		table {
			thead {
				> tr > th:last-child {
					width: 1px;
				}
			}

			tbody {
				tr > td.input {
					> input {
						width: 100px;
					}
				}
			}

			tfoot {
				tr > td:last-child {
					background-color: #f0f0f0;
					padding: 20px;
					padding-left: 25px;
					text-align: right;
					box-shadow: inset 0 20px 0 #f5f5f5;

					> div {
						white-space: nowrap;
					
						> input {
							width: 100px;
							box-sizing: border-box;
							margin-left: 6px;
							text-align: right;
							padding: 10px 7px 8px;
						}
					}
				}
			}
		}

		> section:last-of-type {
			margin-bottom: 0;
		}

		.button-row {
			padding: 0;
		}

		.complete-invoiced-confirmation {
			background: white;
			padding: 30px;
			box-shadow: 0 0 0 100vw rgba(black, .04), 0 0 10px rgba(black, .04);
			z-index: 10;
			position: relative;
			margin-top: 40px;
			@include animate-slide-up;

			> p {
				float: left;
				width: 32em;
				padding: 10px;
			}

			> div {
				display: flex;
				flex-direction: column;
				align-items: flex-end;

				> button {
					width: 240px;
					margin: 5px;
				}
			}
		}
	}
}