done-circle {
	@include center-content;

	> div {
		background: $bright-colors_background_green;
		border-radius: 100%;
		padding: 60px;

		width: 340px;
		height: 340px;
		text-align: center;

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		> i {
			@include mdi-icon(check, 104px, $margin-right: 0, $color: $bright-colors_mint-green);
			display: inline-block;
			margin-top: -40px;
			opacity: .75;
		}

		> h2 {
			margin-top: 26px;
			margin-bottom: 12px;
			font-size: 21px;
			line-height: 1.5;
		}

		> .content {
			max-width: 300px;

			> a {
				@include mdi-icon(arrow-right, 16px, 6px, -2px, gray, 'after');
				display: block;
				margin-top: 1em;
			}
		}
	}
}