﻿@import "../variables";

.editable {
	outline: none;

	&:focus {
		border-bottom-color: rgb(191, 226, 248);
	}

	* {
		border: none;
		padding: 0;
		margin: 0;
	}

	&:before {
		content: attr(data-label);
		font-weight: 300;
		color: silver;
		margin-right: 8px;
		float: left;
		cursor: text;
	}

	&.required.empty.ng-dirty {
		border-bottom: solid 1px $bright-colors_red;
	}

	&[data-placeholder-text]:after, &[data-embedded-placeholder-text]:after {
		color: silver;
		font-weight: 300;
		line-height: 1em;
		cursor: text;
		display: block;
	}

	&[data-embedded-placeholder-text]:not(:focus).empty:after,
	&[data-embedded-placeholder-text]:not(.changed):not(:focus):after {
		content: attr(data-embedded-placeholder-text);
		pointer-events: none;
	}

	&[data-embedded-placeholder-text]:not(.empty):not(.changed):not(:focus):after {
		margin-top: -1em;
	}

	&[data-placeholder-text]:not(:focus).empty {
		padding: 9px 10px 11px;
		min-height: 19px;

		&:after {
			content: attr(data-placeholder-text);
			line-height: inherit;
			pointer-events: none;
		}
	}

	b { font-weight: bold; }
	i { font-style: italic; }
}