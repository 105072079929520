﻿@use "sass:math";
@import "../mixins/checkbox";
@import "../mixins/material-deisgn-icons";
@import "../mixins/ng-animate";
@import "../mixins/responsive";
@import "../mixins/center-content";

#items-items-fileimport-view .page-content {
	.wizard-step-indicator {
		width: 350px;
		margin-left: auto;
		margin-right: auto;
	}

	@at-root .selectize-dropdown.single.csv-column-preview  {
		width: 450px !important;
		border-top: solid 1px #cecece;

		.option > div {
			font-size: 13px;
			opacity: .6;
			white-space: nowrap;
			-ms-text-overflow: ellipsis;
			-o-text-overflow: ellipsis;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}

	#file-upload {
		width: 580px;
		margin: 0 auto;

		> #file-selector {
			background-color: #f8f8f8;
			border: solid 1px #eaeaea;

			> .dropzone {
				border: dashed 2px #cccccc;
				background-color: #f7f7f7;
				border-radius: 15px;
				margin: 30px;
				height: 144px;
				text-align: center;
				vertical-align: middle;
				line-height: 144px;
				font-size: 15px;
				color: #5a5a5a;
				transition: height .3s .2s, line-height .3s .2s;

				&.compact {
					height: 90px;
					line-height: 90px;
				}

				&.dragover {
					background: #eff5f8;
					border-color: #adbfc7;
				}
			}

			> .browse {
				margin: 30px;
				text-align: center;

				> span {
					font-weight: 300;
					color: #606060;
					margin-right: 11px;
				}
			}
		}

		> #selected-files {
			margin-top: 50px;



			> table {
				margin-bottom: 40px;

				> tbody > tr:nth-child(even) {
					border: none;

					> td {
						padding-top: 4px;
					}
				}

				> tbody > tr:nth-child(odd) > td {
					padding: 0;
					height: 4px;
					border: none;

					> .progress-bar {
						height: 4px;
						background-color: #64b8e7;

						transition: width .1s, background-color .1s;

						&.complete {
							background-color: #4fcf7f;
						}
					}
				}
			}
		}
	}

	#select-delimiter {
		width: 600px;
		background: #fafafa;
		padding: 50px;
		margin: 0 auto;

		> p {
			margin: 2em 0;
		}

		> .radio-line {
			i {
				font-weight: 300;
				color: $bright-colors_dark-gray-text;
			}
		}

		> button {
			margin-top: 40px;
		}
	}

	#column-mapping {
		> form {
			text-align: center;
			width: 1198px;
			margin: auto;

			@include when-page-atmost(1310px) {
				width: 1198px - 230px;

				> .columnset > h2 {
					float: none;
				}

				> .columnset > table {
					width: 100%;
				}

				> .columnset > div {
					margin-left: 0;
					margin-bottom: 50px;
				}
			}

			.animate-show {
				@include animate-show;
			}

			> section {
				margin: 50px 0;
				
				> div {
					width: calc(100% - 230px);

					> .checkbox {
						margin-top: 30px;
						display: inline-block;
					}
						
					> table {
						width: 100%;
						margin-top: 3em;

						> thead > tr > th {
							padding: 8px;
							min-width: 163px;
						}

						> tbody {
							background-color: #f0f0f0;
							border-left: solid 3px #f0f0f0;
							border-bottom: solid 2px #f0f0f0;
							border-right: solid 3px #f0f0f0;

							> tr:not(:first-child) {
								border-top: solid 1px $bright-colors_silver-stroke-on-smoke;
							}

							> tr:first-child {
								border-top: solid 1px #f0f0f0;
							}

							> tr:hover {
								> td:last-child {
									position: relative;

									> .remove-row {
										position: absolute;
										top: 0;
										right: -32px;
										width: 30px;
										text-align: right;
										height: 27px;
										padding-top: 17px;
										cursor: pointer;
										color: #969696;
										@include mdi-icon(close, 18px);

										&:hover {
											color: black;
										}
									}
								}
							}

							> tr > td {
								box-sizing: border-box;
								height: 34px;
								vertical-align: middle;
								padding: 8px 8px 9px;

								&:first-of-type {
									width: 180px;

									&.has-input {
										padding: 2px 0px 2px 5px;
									}

									> i {
										font-weight: 300;
										color: gray;
									}
								}

								&:last-of-type{
									max-width: 500px;
									white-space: nowrap;
									overflow: hidden;
									position: relative;

									&:after {
										content: '';
										@include linear-gradient(to left, #f0f0f0, transparent, $fallback: transparent);
										width: 50px;
										position: absolute;
										right: 0;
										top: 0;
										bottom: 0;
									}

									> .csv-column-sample {
										padding: 5px 6px 6px;
										margin: 2px;
										background: #fefee4;
										font-size: 13px;
										color: $bright-colors_dark-gray-text;
										border: dashed 1px #e0e0aa;
										line-height: 29px;
									}
								}

								&.has-input {
									width: 200px;
									padding: 5px 0px 4px 8px;

									.selectize-input {
										width: calc(100% - 24px);
									}
								}

								&:not(.has-input) {
									line-height: 1.3em;
								}
							}
						}

						th, td {
							text-align: left;
							box-sizing: border-box;

							> input {
								line-height: 34px;
								border: none;
								width: 100%;
								border-left: solid 1px #e0e0e0;
								box-sizing: border-box;
								height: 34px;
								box-shadow: 0 1px 0 #E1E1E1 !important;
							}
						}
					}
				}

				> div {
					text-align: left;

					> button {
						margin-top: 30px;

						&.add {
							@include mdi-icon(plus, 16px);
							padding-left: 15px;
						}
					}
				}

				&#vat {
					> div {
						> table {
							margin-top: 30px;
						}
					}
				}
			}
		}

		> #mapping-issues {
			@extend %popup;
			position: fixed;

			$popup-height: 621px;
			$popup-width: 587px;

			&:before {
				display: none;
			}

			&.visible {
				top: calc(50% - #{math.div($popup-height, 2)});
				left: calc(50% - #{math.div($popup-width, 2)});
			}

			> .popup-content {
				box-sizing: border-box;
				background-color: white;
				width: $popup-width;
				height: $popup-height;
				text-align: center;
				padding: 50px;

				h1 {
					font-size: 21px;
					margin-top: 0;
					margin-bottom: 18px;
					font-weight: 600;
				}

				> .is-analyzing {
					margin-top: 220px;

					> h1 {
						margin-bottom: 30px;
					}
				}

				> .analyzation-done {
					> .contents {
						height: 350px;
						padding: 30px;
						border: solid 1px #E8E8E8;
						border-radius: 20px;
						margin: 30px 0;
						display: flex;
						align-items: center;
						flex-direction: column;
						justify-content: center;

						> i {
							height: 33px;
							width: 33px;
							display: inline-block;
							@include mdi-icon(alert-circle-outline, 33px, $color: #f2a02a);
							margin-bottom: 20px;
						}

						> h3 {
							font-size: 16px;
							font-weight: 600;
							margin-bottom: 40px;
						}
					}

					> button {
						min-width: 130px;
					}
				}

				> .issue {
					> h1 {
						font-size: 21px;
					}

					> h2 {
						font-weight: 300;
						text-transform: none;
						font-size: 17px;
						text-align: left;
						margin-top: 40px;
					}

					.contents {
						height: 272px;
						padding: 30px;
						border: solid 1px #E8E8E8;
						border-radius: 20px;
						margin: 30px 0;
						display: flex;
						align-items: center;
						flex-direction: column;
					}

					.details {
						text-align: left;
						strong { font-weight: 700; }
						p { 
							line-height: 1.5em;
							width: 30em;
						}

						h3 {
							font-size: 15px;
							font-weight: 600;
							margin-bottom: 20px;
							margin-top: 20px;
						}

						ul.bad-strings {
							padding-left: 20px;
							margin-bottom: 40px;
							max-height: 120px;
							overflow: hidden;

							> li {
								display: inline-block;
								padding: 10px;
								border-radius: 7px;
								background-color: #F8F8F8;
								border: solid 1px #DDD;
								margin: 6px;
								font-weight: 400;
								font-size: 15px;
							}
						}

						label {
							display: block;
							margin: 8px 0;
							margin-left: 20px;
						}
					}

					> .buttons {
						position: absolute;
						bottom: 40px;
						left: 0;
						right: 0;
						text-align: center;

						> button {
							margin: 6px;
							min-width: 120px;
						}
					}
				}
			}
		}
	}

	#execute {
		background: #fafafa;
		padding: 40px;
		width: 440px;
		margin: 0 auto;
		text-align: center;

		ul {
			margin: 1em 8em;
			text-align: left;
			list-style: disc;

			> li:not(:last-child) {
				margin-bottom: 1em;
			}
		}

		button {
			margin-right: 10px;
		}
	}
}