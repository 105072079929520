﻿confirm-button {
	.confirm-button-container {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		height: 100%; // Så att den aldrig blir högre än föräldrern (vilket var fallet på Lev > Kontaktperson)

		.confirm-message {
			transition: opacity .2s .2s;
			flex: 0;
			overflow: hidden;
			white-space: nowrap;
			opacity: 0;
			text-align: right;
			line-height: 1.3; // Så att bokstavsfötter klipps av
		}

		.confirm-mode-buttons {
			opacity: 0;
			max-width: 0;
			overflow: hidden;
			white-space: nowrap;

			> button {
				margin-left: 10px;
				white-space: nowrap;
			}
		}

		.default-button {
			transition: flex .15s, padding .2s, border .2s, opacity ease-in .6s;
			opacity: 1;
			white-space: nowrap;

			&.icon {
				padding: 9px 0;
			}
		}

		&.in-confirm-mode {
			.confirm-message {
				flex: none;
				visibility: visible;
				opacity: 1;
			}

			.confirm-mode-buttons {
				max-width: 300px;
				transition: max-width ease-out .15s .2s, opacity .1s;
				margin-left: 16px;
				opacity: 1;
			}

			.default-button {
				opacity: 0;
				flex: 0;
				padding: 0;
				border: 0;
				overflow: hidden;
			}
		}
	}
}
