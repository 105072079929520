﻿.tooltipster-base {
	--tooltip-backcolor: #4d585e;
	--tooltip-color: whitesmoke;

	&.tooltip-dark {
		--tooltip-backcolor: #1d2a33;
		--tooltip-color: #f5f5f5a9;
	}

	> .tooltipster-box {
		border: none;
		border-radius: 20px;
		background-color: var(--tooltip-backcolor);

		&.top-right {
			border-radius: 20px 20px 14px 20px;
		}

		> .tooltipster-content {
			padding: 7px 14px 9px;
			font-size: 14px;
			font-family: $muli;
			color: var(--tooltip-color);
		}
	}

	&.tooltipster-top > .tooltipster-arrow > .tooltipster-arrow-uncropped {
		top: 1px; // Skjut ned pilen 1 px så att den blir större
	}

	&.tooltipster-bottom > .tooltipster-arrow > .tooltipster-arrow-uncropped {
		top: -11px; // Skjut ned pilen 1 px så att den blir större
	}

	&.tooltipster-top .tooltipster-arrow-background {
		border-top-color: var(--tooltip-backcolor);
	}

	&.tooltipster-bottom .tooltipster-arrow-background {
		border-bottom-color: var(--tooltip-backcolor);
	}

	&.tooltipster-left .tooltipster-arrow-background {
		border-left-color: var(--tooltip-backcolor);
	}

	&.tooltipster-right .tooltipster-arrow-background {
		border-right-color: var(--tooltip-backcolor);
	}

	> .tooltipster-arrow {
		.tooltipster-arrow-border {
			border: none;
		}

		&.tooltipster-arrow-right .tooltipster-arrow-border {
			 margin-top: -6px;
			 border-top-width: 5px !important;
			 border-bottom-width: 5px !important;

			 & + span {
				 // Vet inte om detta är ett tooltipster-element, men det dyker upp en span med klassen webinspector
				 display: none;
			 }
		}
	}
}