﻿@import "../variables";

.tiles {
	margin: -4px;
	padding: 0;
	list-style-type: none;

	&.clickable > li {
		transition: background-color .05s;
		cursor: pointer;

		&:hover {
			background: #ebeff2;
		}
	}

	> li {
		background: #f0f0f0;
		padding: 27px 32px;
		border: solid 4px #f5f5f5;
		display: inline-block;
		box-sizing: border-box;
		vertical-align: top;

		> h3 {
			font-family: $muli;
			font-size: 15px;
			font-weight: 600;
		}
	}

	&.tiles-4 > li {
		width: 25%;
	}
}