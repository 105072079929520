﻿@import "../variables";
@import "../font-awesome";
@import "../mixins/material-deisgn-icons";
@import "../mixins/center-content";
@import "../mixins/webkitscrollbar";
@import "../mixins/animations";
@import "../mixins/responsive";
@import "../mixins/callout-arrow";
@import "../mixins/multiline-ellipsis";
@import "../mixins/icons";

#sales-quarantine-view > .page-content {
	max-width: 1000px;

	.breadcrumb {
		z-index: 1;
	}

	> p {
		width: 500px;
		margin: 4em auto;
		text-align: center;
	}

	.centered-circle-container {
		> div {
			> object {
				opacity: .6;
				height: 150px;
			}
		}
	}
}

#sales-quarantinedorder-view > .page-content {
	max-width: 1300px;
	min-width: 1150px;
	
	> .order-details {
		> article {
			> .patch-alert {
				margin: 3.5em 0 1.3em;
			}

			> .button-row {
				position: relative;
				left: 33px;
			}
			> .issues {
				margin: 50px auto;
				position: relative;
				left: -17px;
				width: calc(100% + 17px);

				> li {
					margin: 30px 0;

					> i {
						float: left;
						width: 34px;
						display: inline-block;
						height: 34px;
						color: white;
						border-radius: 34px;
						line-height: 33px;
						text-align: center;
						font-family: $raleway;
						font-size: 17px;
						font-weight: 500;
						transition: background .1s;
						position: relative;

						&.highlighted {
							background: $bright-colors_red;
						}

						&:not(.highlighted) {
							background: #cecece;
							@include mdi-icon(check, 14px, 0, 0, white);

							&:before {
								position: absolute;
								top: -7px;
								left: 22px;
								box-shadow: 0 2px 0 rgba(black, .05);
								border-radius: 100%;
								width: 20px;
								height: 21px;
								background: $bright-colors_mint-green;
								line-height: 21px;
							}
						}
					}

					> div {
						background: white;

						&.is-handled {
							background: #fbfbfb;
						}

						padding: 30px 35px;
						margin-left: 50px;
						position: relative;

						> h3 {
							font-weight: 600;
						}

						.form-field {
							max-width: 50%;
							margin-bottom: 0;

							.selectize-control, .input {
								background: #fafafa;

								> .selectize-input {
									background: none;
								}
							}
						}

						.table {
							margin: 20px 0;
						}

						button {
							margin-top: 10px;
							margin-right: 10px;
						}

						.highlighted {
							font-weight: bold;
							background: $bright-colors_pink-bright;
							padding: 1px 5px 2px;
							border-radius: 3px;
						}

						.contrasted-help {
							background: $bright-colors_yellow-bright;
							border-left: solid 4px $bright-colors_yellow;
							padding: 20px;
							color: $bright-colors_dark-gray-text;
							line-height: 1.5;
							margin: 1.5em 0;
							overflow: hidden;

							// Centrera text vertikalt:
							display: flex;
							flex-flow: row-reverse;
							align-items: center;

							> button {
								margin: 0 0 0 30px;
								float: right;
								flex-shrink: 0;

								&.refresh {
									@include mdi-icon(refresh, 20px, $vertical-align: -4px);

									&:before {
										position: relative;
										top: -1px;
									}
								}
							}
						}

						.unifaun {
							p {
								margin: 1em 0;
							}

							.selectize-control {
								width: 400px;
							}

							.patch-success {
								text-align: left;
							}
						}
					}
				}
			}
		}
	}
}