﻿@import "../variables";
@import "../mixins/material-deisgn-icons";

.datepicker-backdrop {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(black, .03);
    z-index: 9998;
}

.datepicker-container {
    display: none;
    position: absolute;
    background-color: white;
    box-shadow: 2px 2px 2px rgba(black, .07), 0 0 0 1px rgba(black, 0.05);
    user-select: none;
    font-size: 14px;
    z-index: 9999;

    > .periods {
        overflow: hidden;
        padding: 20px 20px 13px 20px;
        border-bottom: solid 1px $bright-colors_silver-stroke-on-white;

        & + .range-mode {
            margin-top: 11px;
        }

        > div {
            float: left;
            clear: left;
            margin: 1px 0;
        }
    }

    > .range-mode {
        overflow: hidden;
        margin: 20px;

        > div {
            float: left;
            clear: left;
            margin: 1px 0;
        }
    }

    > .presets {
        margin: 20px;

        &:not(.visible) {
            display: none;
        }
    }

    .datepicker-navigation {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        flex-direction: row;
        padding: 20px;
        margin: 0;
		background-color: $bright-colors_baby-blue-2;

        > a {
            &.back-button {
				@include mdi-icon(chevron-left, 18px, $color: $bright-colors_dark-gray-text, $vertical-align: middle, $margin-right: 0);
				margin-right: 5px;
            }

            &.next-button {
                @include mdi-icon(chevron-right, 18px, $color: $bright-colors_dark-gray-text, $vertical-align: middle, $margin-right: 0);
				margin-left: 5px;
            }

            cursor: pointer;
            font-size: 12px;
            padding: 0 7px;
			line-height: 29px;
			border-radius: 29px;

            &:hover {
                background-color: darken($bright-colors_baby-blue-2, 5%);
            }
        }

        > .dropdowns-container {
            > select {
                width: auto !important;
                margin: 0 2px;
				border: none;
				padding: 4px 5px 5px;
				box-shadow: 0 1px 0 #dfe7ea;

            }
        }
    }

    .datepicker-inline {
        margin: 20px;
        margin-top: 3px;

        .datepicker-arrow {
            display: none;
        }

        .datepicker {
            font-family: $muli;
            border: none;

            > .datepicker--nav {
                display: none;
            }

            > .datepicker--content > .datepicker--days {
                > .datepicker--days-names {
                    > .datepicker--day-name {
                        font-size: 14px;
                        color: #585858;
                        font-weight: 600;
                        text-transform: none;
						height: 32px;
                    }
                }

                > .datepicker--cells-days {
                    > .datepicker--cell-day {
                        font-size: 14px;
                        border-radius: 0;

						&.-other-month-:not(.-selected-) {
							color: #b8b8b8;
						}

						&.-disabled- {
							background: #f3f3f3;
							color: #cccccc;
						}

                        &:not(.-disabled-).-focus- {
                            background: #dfe9ef;
                        }

                        &.-selected- {
                            background: $bright-colors_vibrant-blue;
                        }
                    }
                }
            }
        }
    }

    .timepicker-container {
        padding: 20px 20px 27px 20px;
        font-size: 14px;
        position: relative;
        text-align: center;
        background-color: #fafafa;
		border-top: solid 1px #f4f4f4;

        &:not(.is-range-mode) {
            > .time-separator, > .time-wrapper:last-child {
                display: none;
            }
        }

        &:not(.is-different-dates) > .time-wrapper > .date-label {
            display: none;
        }

        &.is-different-dates > .time-separator {
            position: relative;
            top: -10px;
        }

        > .time-wrapper {
            display: inline-block;

            > .date-label {
                font-weight: 400;
                color: #313131;
                margin: 0;
                padding: 0;
                margin-bottom: 4px;
            }
        }

        > .time-separator {
            margin: 0 20px;
        }

        input[type=number] {
			width: 36px;
			padding: 8px 10px;
			font-family: inherit;
			font-size: 14px;
			text-align: right;
			margin: 0 4px;
			height: 33px;
			box-sizing: border-box;
			
			&:not(:focus) {
				border-bottom-color: #dcdcdc;
			}
        }

        input[type=checkbox] {
            margin: 0 10px 0 0;
            top: 3px;
            position: absolute;
            left: 22px;
            top: 25px;
        }

        &.disabled {
            background: #fafafa;

            input[type=number] {
                background: #fafafa;
                color: #505050;
            }
        }
    }
}