﻿@import "../mixins/material-deisgn-icons";

#customers-index-view > .page-content {
	max-width: 1310px;
	min-width: 900px;
	margin: 0 auto;

	> #table-headline {
		margin-bottom: 12px;
		overflow: hidden;

		h2 {
			float: left;
		}

		.filter {
			float: right;

			// Så att den blir fokuserbar även när center-contents visas
			z-index: 1;
			position: relative;

			> .search-box {
				margin-left: 30px;
				position: relative;

				> input {
					padding-right: 30px;
					transition: width .3s;
					width: 70px;

					&.ng-not-empty, &:focus {
						width: 150px;
					}
				}

				@include mdi-icon(magnify, 18px, 0, middle, #606060, after);

				&:after {
					position: absolute;
					right: 8px;
					top: 2px;
				}
			}
		}
	}

	> .paging {
		text-align: center;
		margin-top: 40px;
	}

    > .empty {
        text-align: center;
        font-weight: 300;
    }
}