﻿@import "../variables";

.relative-bars {
	> h3 {
		font-family: $raleway;
		text-align: left;
		margin-bottom: 18px;
	}

	&.negative > .diagram-container  {
		> .left > .value {
			color: #e7634a;
		}

		> .right > .value {
			background: #e9664d;
		}
	}

	> .diagram-container {
		display: flex;

		> .left {
			padding-right: 12px;
			margin-top: -1px;

			> span {
				display: block;
				text-align: right;

				&.value {
					font-size: 21px;
					color: #64b5e3;
					font-weight: 500;
					padding-bottom: 2px;
					border-bottom: solid 1px #cfcdcd;
					margin-bottom: 4px;
				}

				&.compared-value {
					color: #999999;
				}
			}
		}

		> .right {
			text-align: left;
			flex: 1;

			> .value {
				height: 9px;
				background: #64b5e3;
				margin-top: 4px;
			}

			> .compared-value {
				height: 6px;
				background: #cfcece;
				margin-bottom: 6px;
			}

			> .label:last-child {
				color: #999999;
			}
		}
	}
}