﻿@import "variables";
@import "mixins/material-deisgn-icons";

%treecontrol {
	> ul {
		overflow: visible;
		background: #fafafa;
	    padding: 30px 20px 30px 0;

		li {
			padding-left: 20px;
			line-height: 27px;

			&.selected {
				> .tree-label > a {
					color: white;
					background-color: $bright-colors_vibrant-blue;
				}
			}

			.tree-leaf-head {
				float: left;
				width: 14px;
				height: 10px;
			}

			.tree-branch-head {
				color: #818181;
				float: left;
				width: 14px;
				height: 10px;
				text-align: center;
				margin-left: -3px;
				margin-right: 3px;
				position: relative;
				z-index: 2;
				top: 2px;

				@include mdi-icon(chevron-right);

				&.expanded {
					@include mdi-icon(chevron-down);
				}

				&:before {
					background: rgba(250, 250, 250, 0.78);
					border-radius: 10px;
					box-shadow: 0 0 7px 0px rgba(250, 250, 250, 0.74);
					cursor: default;
				}

				&:hover {
					color: #555;
							
					&:before {
						transform: scale(1.3);
					}
				}
			}

			&.tree-leaf {
				> .tree-branch-head {
					display: none;
				}
			}

			&.tree-collapsed, &.tree-expanded {
				> .tree-leaf-head {
					display: none;
				}
			}

			.tree-label {
				position: relative;
				display: inline;

				&:before {
					content: ' ';
					position: absolute;
					width: 8px;
					height: 30px;
					border-width: 0 0 2px 2px;
					border-style: groove;
					border-color: rgba(212, 212, 212, 0.34);
					top: -22px;
					left: -11px;
					z-index: 1;
				}

				cursor: pointer;

				> a {
					color: $bright-colors_black-text;
					display: inline-block;
					background: white;
					padding: 2px 10px 2px;
					margin: 0;
					box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.17), 1px 1px 1px rgba(0, 0, 0, 0.09);
					font-family: $muli;
					font-size: 14px;
					margin-bottom: 1px;
					position: relative;
					z-index: 2;
				}
			}
		}

		> li > .tree-label:before {
			// Rotnoder ska inte ha några connectors
			content: none;
		}
	}
}