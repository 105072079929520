﻿@import "variables";
@import "mixins/material-deisgn-icons";
@import "mixins/checkbox";
@import "mixins/radio";
@import "mixins/ng-animate";

.multi-options {
	@include animate-expand(76px, .2s);

	max-height: 76px;
	position: sticky;
	top: 51px;
	background: #ebebeb;
	padding: 17px 19px;
	margin-bottom: 30px;
	z-index: 1;

	> button {
		margin-right: 10px;
	}

	&:not(.ng-hide-remove):not(.ng-hide-add) {
		box-shadow:
			0vw 0 0 0 #ebebeb,
			0vw 0 0 0 #ebebeb,
			0vw 0 0 0 #ebebeb,
			0vw 0 0 0 #ebebeb;

		transition: box-shadow .1s;

		&.stuck {
			box-shadow:
				-20vw 0 0 0 #ebebeb,
				-40vw 0 0 0 #ebebeb,
				20vw 0 0 0 #ebebeb,
				40vw 0 0 0 #ebebeb;

			transition: box-shadow .3s;

			filter: drop-shadow(0px -1px 0px #ebebeb); // Eftersom det är en glipa på 1px på itemgroup men inte på någon annan sida. Vet inte varför, så vi "målar över" glipan med skugga bara.
		}
	}
}

.table {
	width: 100%;
	font-size: 14px;
	line-height: 1.2;

	&.checkable {
		transition: width .4s, margin-left .4s; // För när checkboxar fälls in/ut
	}

	&.loading {
		> tbody {
			transition: opacity .2s;
			opacity: .2;
		}
	}

    $cell-padding: 7px 7px 8px;
    $checkbox-check-color: #bbbbbb;
    $checkbox-fill-color: #bbbbbb;
	$checkbox-checked-fill-color: $bright-colors_vibrant-blue;

	--right-aligned-cell-padding: 15px;
	--border-color: #dfdfdf;

	&.on-white {
		--border-color: #{$bright-colors_silver-stroke-on-white};
	}

	> thead {
		> tr {
			> th {
				font-size: 14px;
				text-align: left;
				padding: $cell-padding;
				color: $bright-colors_dark-gray-text;
				vertical-align: bottom;
                border-bottom: solid 1px var(--border-color);

				&.input {
					border-bottom-color: #e4e4e4;
				}

				&.right-aligned { 
					text-align: right;

					&:not(:last-child):not(.input):not(.call-to-input) {
						// Så att texten inte hamnar så nära den gråa bakgrunden hos call-to-input
						padding-right: var(--right-aligned-cell-padding);

						+ th:not(.input):not(.call-to-input) {
							padding-left: 0;
						}
					}
				}

				&.orderby {
					border-bottom: solid 1px $bright-colors_dim-blue;
					box-shadow: inset 0 -2px 0 -1px $bright-colors_dim-blue;
					padding-bottom: 7px;

					@media (-webkit-min-device-pixel-ratio: 1.2) and (-webkit-max-device-pixel-ratio: 1.5) {
						padding-bottom: 6px;
					}

					--arrow-color: #{darken($bright-colors_vibrant-blue, 5%)};

					&:not(.desc) {
						// Ascending
						&:not(.right-aligned) { @include mdi-icon(arrow-up, 18px, 3px, -2px, var(--arrow-color), 'after'); }
						&.right-aligned { @include mdi-icon(arrow-up, 18px, 3px, -2px, var(--arrow-color)); }
					}
					&.desc {
						// Descending
						&:not(.right-aligned) { @include mdi-icon(arrow-down, 18px, 3px, -2px, var(--arrow-color), 'after'); }
						&.right-aligned { @include mdi-icon(arrow-down, 18px, 3px, -2px, var(--arrow-color)); }
					}

					&:hover {
						border-bottom-color: $bright-colors_vibrant-blue;
						box-shadow: inset 0 -2px 0 -1px $bright-colors_vibrant-blue;

						--arrow-color: #{darken($bright-colors_vibrant-blue, 15%)};
					}

					&:after {
						display: inline !important; // This prevents wrapping from causing the icon to appear on it´s own line
					}
				}

				&.sortable {
					cursor: pointer;

					&:hover:not(.orderby) {
						border-bottom: solid 1px silver;
						box-shadow: inset 0 -2px 0 -1px silver;
					}
				}

				&.call-to-input {
					background-color: #f0f0f0;
					padding: 20px 7px 8px 7px;
				}

				&:not(.call-to-input) + .call-to-input {
					padding-left: 20px;
				}

				&.call-to-input + :not(.call-to-input),
				&.call-to-input:last-child {
					padding-right: 27px;
				}

				&.input + :not(.input) {
					// Kolumnen till höger om input behöver lite extra vänsterpadding (upptäckt på Ange hyllplatser)
					padding-left: 20px;
				}

				&.no-wrap { white-space: nowrap; }
			}
		}
	}

	> tbody {
		> tr {
			&.dimmed {
				color: silver;
				input {
					color: silver;
				}
			}

            &:hover {
                > td:last-child > .delete {
                    transition: right .3s .4s, width .3s .4s;
                    right: -30px;
                    width: 30px;
                }
            }

			> td {
				padding: $cell-padding;
                border-bottom: solid 1px var(--border-color);
				vertical-align: middle;

				&.input {
					border-bottom-color: #e4e4e4;
					position: relative;

					> .revert {
						position: absolute;
						width: 3px;
						top: 0;
						left: 0;
						bottom: 0;
						background: $bright-colors_vibrant-blue-brighter;
						cursor: pointer;
						text-align: center;
						transition: width .3s;
			
						@include mdi-icon(undo, 16px, 0, -10px, white);
			
						&:before {
							transition: opacity .3s;
							opacity: 0;
						}
			
						&:hover {
							background: $bright-colors_vibrant-blue;
						}
					}
			
					&:hover {
						> .revert {
							width: 25px;
							transition: width .3s .1s;
			
							&:before {
								opacity: 1;
							}
						}
					}
				}

				&.dimmed {
					color: silver;
				}

				&.strong {
					font-weight: 700;
					line-height: 1em;
				}

				&.no-wrap { white-space: nowrap; }

				&.right-aligned { 
					text-align: right;
					> input {
						text-align: right;
					}

					&:not(:last-child):not(.input) {
						// Så att texten inte hamnar så nära den gråa bakgrunden hos call-to-input
						padding-right: var(--right-aligned-cell-padding);

						+ td:not(.input)  {
							padding-left: 0;
						}
					}
				}

				&.input + :not(.input) {
					// Kolumnen till höger om input behöver lite extra vänsterpadding (upptäckt på Ange hyllplatser)
					padding-left: 20px;
				}

				&.input {
					padding: 0;
					position: relative; // Eftersom .selectice-control nedan är pos: abs med width 100%
					vertical-align: middle; // Så att rader som blir höga pga radbrytning i andra kolumner ser bra ut
					height: 35px;

					&:first-child {
						// Ingen kantlinje för vänstra kolumnen

						> input, > .selectize-control.single {
							box-shadow: none;
						}
					}

					> input {
						margin: 0;
						border: 0;
						padding: 0 7px 1px;
						box-shadow: 0 0 0px 1px #e4e4e4;
						//width: calc(100% - 1px); // får en ful kantlinje på purchase form med denna
						width: 100%;
						box-sizing: border-box;
						line-height: unset;
						height: 100%; // Annars blir raderna låga i Firefox

						@at-root .ng-submitted &.required.not-full:not(:disabled) {
							box-shadow: 0 0 0px 1px #e4e4e4, inset 0 -1px 0px 0px $bright-colors_red;
						}

						@at-root .ng-submitted &.ng-invalid:not(:disabled) {
							box-shadow: 0 0 0px 1px #e4e4e4, inset 0 -1px 0px 0px $bright-colors_red;
						}
					}

					> .selectize-control.single {
						box-sizing: border-box;
						width: 100%; // calc(100% - 1px)
						margin: 0;
						padding: 0;
						line-height: unset;
						border: none;
						position: absolute;
						height: 100%;
						box-shadow: 0 0 0px 1px #e4e4e4;
						background-color: white;
						top: 0; // Annars hamnar de halvvägs ner i Bulk

						@at-root .table > tbody > tr > td:first-child.input > .selectize-control.single {
							box-shadow: none;
						}

						> .selectize-input {
							box-sizing: border-box;
							padding: 7px 7px 11px;
							border: none !important;
							font-size: 14px;
							background: none;
							border: solid 1px transparent;
							box-shadow: none;
							border-bottom: none;
							line-height: unset;
							height: 100%;

							&:after {
								display: none;
							}

							> * {
								// Innerhöjden i en cell är 20 px
								line-height: 20px !important;
							}

							> input {
								margin: 0 !important; // !important krävs för att skriva över selectize idiotiska regler
							}

							&.disabled {
								background: #fafafa;
								color: silver;
								mix-blend-mode: multiply; // Elementet har normalt genomskinlig bakgrund, så när vi sätter en bakgrund täcks förälderns box-shadow. Testa genom att markera/avmarkera Returnerade artiklar på vyn Registrera retur
							}
						}
					}
				}

				&:not(.call-to-input) + .call-to-input {
					padding-left: 20px;
				}

				&.call-to-input + &:not(.call-to-input),
				&.call-to-input:last-child {
					padding-right: 20px;
				}

				&.call-to-input {
					background-color: #f0f0f0;
					//padding: 0 20px;
				}

				&:last-child {
					position: relative;

					> .delete {
						@include mdi-icon(trash-can-outline, $font-size: 20px, $margin-right: 0, $color: #7eb2ce, $vertical-align: -2px);
						position: absolute;
						right: 0;
						width: 0;
						background: #e8e8e8;
						border-radius: 0 10px 10px 0;
						text-align: center;
						overflow: hidden;
						transition: right .2s, width .2s;
						height: 36px;
						line-height: 36px;
						cursor: pointer;
						top: 0;

						&:hover:before {
							color: #606060;
						}
					}
				}
			}

			&:last-child {
				td.call-to-input {
					box-shadow: 0 20px 0 rgba(0, 0, 0, 0.022);
					border-bottom-color: hsla(0, 0%, 89%, 1);
				}
			}
		}
	}

	> tfoot {
		> tr:first-child > * {
			padding-top: 25px;
		}

		tr > th {
			text-align: left;
			padding: 5px 7px;
			color: $bright-colors_dark-gray-text;

			&.right-aligned { 
				text-align: right;
				&:not(:last-child) { padding-right: var(--right-aligned-cell-padding); }
			}
		}

		tr > td {
			padding: 5px 7px;

			&.right-aligned { 
				text-align: right;
				&:not(:last-child) { padding-right: var(--right-aligned-cell-padding); }
			}
		}
	}

	&.sticky-head {
		> thead {
			background-color: #f5f5f5;
			position: sticky;
			z-index: 1;

			box-shadow:
				//inset 0 -1px 0 transparent,
				-20vw 0 0 0 transparent,
				-40vw 0 0 0 transparent,
				20vw 0 0 0 transparent,
				40vw 0 0 0 transparent;

			transition: none;

			&.stuck {
				box-shadow:
					inset 0 -1px 0 #dfdfdf,
					-20vw 0 0 0 #eeeeee,
					-40vw 0 0 0 #eeeeee,
					20vw 0 0 0 #eeeeee,
					40vw 0 0 0 #eeeeee;
				background-color: #eeeeee;

				transition: background-color .07s;

				> tr > th.orderby {
					box-shadow: inset 0 0px 0 #f5f5f5, inset 0 -2px 0 $bright-colors_dim-blue;
				}
			}

			> tr {
				> th {
					padding-top: 25px;
					padding-bottom: 9px;
					border-bottom: none !important;
					box-shadow: inset 0 -1px 0 var(--border-color);
					
					&.orderby {
						box-shadow: inset 0 0px 0 #f5f5f5, inset 0 -2px 0 $bright-colors_dim-blue;
						padding-bottom: 8px;

						@media (-webkit-min-device-pixel-ratio: 1.2) and (-webkit-max-device-pixel-ratio: 1.5) {
							padding-bottom: 7px;
						}
					}

					&.sortable:hover {
						box-shadow: inset 0 0px 0 #f5f5f5, inset 0 -2px 0 silver;
						
						&.orderby {
							box-shadow: inset 0 0px 0 #f5f5f5, inset 0 -2px 0 $bright-colors_vibrant-blue;
						}
					}
				}
			}
		}
	}

	&.clickable:not(.checked) {
		> tbody > tr {
			&:hover > td:not(.checkbox) { 
				background-color: #{'hsl(200 15% 94% / 1)'};
			}

			cursor: pointer;
		}

		&.on-white > tbody > tr:hover > td:not(.checkbox) {
			background-color: #{'hsl(195 31% 96% / 0.6)'};
		}
	}

	&.clickable.sortable:not(.checked)>tbody>tr:hover>td:not(.checkbox) {
		//mix-blend-mode: darken; // Bara så att inte första raden vid hover täcker över kantlinjen som markerar sorterad kolumn.
	}

	// Checkboxes

	&:not(.checkable) > * > tr {
		> td.checkbox, > th.checkbox {
			display: none;
		}
	}

    &.checkable {
		td.checkbox, th.checkbox {
			@include checkbox;
			padding: 0;
			width: 20px;
			cursor: default;
			vertical-align: middle;

			> label {
				display: initial;
				padding: 0;
				padding-left: 1px; // Utan denna så hamnar checkrutan i toppen av td:n i Chrome
				top: 1px; // Oklart varför jag måste lägga till denna helt plötsligt
			}
		}

		th.checkbox {
			vertical-align: bottom;
			padding-bottom: 7px;
		}

		td.radio {
			padding: 0;
			width: 20px;

			> label {
				> input[type="radio"] {
					@include radio;

					+ span:before {
						margin: 0;
					}
				}
			}
		}

		td.checkbox + td > label,
		td.radio + td > label {
			display: block; // så att hela cellen blir klickbar
		}

        &:not(.checked) {
            $checkbox-column-width: 20px;
            margin-left: -$checkbox-column-width;
            width: calc(100% + #{$checkbox-column-width});

            tr > :first-child {
                border-bottom: none;
            }

            .checkbox > label {
                visibility: hidden;
			    opacity: 0;	
                transition: visibility 0s .2s, opacity .2s;
				top: 2px; // Oklart varför jag måste lägga till denna helt plötsligt
            }

            &:hover {
                // When hovering the table

				> thead > tr > .checkbox > input:not(:disabled) + label,
                > tbody > tr > .checkbox > input:not(:disabled) + label {
			        visibility: visible;
			        opacity: .5;
	            }

				> thead > tr > .checkbox:hover > input:not(:disabled) + label,
				> tbody > tr > .checkbox:hover > input:not(:disabled) + label {
			        opacity: 1;
	            }
            }
        }

        &.checked {
            cursor: default;

			// UNCHECKED, Not disabled
            > tbody > tr:hover > td.checkbox > input:not(:checked) + label {
                &:before {
                    background: darken($checkbox-fill-color, 6%);
                }

                &:after {
                    border: solid lighten($checkbox-check-color, 5%);
				    border-width: 0 2px 2px 0;
                }
            }

			// UNCHECKED, Disabled
			> tbody > tr:hover > td.checkbox > input:disabled:not(:checked) + label {
                &:before {
                    background-color: transparent;
                }
				&:after {
                    border: none;
                }
            }
				

			// CHECKED, not disabled
            > tbody > tr:hover > td.checkbox > input:checked + label:before {
				background: darken($checkbox-checked-fill-color, 10%);
            }

			// CHECKED, disabled
            > tbody > tr > td.checkbox > input:disabled:checked + label  {
				&:before {
					background-color: transparent;
				}

				&:after {
					border-color: rgba(black, 0.2);
				}
            }

            td.checkbox > label {
			    visibility: visible;
			    opacity: 1;
			    transition: visibility 0s, opacity .3s .1s;
            }
        }
    }
}