﻿#pricelists-page-content {
	#intro {
		width: 650px;
		margin: 120px auto;

		a {
			float: right;
			margin-top: 48px;
			margin-left: 60px;
		}

		.help {
			border-right: solid 1px #cacaca;
			padding-right: 40px;
			padding-bottom: 20px;
		}
	}
}