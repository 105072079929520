@import '../mixins/material-deisgn-icons';

.billboard {
	display: flex;
    flex-direction: column;
    align-items: center;
	row-gap: 37px;
	margin-top: -40px; //ögonmåttat

	> .blob {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		aspect-ratio: 1;
		
		> i {
			position: absolute;
			border-radius: 42.5%;
			transform: rotate(15deg);
			width: 100%;
			height: 100%;

			display: flex;
			align-items: center;
			justify-content: center;

			&::before {
				opacity: .8;
				font-size: 110px;
				transform: rotate(-15deg);
			}
		}

		&.with-text {
			> i::before {
				margin-top: -36px;
			}

			> h1 {
				z-index: 1;
				font-size: 21px;
				align-self: end;
				margin-bottom: 60px;
				opacity: .9;
			}
		}

		> img {
			z-index: 1;
		}
	}

	> h1 {
		font-size: 28px;
		margin: 0;
	}

	> .paragraphs {
		margin: 23px 0;

		p {
			padding: 29px;
			width: 450px;
			border-top: solid 1px $bright-colors_silver-stroke-on-smoke;
			display: flex;
			column-gap: 26px;

			&:last-child {
				border-bottom: solid 1px $bright-colors_silver-stroke-on-smoke;
			}

			&.info {
				@include mdi-icon(information-outline, 24px, 0, 0, #999999);
			}
		}
	}
}