@import '../mixins/icons';
@import '../mixins/multiline-ellipsis';
@import '../mixins/impromptu-animation';
@import '../mixins/webkitscrollbar';

@mixin scrollbar {
	@include webkit-scrollbar(8px, $track-color: white, $thumb-color: rgba(black, .08));

	&:hover {
		@include webkit-scrollbar(8px, $track-color: rgba(black, .03), $thumb-color: rgba(black, .3));
	}
}

.email-inbox-backdrop {
	&:not(.with-navbar) {
		@include center-content-in-main-area-when-fits(1060px, 0);

		> .email-inbox.popup-appearance {
			width: 1030px;
		}
	}

	&.with-navbar {
		@include center-content-in-main-area-when-fits(1330px, 0);

		> .email-inbox.popup-appearance {
			width: 1300px;
		}
	}

	&.loading {
		> .email-inbox.popup-appearance > .throbber {
			opacity: 1;
			transition: opacity .3s, visibility 0s;
			visibility: visible;
		}

		pointer-events: none;
	}

	> .email-inbox.popup-appearance {
		height: 700px;
		max-height: calc(100vh - 90px);

		&.blank {
			width: 870px;

			> email-conversation {
				--grid-columns: 0 0 auto 183px;

				.blank-conversation-intro {
					padding: 10px 40px 30px;
				}
	
				.minimize {
					visibility: hidden;
				}
	
				.conversation-area {
					display: none;
				}

				> .compose-area.expanded.show-subject {
					flex: 1;
				}
			}
		}

		transition: height .2s ease-out;

		display: flex;
		flex-direction: row;
		
		//transition: none; // Egentligen borde .popup-appearance inte ha någon transition, eftersom den ändå fadar inte med sin backdrop, men jag orkar inte kolla att alla ställen ser bra ut om jag tar bort den. Så jag overridar här istället.

		overflow-y: hidden; // Så att scrollbars inte visas när man öppnar/minimerar composer arean

		> .throbber {
			opacity: 0;
			transition: opacity .3s, visibility 0s .3s;
			visibility: hidden;
			position: absolute;
			left: calc(50% - 44px);
			top: calc(50% - 27px);
			z-index: 5;
			background: white;
			padding: 20px 41px;
			border-radius: 50px;
			box-shadow: 0 0 0 100vmax #ffffff9e, 0 0 19px #00000054;
		}

		> .close {
			z-index: 6; // Så att den hamnar framför throbbern
			pointer-events: all;
		}

		> nav {
			width: 247px;
			padding: 39px 35px;
			border-right: solid 1px #EDEDED;
			z-index: 2; // Annars täcks bordern av den vita skugga från h1:an till höger
			overflow-y: auto;
			
			@include scrollbar;

			> h1 {
				font-size: 15px;
				text-align: left;
			}

			> p {
				color: $bright-colors_dark-gray-text;
				opacity: .66;
				line-height: 130%;
				margin-bottom: 2em;
			}

			> a {
				--background: white;
				$transition-duration: .15s;

				display: block;
				background: var(--background);
				border-radius: 1px; // Blir större med skuggan. 0px ger ingen rundning alls.
				margin: 3em 0;
				padding-left: 20px;
				position: relative;
				box-shadow: 0 0 0 10px var(--background);
				user-select: none;

				&:hover {
					--background: #{'hsl(202 60% 97%)'};
					box-shadow: 0 0 0 15px var(--background);
				}

				&.selected, &:active {
					--background: #{'hsl(202 52% 73%)'};
					box-shadow: 0 0 0 15px var(--background);

					> i {
						background-color: #d7f0ff;
					}

					> span {
						color: white;
						font-weight: 400;
					}
				}

				transition: background $transition-duration, box-shadow $transition-duration !important;

				&:not(.selected).has-unread-messages {
					@include impromptu-animation(2s) {
						0% {
							box-shadow: 0 0 0 0px var(--background);
						}
						7% { 
							--background: #ffe285FF;
							box-shadow: 0 0 0 15px var(--background);
						}
						27% { --background: #ffe285FF; }
						30% { --background: unset }
						35% { --background: unset }
						39% { --background: #ffe285FF }
						70% {
							--background: #ffe285FF;
							box-shadow: 0 0 0 15px var(--background);
						}
						100% {
							--background: #ffe285CC
							box-shadow: 0 0 0 0px var(--background);
						}
					}

					> i {
						background: $bright-colors_vibrant-blue;
					}
				}

				> i {
					background: #00000036;
					width: 9px;
					height: 9px;
					position: absolute;
					left: 0;
					border-radius: 9px;
					margin-top: 5px;
					opacity: .7;
				}

				> .subject {
					font-weight: 600;
					color: $bright-colors_dark-gray-text;
					@include multiline-ellipsis-maskbased($font-size: 14px, $line-height: 1.2, $lines-to-show: 2, $background-color: var(--background));

					&:after {
						transition: background-color $transition-duration;
					}
				}

				> .time {
					color: rgba($color: $bright-colors_dark-gray-text, $alpha: 0.62);
					margin-top: 3px;
					display: block;
				}
			}
		}

		> email-conversation {
			--grid-columns: 140px 54px auto 183px;

			display: flex;
			flex-direction: column;
			width: 1030px;
			
			> .conversation-area, > h1 {
				opacity: 1;
				transition: opacity .2s;
			}

			&.switching-conversation {
				> .conversation-area, > h1 {
					opacity: 0;
				}
			}

			> h1 {
				font-size: 20px;
				text-align: left;
				padding: 35px 40px 20px;
				margin-bottom: 0;
				box-shadow: -17px 11px 14px -4px #ffffff;
				z-index: 1;

				> span {
					@include multiline-ellipsis($font-size: 20px, $line-height: 1.2, $lines-to-show: 2, $background-color: white);
				}
			}
		
			> .conversation-area {
				flex: 1;
				overflow-y: scroll;
				position: relative;
				scroll-behavior: smooth;
				@include scrollbar;
		
				&:after {
					content: "";
					position: sticky;
					display: block;
					bottom: 0;
					height: 60px;
					background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
					z-index: 1;
					pointer-events: none;
    				margin-top: -10px;
				}

		
				> .conversation {
					$background-color: #bcbcbc;
					$accentuated-background-color: #94c9e0;
					grid-template-columns: var(--grid-columns);
					display: grid;
					row-gap: 15px;
					box-sizing: border-box;
					margin-top: 10px;
					padding: 0 32px 0 40px; // 32px är för att kompensera för scrollbaren, så att griden alignas med griden i compose-area
					$marker-top-offset: 12px; // Använd denna för att justera top-offset, så följer alla relaterade element med
					&:not(:last-child) {
						margin-bottom: 15px;
					}
				
					> .from {
						grid-column: 1;
						text-align: right;
						padding-top: $marker-top-offset;
				
						> span {
							color: #a2a2a2;
							font-size: 13px;
							display: block;
							font-weight: 300;
							word-break: break-word;
						}
				
						> p {
							margin-top: -1px;
							line-height: 1.3;
							color: #444444;
							margin-bottom: 5px;
							word-break: break-word;
						}
					}
				
					> .marker {
						grid-column: 2;
						position: relative;
				
						&:after {
							border-left: solid 2px #E6E6E6;
							content: '';
							left: 31px;
							position: absolute;
							top: -8px;
							bottom: -8px;
						}
				
						&:before {
							border-top: solid 2px #E6E6E6;
							content: '';
							left: 13px;
							position: absolute;
							right: 9px;
							top: $marker-top-offset + 9px;
						}
				
						> i {
							position: absolute;
							box-sizing: border-box;
							background: white;
							left: 26px;
							border: solid 2px #DADADA;
							width: 12px;
							height: 12px;
							border-radius: 12px;
							display: inline-block;
							z-index: 1;
							top: $marker-top-offset + 4px;
						}
					}
								
					> .message-wrapper {
						grid-column: 3;
				
						position: relative;
						margin-left: 10px;
				
						--background: #f5f5f5;
				
						&.outbound {
							--background: #EAF6F3;
						}
				
						&.is-meta-event {
							--background: #FBFBFB;
				
							> .message {
								color: #b3b3b3;
								font-weight: 300;
				
								> svg {
									vertical-align: -4px;
									margin-right: 6px;
									opacity: .85;
								}
							}
						}
				
						> .message {
							background: var(--background);
							overflow: hidden;
							padding: 11px 15px 13px;
							line-height: 1.3;
							border-radius: 4px;
				
							* {
								border: none !important;
								background-color: transparent;
								font-family: $muli !important;
								font-size: 14px !important;
							}
						}
				
						> i {
							width: 11px;
							height: 11px;
							background-color: inherit;
							position: absolute;
							z-index: 1;
							top: $marker-top-offset + 4px;
							left: -5px;
							transform: rotate(-45deg) skew(9deg, 9deg);
							background: var(--background);
						}
					}
				}
			}
		
			> .button-row {
				background: #F8FBFA;
				will-change: padding, height;
				transition: padding .2s, height .2s;
				height: 0;
		
				&.composer-minimized {
					padding: 30px 40px;
					height: 42px;
				}
		
				button.reply {
					margin: 0 auto;
					display: block;
		
					&:before {
						content: '';
						@include icon-reply();
						height: 14px;
						width: 15px;	
						margin-right: 10px;
						vertical-align: -2px;
						margin-left: -4px;
					}
				}
			}
		
			> .compose-area {
				display: grid;
				grid-template-columns: var(--grid-columns);
				box-sizing: border-box;
		
				background: #F8FBFA;
				transition: padding .2s, height .2s;
				height: 0;
				padding: 0 40px;

				will-change: padding, height;
		
				&.expanded {
					padding-top: 35px;
					height: 350px;
					padding-bottom: 40px;
					background: #{'hsl(168 30% 97% / 1)'};
		
					&.show-subject {
						height: 369px;
					}
		
					> .buttons {
						display: block;
					}
				}
		
				> .buttons {
					display: none;
		
					grid-column: 4;
					grid-row: 1;
					text-align: right;
					align-self: end;
		
					> .trash {
						@include mdi-icon(trash-can-outline, $font-size: 24px, $margin-right: 0, $vertical-align: -4px);
						margin-left: 8px;
						padding-top: 7px;
					}
				}
		
				> .minimize {
					grid-column: 4;
					grid-row: 1;
					text-align: right;
					margin-top: -7px;
					margin-right: 11px;
					margin-left: auto;
		
					@include mdi-icon(chevron-down, 22px, 5px, -3px);
				}
		
				> h3 {
					grid-column: 1;
					grid-row: 1;
					text-align: right;
					font-size: 17px;
					color: rgba($bright-colors_dark-gray-text, .84);
					font-weight: 600;
					line-height: 1.2;
				}
		
				> email-composer {
					grid-column: 3;
					grid-row: 1;
		
					.email-editor > .part {
						background-color: rgba(white, .92);
			
						> .editable {
							&:hover,
							&:focus {
								background: white;
							}
			
							&:hover {
								border-color: #9ccaea;
							}
							
							&:last-child:not(.changed):not(:focus) {
								color: rgba($bright-colors_dark-gray-text, .8);
							}
						}
					}
				}
			}
		}
	}
}