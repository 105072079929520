@import "../variables";

.label-radio {
	> :not(input) {
		background-color: #f0f0f0;
		min-height: 41px;
		line-height: 40px;
		padding: 0 2ch;
		display: inline-block;
		transition: background-color .1s;
	}

	&:hover {
		> :not(input) {
			background-color: #E2E6E7;
		}
	}

	> input:checked + :not(input) {
		background-color: $bright-colors_baby-blue-1;
	}
}