﻿@import "../mixins/material-deisgn-icons";
@import "../variables";
@import "../mixins/ng-animate";

#sales-orderarchive-details-view .page-content,
#sales-ongoingorder-details-view .page-content > div[ui-view] {
	max-width: 1300px;
	min-width: 1150px;
	margin-left: auto;
	margin-right: auto;

	article {
		> section:first-child {
			margin-bottom: 15px;
		}

		> .button-row {
			margin: 0;
			display: flex;

			> button {
				margin-right: 13px;

				@include collapse-horizontally();

				&.more {
					width: 42px;
					padding: 0;
					text-align: center;

					@include mdi-icon(dots-horizontal, 22px, 0, -3px);
				}
			}
		}

		
	}
}

#order-customer-details > .customer-details {
	width: 830px;
	padding: 30px;
	display: flex;
	flex-wrap: wrap;

	> .details {
		margin-bottom: -30px;
		width: 580px;
		
		> .address {
			text-transform: capitalize;
		}
	}

	> .metrics {
		background: #fafafa;
		border-radius: 8px;
		flex: 1;
		margin-left: 30px;
		padding: 20px;

		> .metric {
			text-align: center;
			padding: 25px 15px;

			> label {
				text-transform: uppercase;
				font-size: 13px;
				color: $bright-colors_dark-gray-text;
			}

			> hr {
				margin: 9px 0;
			}

			> span {
				font-size: 18px;
				font-weight: 600;
			}
		}
	}

	> .buttons {
		margin-top: 10px;
	}
}

.print-order-menu {
	width: 210px;
	padding: 18px;

	li {
		margin: 8px 0;

		> label > span {
			display: block;
		}
	}

	button {
		width: 100%;
		margin-top: 25px;
	}
}