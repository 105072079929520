﻿@import "../variables";
@import "../mixins/material-deisgn-icons";
@import "../mixins/webkitscrollbar";
@import "../mixins/responsive";
@import "../mixins/callout-arrow";
@import "../mixins/animations";
@import "../mixins/ng-animate";

@keyframes intro-floater {
	0% {
		transform: translate(112px, 113px);
	}
	100% {
		transform: translate(405px, 131px);
	}
}

@keyframes intro {
	0% {
		opacity: 1;
		height: 235px;
		margin: calc(50vh - 21em) 0 3em;
	}
	86% {
		opacity: 1;
	}
	96% {
		opacity: 0;
		margin: calc(50vh - 21em) 0 3em;
		height: 235px;
	}
	99% {
		height: 0px;
		margin: 0;
		opacity: 0;
	}
}

#organizationSettings-saleschannels_itemgroups-view > .page-content {
	min-width: 1120px;
	max-width: 1320px;

	> h1 {
		margin-bottom: 50px;
	}
	
	> p {
		width: 30em;
		margin: 0 auto;
		text-align: center;
	}

	#intro {
		animation: intro 4s;
		animation-fill-mode: both;
		animation-timing-function: ease-in-out;

		height: 0px;
		overflow: hidden;
		margin: 0;

		text-align: center;

		> svg {
			width: 543px;
			height: 194px;

			#floater {
				stroke: #787878;
				stroke-width: 2px;
				fill: #f5f5f5;
				width: 86px;
				height: 14px;
				transform: translate(404px, 113px);
				animation: intro-floater 2s infinite;
			}
		}
	}

	> .patch-info {
		margin: 0;
	}

	> .trees-container {
		$panes-height: calc(100vh - 8em);

		display: grid;
		column-gap: 20px;
		opacity: 0;
		height: $panes-height;
		padding-top: 15px;
		margin-bottom: 50px;
		grid-template-columns: 480px 1fr;

		scroll-margin-top: 64px;
		
		&.with-intro {
			@include animate-fade-in(.5s, 4s);
		}
		&:not(.with-intro) {
			@include animate-fade-in(.5s, .15s);
		}

		.highlighted {
			> .view {
				background-color: #f7fffa;
				box-shadow: inset 0 0 0px 1px #dffbec;
			}
		}

		.association-markers {
			float: right;
			margin-right: 11px;
			display: inline-block;

			> .association-marker {
				display: inline-block;
				height: 5px;
				width: 5px;
				background: #7db7d8;
				border-radius: 5px;
				vertical-align: 2px;
				margin-left: 3px;
			}
		}

		[collapsed='true'] .association-markers {
			display: none;
		}

		> div {
			padding: 35px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			min-height: max-content;

			max-height: $panes-height;
			box-sizing: border-box;

			> h2 {
				margin-bottom: 23px;

				> .webshop-platform-logo {
					height: 35px;
					width: 37px;
					vertical-align: middle;
					margin-right: 7px;
				}
			}

			> .tabs {
				margin-bottom: 2.2em;
			}

			> .tree-editor {
				margin: 0;
				padding: 0;
				flex: 1;
				
				&:not(.empty) {
					overflow-y: auto;
					@include webkit-scrollbar();
				}
			}

			.source-tree-options {
				background-color: #f0f0f0;
				margin-top: 1.5em;
			}

			&.source .expand-button + span > i {
				font-weight: 300;
				color: #b2b2b2;
			}

			&.source {
				background: #f0f0f0;

				.angular-ui-tree-handle {
					padding-right: 0;
				}

				@at-root .source-node.angular-ui-tree-drag .view {
					background-color: $bright-colors_dim-blue !important;
				}
			}

			&.destination {
				background: white;
			}

			&.destination.node {
				> *:not(h2) {
					@include animate-fade-in(.24s);
				}

				> h2 {
					> svg {
						margin-right: 9px;
					}

					font-size: 17px;

					> .path > span {
						font-weight: 500;
						font-size: 15px;

						&:after {
							content: '';
							display: inline-block;
							width: 13px;
							height: 12px;
							border-right: solid 1px darkgrey;
							border-top: solid 1px darkgrey;
							transform: rotate(57deg) skewX(25deg);
							margin-right: 7px;
							vertical-align: -1px;
							margin-left: 2px;
						}
					}

					> .back {
						float: right;
						font-size: 14px;
						font-family: muli;
						font-weight: 400;
						color: $bright-colors_dark-gray-text;
						@include mdi-icon(subdirectory-arrow-right, 20px, $vertical-align: 0);

						padding: 5px 9px 7px 6px;
						border: solid 2px transparent;
						border-radius: 4px;

						transition: border-color .2s;

						&:hover {
							border-color: silver;
						}

						&:before {
							transform: rotate(-180deg);
						}
					}
				}

				> .source-nodes {
					margin-bottom: 3em;
					background-color: transparent;
					transition: background-color 0s, margin-bottom .2s;

					.included-nodes-label {
						margin-bottom: 7px;

						> .collapse {
							float: right;

							@include mdi-icon(arrow-collapse, 18px, $location: 'after', $vertical-align: -1px);
						}
					}

					.included-nodes > .selectize-input {
						border: solid 1px #ebebeb;
						padding: 10px 16px 11px 16px !important;
						background: transparent;
					}

					.add {
						margin-top: 13px;
					}

					.unpublished-items-count {
						float: right;
						display: inline-block;
						background: $bright-colors_turquoise;
						padding: 4px 11px 4px 8px;
						margin-top: 8px;
						color: rgba(0,0,0,.55);
						font-weight: 700;
						font-size: 14px;
						border-radius: 15px;

						@include mdi-icon(plus, 16px, 1px, $vertical-align: -1px);
						@include animate-bounce-in(.4s, .5s, 1, 1.3, .5s, .3s);
					}

					&.collapsed {
						transition: background-color .2s, margin-bottom .2s;
						background-color: $bright-colors_baby-blue-2;
						border-radius: 30px;
						padding: 11px 18px;
						line-height: 25px;
						margin-bottom: 35px;

						> .included-nodes {
							display: none;
						}

						> .unpublished-items-count {
							display: none;
						}

						> .collapse {
							float: right;

							@include mdi-icon(arrow-expand, 18px, $location: 'after', $vertical-align: -2px);
						}

						strong {
							background-color: #d0e2ec;
							padding: 5px 6px;
							border-radius: 26px;
							display: inline-block;
							height: 16px;
							margin: 0 2px;
						}
					}
				}

				> .items-container {
					flex: 1;
					overflow: hidden;
					display: flex;
					flex-direction: column;
					justify-content: center;

					> h3 {
						font-weight: 600;
						margin-bottom: 0;
						display: flex;
						justify-content: space-between;
						align-items: center;

						transition: background-color .2s, padding .2s;

						> span {
							order: 0;
							flex: 1;
						}

						> .search-box {
							order: 1;
							flex: 0;
							position: relative;

							padding-right: 18px;
							border-right: solid 1px silver;
							margin-right: 20px;


							> input {
								padding-right: 30px;
								transition: width .3s;
								width: 90px;

								&.ng-not-empty, &:focus {
									width: 150px;
								}
							}

							@include mdi-icon(magnify, 18px, 0, middle, #888);

							&:before {
								position: absolute;
								right: 26px;
								top: 10px;
							}
						}

						> .add-items {
							order: 2;
						}

						> .selectize-control {
							flex: 1;
							margin: 0 19px 0 5px;
							font-family: $muli;
							font-size: 14px;
							color: $bright-colors_black-text;
							font-weight: 400;

							> .selectize-input {
								height: 37px;
								line-height: 36px;
							}
						}

						> .close {
							@include mdi-icon(check, 20px, 0, -4px);
						}

						&.add-items {
							background: #f8f8f8;
							padding: 10px;
						}
					}

					> .table-wrapper {
						margin: 2em 0;
						flex: 1;
						overflow: auto;
						@include webkit-scrollbar($thumb-color: rgba(black, .1), $track-color: rgba(black, .04));

						&:hover {
							@include webkit-scrollbar($thumb-color: rgba(black, .25), $track-color: rgba(black, .07));
						}

						tr:hover .remove > i {
							opacity: 1;
							transition: opacity .4s .07s, border-color .1s;
						}

						.remove {
							padding: 0;
							text-align: right;
							padding-right: 5px;							

							> i {
								@include mdi-icon(trash-can-outline, $font-size: 21px, $margin-right: 0, $color: #919191, $vertical-align: -2px);
								border: solid 2px transparent;
								height: 100%;
								display: inline-block;
								border-radius: 4px;
								opacity: 0;
								cursor: pointer;
								transition: opacity .1s, border-color .1s;

								&:hover {
									border-color: #999;

									&:before {
										color: #616161;
									}
								}

								&:active {
									border-color: #777;

									&:before {
										color: #555;
										background: #f0f0f0;
									}
								}
							}
						}
					}

					> .paging {
						text-align: center;
						margin-bottom: 3em;
					}
				}
			}

			&.destination.tree {
				position: relative;
				max-width: calc(1320px - 500px);

				> .placeholder {
					display: none;
					position: absolute;
					color: darkgrey;
					font-weight: 600;
					width: 100%;
					text-align: center;
					left: 0;
					z-index: 1;
					top: calc(50% - 10px);
				}

				&.empty {
					.placeholder {
						display: block;
					}

					.angular-ui-tree {
						flex: 1; // När det finns en eller flera noder vill vi inte att den är större än den höjd den själv bygger, pga att det då smäller när man släpper noder där
						border-radius: 5px;
						border: dashed 2px #b1b1b1;
						margin-bottom: 40px;
						z-index: 2;
						position: relative;
					}
				}

				> .items {
					flex: 1;
					overflow: hidden;
					display: flex;
					flex-direction: column;
					justify-content: center;

					@include animate-show($fade-in-duration: .15s, $show-delay: .01s, $fade-out-duration: 0s);

					> .search-box {
						position: relative;

						> input {
							float: right;
							padding-right: 30px;
							transition: width .3s;
							width: 90px;

							&.ng-not-empty, &:focus {
								width: 150px;
							}
						}

						@include mdi-icon(magnify, 18px, 0, middle, #888);

						&:before {
							position: absolute;
							right: 8px;
							top: 10px;
						}
					}

					> .table-wrapper {
						margin: 2em 0;
						flex: 1;
						overflow: auto;
						@include webkit-scrollbar($thumb-color: rgba(black, .1), $track-color: rgba(black, .04));

						&:hover {
							@include webkit-scrollbar($thumb-color: rgba(black, .25), $track-color: rgba(black, .07));
						}

						.table>tbody>tr>td:nth-child(3) {
							max-width: calc(80px + 4vw);
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}

					> .paging {
						text-align: center;
						margin-bottom: .5em;
						margin-top: .5em;
					}
				}

				> .tree-editor {
					@include animate-show($fade-in-duration: .15s, $show-delay: .01s, $fade-out-duration: 0s);

					background: transparent;
					flex: 1;

					display: flex;
					flex-direction: column;
					align-items: self-start;
					justify-content: space-between;

					padding: 1px; // Annars syns inte vänsterbordern på rotnoderna

					> [ui-tree] {
						margin: 0;
						width: 100%;
						margin-bottom: 2em;

						.angular-ui-tree-nodes {
							// Max-height-joxet nedan är för att 1) slippa glapp när en gren är kollapsad
							// och 1) bibehålla en smooth transition när grenen kollapsas

							transition: max-height 0s 0s;
							max-height: 100000vh;

							&.collapsed {
								transition: max-height 0s .3s;
								max-height: 0;
							}

							> .angular-ui-tree-node {
								> .angular-ui-tree-handle {
									margin-top: -1px;
									margin-bottom: 0;

									.filter-button {
										background: rgba(#7ca4bd, 0.6);
										color: white;
										border-radius: 5px;
										float: right;
										margin: 5px 8px 5px 7px;
										opacity: 0;
										padding: 0 9px;
										vertical-align: middle;
										line-height: 26px;

										transition: background .1s, opacity .1s;

										&:hover {
											background: #7ca4bd;
										}
									}

									&:hover .filter-button {
										opacity: 1;
									}

									> .view {
										background: #e4f3fb;
										box-shadow: inset 0px 0px 0px 1px #cce1ea;
										height: 37px;
										line-height: 37px;
										transition: background-color .1s;

										&:hover {
											background: #dbedf7;
										}

										> .delete {
											background: #f2f2f2;
										}

										> .expand-button {
											width: 24px;

											&:before {
												vertical-align: -2px;
											}
										}
									}
								}
							}
						}

						> .angular-ui-tree-nodes:first-child > .angular-ui-tree-node:first-child > .angular-ui-tree-handle {
							margin-top: 0;
						}
					}

					> .add-root-node {
						margin-top: 0;
					}
				}
			}
		}
	}

	> .button-row {
		&.with-intro {
			@include animate-fade-in(.5s, 4s);
		}
	}

	.patch-info.count {
		> button {
			margin-left: 30px;
		}
	}

	#node-filter-popup {
		@include center-content-in-main-area-when-fits(730px);
	}

	#node-filter-popup > div {
		width: 650px;
		max-height: calc(100vh - 170px);
		display: flex;
		flex-direction: column;

		h2 {
			margin-bottom: 40px;
		}

		.included-nodes-label {
			margin-bottom: 10px;
		}

		.included-nodes > .selectize-input {
			border: solid 1px #ebebeb;
			padding: 10px 16px 11px 16px !important;
		}

		.add {
			margin-top: 15px;
		}

		.button-row {
			margin-top: 40px;
		}
	}
	
	#item-exclusion-popup > div {
		width: 650px;

		.table-container {
			max-height: 400px;
			overflow-y: auto;
			@include webkit-scrollbar;
			margin: 30px 0;

			table {

				tbody > tr > td:last-child {
					text-align: right;
					@include mdi-icon(close);
					padding: 0;
					cursor: pointer;
					width: 27px;

					&:before {
						width: 27px;
						height: 27px;
						border-radius: 27px;
						text-align: center;
						line-height: 27px;
						margin: 0;
					}

					&:hover:before {
						background: $bright-colors_baby-blue-1;
					}
				}
			}
		}

		label {
			margin: 40px 0;
			display: block;
			width: 250px;

			> span {
				margin-bottom: 4px;
				display: block;
			}

			.selectize-control {
				background: #fafafa;

				> .selectize-input.focus {
					background: #f7fafc;
				}
			}
		}
	}
}

.selectize-dropdown.included-nodes, .selectize-dropdown.publish-itemgroups-exclude-items.item-auto-complete {
	box-shadow: 0px 0px 1px 1px rgba(1, 1, 0, 0.06), 6px 6px 17px rgba(black, .07), -6px 6px 17px rgba(black, .07);
}

.selectize-dropdown.single.add-items-to-published-node {
	box-shadow: 0 3px 4px rgba(0,0,0,.05),
				1px 1px 1px rgba(1,1,0,.05),
				0 6px 10px 5px rgba(black, .06);
}

.popup-overlay.add-items-to-published-node {
	background: rgba(black, .02);
}