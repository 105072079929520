accounting-home {
	width: 700px;
    margin: 0 auto;
	display: block;

	> h1 {
		margin-bottom: 5rem;
	}

	> .details {
		margin-bottom: -2em;
	}

	.button-group {
		text-align: right;

		> button {
			margin-left: 10px;
		}
	}

	.under-construction {
		margin: auto;

		display: flex;
		flex-direction: column;
		align-items: center;
		row-gap: 1em;
		max-width: 50ch;
	
		> img {
			width: 12ch;
		}
	}

	> article {
		> h1 {
			margin-bottom: 50px;
		}

		> summary {
			margin-bottom: 5em;

			> div {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 30px 0;
			}

			> hr {
				margin: 0;
			}

			.details {
				margin: 0;
				> dt {
					height: min-content;
				}
			}

			.details > dd {
				padding-bottom: 0;

				&:after {
					content: '';
					width: 11px;
					height: 11px;
					border-radius: 11px;
					display: inline-block;
					margin-left: 8px;
				}

				&.active:after { background-color: $bright-colors_mint-green; }
				&.paused:after { background-color: #b5b5b5 }
				&.faulted:after { background-color: $bright-colors_red; }
			}
		}

		> h2 {
			margin-bottom: 1.5em;
		}
	}
}