.add-to-purchase-order {
	position: fixed;
	width: 66ch;
	overflow: hidden;

	> label:first-of-type {
		margin-top: 2em;
	}

	> .drafts {
		margin: 3em 0;

		> h3 {
			font-size: 16px;
		}

		> .table-container {
			background: #{'hsl(202 44% 98% / 1)'};
			padding: 14px 18px 23px;

			max-height: calc(100vh - 620px);
			overflow-y: auto;
			@include webkit-scrollbar($width: 10px, $track-color: rgba(black, .07), $thumb-color: rgba(black, .15), $border-radius: 8px, $on-hover-only: false);

			> .table {
				--border-color: #{'hsl(202 22% 89% / 1)'};
			}
		}
	}

	> .button-row {
		margin-top: 3em;
	}
}