﻿
@import "../mixins/dashed-underline";
@import "../mixins/material-deisgn-icons";
@import "../font-awesome";
@import "../_variables.scss";
@import "../mixins/ng-animate";

#organizationSettings-supplierform-view {
	#supplier-form > form {
		width: 900px;
		margin: 70px auto;

		> section:first-of-type {
			width: 700px;

		}

		.domestic {
			margin-bottom: 40px;
		}

		#purchase-orders {
			.switch {
				display: inline-block;
				margin-top: 9px;
			}

			.fields-container {
				margin-top: 30px;
			}
		}

		#contact-persons {
			margin-bottom: -20px;

			> div {
				> ul {
					> li {
						display: inline-block;
						margin: 0 15px 15px 0;
						vertical-align: middle;
					}

					> li:not(.add) {
						position: relative;
						@include mdi-icon(account, 24px, $margin-right: 0px, $color: #8cc0e2);
						@include fa-icon($fa-var-paperclip, after, 18px);
						@include animate-show();

						&:before {
							position: absolute;
							margin-top: -14px;
							top: 50%;
							left: 14px;
						}

						&:after {
							position: absolute;
							top: -9px;
							left: 17px;
							color: #a5a5a5;
						}

						background-color: #fafafa;
						padding: 15px 25px 15px 50px;
						box-shadow: 1px 1px 2px rgba(black, .08);
						line-height: 1.2em;
						cursor: pointer;

						&:hover {
							background-color: darken(#e4f2fb, 2%);
						}

						> div:nth-of-type(2) {
							font-size: 13px;
							color: #8f9fa9;
						}
					}

					> li.add {
						#add-contact {
							padding: 0;
							width: 42px;
							line-height: 43px;
							margin: 10px;
							@include mdi-icon(account-plus, 22px, $margin-right: 0px, $color: gray, $vertical-align: -1px);
						}
					}
				}
			}
		}

		confirm-button {
			float: right;
		}

		button.remove {
			@include mdi-icon(trash-can-outline, 21px, 0, -1px);
			width: 42px;
			text-align: center;
		}
	}
}

#new-contactperson-form {
	> div {
		width: 355px;

		> label {
			&:first-of-type {
				display: inline-block;
				width: 140px;
			}

			&:nth-of-type(2) {
				display: inline-block;
				width: 200px;
				margin-left: 10px;
			}

			&:nth-of-type(3) { margin: 10px 0 20px; }

			> input {
				box-sizing: border-box;
				width: 100%;
				margin-top: 4px;
			}
		}

		button.remove {
			@include mdi-icon(trash-can-outline, 21px, 0, -1px);
			width: 42px;
			text-align: center;
			padding: 9px 0;
		}
	}
}