﻿@mixin gauge($size, $color: #3498db, $background-color: #7f8c8d, $text-color: #7f8c8d, $value-text-size: 100%, $wedge-thickness: 9px) {
	> .pie-wrapper {

		width: $size;
		height: $size;

		> .label {
			color: $text-color;

			.value {
				font-size: $value-text-size;
			}
		}

		> .pie > .half-circle {
			border-color: $color;
		}

		> .shadow {
			border-color: $background-color;
			border-width: $wedge-thickness;
		}

	}
}