﻿@mixin animate-show($fade-in-duration: .2s, $show-delay: 0s, $fade-out-duration: $fade-in-duration) {
	&.ng-hide-remove {
		transition: opacity ease $fade-in-duration $show-delay;
		opacity: 0;
	}

	&.ng-hide-remove.ng-hide-remove-active {
		opacity: 1;
	}

	&.ng-hide-add {
		transition: opacity ease $fade-out-duration;
		opacity: 1;
	}

	&.ng-hide-add.ng-hide-add-active {
		opacity: 0;
	}

	&.ng-enter {
		transition: opacity ease $fade-in-duration $show-delay;
		opacity: 0;
	}

	&.ng-enter.ng-enter-active {
		opacity:1;
	}

	&.ng-leave {
		transition: opacity ease $fade-out-duration;
		opacity:1;
	}

	&.ng-leave.ng-leave-active {
		opacity:0;
	}
}

@mixin collapse-horizontally() {
	&.ng-leave, &.ng-hide-add {
		opacity: 1;
		transition: width .3s cubic-bezier(0.165, 0.84, 0.44, 1),
					padding .3s cubic-bezier(0.165, 0.84, 0.44, 1),
					filter .3s cubic-bezier(0.165, 0.84, 0.44, 1);
		overflow: hidden;
		white-space: nowrap;
		min-width: 0;
		filter: saturate(.8);
	}

	&.ng-leave.ng-leave-active, &.ng-hide-add.ng-hide-add-active {
		opacity: .5;
		max-width: 0;
		padding-left: 0;
		padding-right: 0;
		filter: saturate(0);
	}
}

@mixin animate-expand($max-height, $fade-duration: .4s, $show-delay: 0s, $extra-transition: none) {
	&.animate-if.ng-enter, &.ng-hide-remove {
		transition: if($extra-transition == none,
						max-height $fade-duration $show-delay,
						#{max-height $fade-duration $show-delay, $extra-transition});
		max-height: 0;
		overflow: hidden;
	}

	&.animate-if.ng-enter.ng-enter-active, &.ng-hide-remove.ng-hide-remove-active {
		max-height: $max-height;
	}

	&.animate-if.ng-leave, &.ng-hide-add {
		transition: if($extra-transition == none,
						max-height $fade-duration,
						#{max-height $fade-duration, $extra-transition});

		max-height: $max-height;
	}

	&.animate-if.ng-leave.ng-leave-active, &.ng-hide-add.ng-hide-add-active {
		max-height: 0;
		overflow: hidden;
	}
}

@mixin animate-slide-in($fade-duration: .2s, $show-delay: 0s) {
	overflow: hidden;
	transition: transform ease $fade-duration;

	&.animate-if.ng-enter, &.ng-hide-remove {
	  transition: transform ease $fade-duration $show-delay;
	  transform: translate(100%);
	}

	&.animate-if.ng-enter.ng-enter-active, &.ng-hide-remove.ng-hide-remove-active {
	  transform: translate(0);
	}

	&.animate-if.ng-leave, &.ng-hide-add {
		transform: translate(0);
	}

	&.animate-if.ng-leave.ng-leave-active, &.ng-hide-add.ng-hide-add-active {
	  transform: translate(100%);
	}
}

@mixin animate-inflate($duration: .2s, $transform-origin: center, $show-delay: 0s) {
	transform-origin: $transform-origin;
	transition: transform $duration cubic-bezier(0.020, 1, 0.795, 0.990);
	transform: scale(1);
	transition-delay: $show-delay;

	&.ng-hide {
		transform: scale(.2);
		transition-delay: 0;
	}

	&.animate-if.ng-enter, &.ng-hide-remove {
		transform: scale(.2);
		transition-delay: 0;
	}

	&.animate-if.ng-leave, &.ng-hide-add {
		transform: scale(.2);
		transition-delay: 0;
	}
}