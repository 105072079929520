﻿@import "../variables";

#organizationSettings-paymentmethods-index-view > .page-content {
	max-width: 700px;
    text-align: center;

	.tiles {
		margin: 40px 0;
		display: flex;
		justify-content: center;

		&.active > li {
			background: #ebeff2;
		}

		> li {
			text-align: left;
			width: 200px;
			padding: 20px 25px;

			> h3 {
				font-size: 15px;
			}

			> span {
				font-weight: 300;
			}
		}
	}
}

#organizationSettings-paymentmethods-form-view > .page-content {
	width: 750px;

	> [ui-view] {
		> h1 {
			margin-bottom: 70px;
		}
	}
}