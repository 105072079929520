﻿@import "../variables";
@import "../mixins/material-deisgn-icons";

.popup-menu {
	margin: -5px;
	min-width: 200px;
	padding: 13px;

	&.has-icons {
		> ol > li > a, > ul > li > a {
			vertical-align: middle;
			height: 20px;
			line-height: 20px;
			padding: 6px 11px 7px;
			padding-left: 5px;

			&:before {
				content: '';
				width: 19px;
				margin-right: 12px !important;
				display: inline-block;
				vertical-align: -1px;
				color: $bright-colors_dark-gray-text !important;
			}
		}
	}
		
	> ol, > ul {
		&:not(:first-child) {
			border-top: solid 1px $bright-colors_silver-stroke-on-white;
			padding-top: 6px;
		}

		&:not(:last-child) {
			margin-bottom: 6px;
		}

		> li {
			border-radius: 4px;
			user-select: none;

			&:first-child {
				border-top: none;
			}

			&:hover { background-color: #{'hsl(202 47% 97% / 1)'}; }

			> a {
				display: block;
				color: $bright-colors_dark-gray-text;
				white-space: nowrap;
				padding: 8px 11px 9px;

				&:hover {
					color: $bright-colors_black-text;
				}

				&.refresh { @include mdi-icon(refresh, 18px, $vertical-align: -2px); }
				&.remove { @include mdi-icon(trash-can-outline, 21px, $vertical-align: -2px); }
				&.history { @include mdi-icon(history, 20px, $vertical-align: -2px); }
			}
		}
	}
}