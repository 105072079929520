﻿@import "../mixins/animations";
@import "../variables";

a:not(.button).big-button {
	> i {
		display: inline-block;
		width: 37px;
		height: 37px;
		border-radius: 37px;
		background: #a8a8a8;
		vertical-align: -12px;
		margin-right: 8px;
		color: white;
		position: relative;

		> sup {
			@include animate-bounce(.6s, 1, 1.8, .8s, .8s);

			background: #fc5f56;
			display: inline-block;
			width: 20px;
			height: 20px;
			border-radius: 20px;
			line-height: 19px;
			text-align: center;
			position: relative;
			top: -30px;
			left: 10px;
			font-family: $muli;
			font-size: 11px;
		}
	}

	display: inline-block;
	line-height: 65px;
	font-family: $raleway;
	font-size: 15px;
	font-weight: 500;
	border-radius: 16px;
	background: #f0f0f0;
	color: $bright-colors_black-text;
	height: 63px;
	padding: 0 25px 0 19px;
	transition: background .1s;
	white-space: nowrap;

	&:hover {
		background: #ebebeb;
	}
}