﻿@import "../variables";
@import "../mixins/material-deisgn-icons";


dl.details {
	display: grid;
	grid-gap: 30px;
	margin-bottom: 7px;

	> dd:nth-of-type(1n),
	> dt:nth-of-type(1n) {
		grid-area: one;
	}

	> dd:nth-of-type(2n),
	> dt:nth-of-type(2n) {
		grid-area: two;
	}

	> dd:nth-of-type(3n),
	> dt:nth-of-type(3n) {
		grid-area: three;
	}

	> dd:nth-of-type(4n),
	> dt:nth-of-type(4n) {
		grid-area: four;
	}

	> dd:nth-of-type(5n),
	> dt:nth-of-type(5n) {
		grid-area: five;
	}

	> dd:nth-of-type(6n),
	> dt:nth-of-type(6n) {
		grid-area: six;
	}

	> dd:nth-of-type(7n),
	> dt:nth-of-type(7n) {
		grid-area: seven;
	}

	> dd:nth-of-type(8n),
	> dt:nth-of-type(8n) {
		grid-area: eight;
	}

	&.cols-1 {
		grid-template-columns: 1fr;
		grid-template-areas: 'one';
	}

	&.cols-2 {
		grid-template-columns: 1fr 1fr;
		grid-template-areas: 'one two'
							 'three four'
			                 'five six'
			                 'seven eight';
	}

	&.cols-3 {
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-areas: 'one two three'
			                 'four five six'
			                 'seven eight nine';
	}

	&.cols-4 {
		grid-template-columns: 1fr 1fr 1fr 1fr;
		grid-template-areas: 'one two three four'
			                 'five six seven eight';
	}

	> dt {
		color: #717171;
		margin-top: 2px;
	}

	&:not(.no-stroke) > dd {
		border-bottom: solid 1px #cfcfcf;
	}

	> dd {
		color: $bright-colors_black-text;
		padding: 28px 0 8px;
		min-height: 1em;
		line-height: 1.3;
		align-self: flex-end;
		word-break: break-word;

		&.editable, &.more {
			cursor: pointer;

			&:after {
				float: right;
			}

			&:hover {
				border-bottom-color: #b3d9ea;

				&:after {
					color: $bright-colors_vibrant-blue;
				}
			}
		}

		&.editable {
			@include mdi-icon(pencil, 16px, 0, 0px, $bright-colors_dim-blue, 'after');
		}

		&.more {
			@include mdi-icon(more, 18px, 0, 0px, $bright-colors_dim-blue, 'after');

			&:after {
				position: relative;
				top: -1px;
			}
		}

		&.no-wrap {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
}