
@import "../variables";

@mixin radio($top-offset: 0px, $left-offset: 0px) {
	$color-background: #bbbbbb;
    $color-background-checked: $bright-colors_vibrant-blue;
    $color-border: #b3b3b3;
    $color-border-checked: darken($bright-colors_vibrant-blue, 5%);

	border: 0; 
	clip: rect(0 0 0 0); 
	height: 1px;
	margin: -1px; 
	overflow: hidden; 
	padding: 0; 
	position: absolute; 
	width: 1px;
	-moz-user-select: none;
    user-select: none;

	&[disabled] + span { color: #b6b6b6; }
	
	&[disabled] + span::before, &[disabled] + span:hover::before {
		box-shadow: 0 0 0 1px #cdcdcd;
		background-color: #d5d5d5;
		border: 5px solid #d5d5d5;
	}

	&:checked[disabled] + span::before {
		box-shadow: 0 0 0 1px #cdcdcd;
		background-color: #dedede;
		border: 5px solid #f5f5f5;
	}
	
	&+span {
		&::before {
			content: '';
			display: inline-block;
			width: 6px;
			height: 6px;
			border-radius: 100%;
			border: 5px solid $color-background;
			box-shadow: 0 0 0 1px $color-border;
			margin-right: 9px;
			background-color: $color-background;
            vertical-align: -3px;
		}

		&:hover::before {
			background-color: #D6D6D6;
		}

		> i {
			font-weight: 300;
			color: #9a9a9a;
		}
	}

	&:checked + span::before {
		background: white;
		border-color: $color-background-checked;
		box-shadow: 0 0 0 1px $color-border-checked;
	}
}