@import '../mixins/material-deisgn-icons';

install-fortnox-wizard > .start {
	h1 {
		margin-bottom: 15px;
	}

	.flex-center.column > .button-glow {
		margin-top: 3em;
	}

	.error-message {
		background: #f0f0f0;
		padding: 40px;
		border-radius: 24px;
		text-align: center;
		margin: 1em 0 2em;

		> p {
			margin-top: 23px;
		}
	}
}

excluded-payment-methods {
	display: block;
	width: 72ch;
	margin: 0 auto;

	> h1 {
		margin-bottom: 57px;
	}
	
	> .low-contrast {
		margin: 0 auto 3em;
	}

	> form {
		align-self: stretch;
		
		> * {
			width: 100%;
			padding: 0;

			> button {
				margin: 0;
			}
		}

		> .button-row {
			padding: 0;
			margin-top: 4em;
		}
	}
}