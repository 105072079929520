@import "../variables";

.popup-form {
	&.compact > div {
		margin: 5px;
	}

    > div {
        margin: 20px;
		width: 300px;

        > h1 {
            font-size: 17px;
            text-transform: none;
            margin-bottom: 40px;
            text-align: left;
            margin-top: 0;
            font-weight: 600;
        }

        > label {
            font-size: 14px;
            display: block;
            margin-bottom: 10px;
            color: #353535;

			> span {
				display: block;
				margin-bottom: 6px;
				margin-top: 14px;
			}

			input {
				box-sizing: border-box;
				width: 100%;
				@include input-on-white;
			}

			.selectize-control > .selectize-input, .selectize-control.single > .selectize-input {
				box-sizing: border-box;
				height: 38px;
				line-height: 38px;
				padding: 2px 12px 0;

				@include input-on-white;

				&:after, &.dropdown-active:after {
					margin-top: 0px;
				}
			}
        }

        > button {
            margin-right: 10px;
			margin-top: 20px;

            &:nth-of-type(2) {
                @extend .subtle;
            }
        }

		> .button-row {
			display: flex;
			overflow: hidden;
			max-height: 42px;
			margin-top: 40px;

			> button, > .flexing > button {
				margin-right: 10px;
			}

			> .flexing {
				flex: 1;
				overflow: hidden;
			}

			> confirm-button {
				float: right;

				> .confirm-button-container .confirm-message {
					white-space: normal;
				}
			}
		}
    }
}