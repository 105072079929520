﻿@import "../variables";
@import "../mixins/multiline-ellipsis";
@import "../mixins/material-deisgn-icons";
@import "../mixins/ng-animate";
@import "../mixins/webkitscrollbar";

#customers-details-view > .page-content {
	max-width: 1180px;
	margin: 0 auto;
	display: grid;
	grid-template-columns: 1fr 384px;
	grid-column-gap: 60px;

	> h1 {
		grid-column: span 2;
		margin-bottom: 60px;
	}

	> main {
		grid-column: 1;

		&.has-conversations {
			grid-column-end: 2;
		}

		&:not(.has-conversations) {
			grid-column-end: 3;
			max-width: 960px;
			margin-left: 60px;
		}

		> .details {
			max-width: 492px;
		}

		> h2 {
			margin-top: 30px;
		}

		.note {
			background: #ffffe7;
			padding: 20px;
			box-shadow: 2px 2px 3px rgba(black, .1);
			margin-bottom: 50px;
			max-width: 492px;
			max-height: 300px; // ex padding
			transition: max-width .3s, max-height .3s, opacity .15s;
			overflow-y: auto;
			@include webkit-scrollbar();

			&.empty:not(.editing) {
				max-width: 25px;
				max-height: 24px;

				&:not(:hover) {
					opacity: .65;
				}
			}

			> p {
				white-space: pre-wrap;
			}

			> .edit-button {
				@include mdi-icon(pen, 24px, 0, 0);
				color: rgba(black, .3);
				float: right;
				cursor: pointer;

				&:hover {
					color: rgba(black, .5);
				}
			}

			> textarea {
				display: block;
				width: 100%;
				box-sizing: border-box;
				background-color: #f9f9e1;
				margin-bottom: 20px;
				resize: vertical;

				@include webkit-scrollbar();
			}

			> button {
				@include animate-show();
			}
		}

		> table.orders {
			margin-top: 30px;

			.quarantined, .return {
				background-color: $bright-colors_red;
				color: white;
				padding: 3px 7px 4px;
				border-radius: 8px;
			}

			.ongoing {
				background-color: $bright-colors_vibrant-blue;
				color: white;
				padding: 2px 7px 4px;
				border-radius: 8px;
			}
		}
	}

	> aside {
		grid-column: 2;

		> h2 {
			margin-bottom: 30px;
		}

		> ol {
			> li {
				background: #fbfbfb;
				padding: 20px 25px;
				border-left: solid 2px #e4e4e4;
				position: relative;
				cursor: pointer;
				transition: background .1s, border-left-color .2s;
				// Prick
				&:before {
					content: '';
					background: #fafafa;
					height: 7px;
					width: 7px;
					border-radius: 100%;
					position: absolute;
					left: -6px;
					top: 23px;
					border: solid 2px #dedede;

					transition: background .1s, border-color .2s;
				}

				&:not(:last-child) {
					border-bottom: solid 1px #efefef;
				}

				> .date {
					font-weight: 300;
					color: #6d6d6d;
				}

				> h4 {
					font-family: $muli;
					font-size: 14px;
					font-weight: 600;
					margin: 8px 0 12px;
					color: #444;

					@include multiline-ellipsis($line-height: 1.4, $lines-to-show: 1, $background-color: #fafafa);
				}

				> .message {
					color: #9a9a9a;
					line-height: 1.4;
					@include multiline-ellipsis($line-height: 1.4, $lines-to-show: 2, $background-color: #fafafa);

					* {
						border: none !important;
						background-color: none;
						font-family: $muli !important;
						font-size: 14px !important;
					}

					> br + br {
						display: none;
					}
				}

				> .message-count {
					background: #eaeaea;
					width: 31px;
					height: 31px;
					border-radius: 100%;
					position: absolute;
					font-weight: bold;
					top: 20px;
					right: 20px;
					text-align: center;
					line-height: 31px;
					color: $bright-colors_dark-gray-text;
				}

				&:hover {
					background: white;
					border-left-color: $bright-colors_dim-blue;
					transition: background .2s, border-left-color .2s;

					&:before {
						border-color: $bright-colors_dim-blue;
						background: $bright-colors_dim-blue;
					}

					> .date {
						color: $bright-colors_blue-text-on-white;
					}

					> h4 {
						color: $bright-colors_black-text;
						@include multiline-ellipsis($line-height: 1.4, $lines-to-show: 1, $background-color: white);
					}

					> .message {
						@include multiline-ellipsis($line-height: 1.4, $lines-to-show: 2, $background-color: white);
						color: $bright-colors_black-text;
					}

					> .message-count {
						background: darken($bright-colors_baby-blue-1, 3%);
					}
				}
			}
		}
	}
}