﻿@import "../variables";

.slider {
    display: inline-block;
    white-space: nowrap;
    width: 180px;
    
    > output {
        color: $bright-colors_dark-gray-text;
        min-width: 20px;
        display: inline-block;
        
        &:first-child {
            text-align: right;
        }
    }
    
    > .rzslider {
        margin: 0 0 15px 0;
        top: 6px;
        width: 140px;
        
        $disabled-color: #29464a;
        
        .rz-bubble {
            display: none;
        }
        
        .rz-pointer {
            top: -7px;
            width: 17px;
            height: 17px;
            background-color: $bright-colors_vibrant-blue;
            border-radius: 17px;
			outline: none;
            
            &:after {
                top: 5px;
                left: 5px;
				width: 6px;
				height: 6px;
				transform: scale(1.1) translateY(.1px) translateX(.1px);
				transform-origin: top left;
            }
        }
    
        .rz-bar-wrapper {
            padding-top: 17px;
        }
        
        .rz-bar {
            background-color: #c7c7c7;
            height: 2px;
            &.rz-selection {
                background-color: $bright-colors_vibrant-blue;
            }
        }
        
        &[disabled] {
            .rz-pointer {
                background-color: $disabled-color;
                
                &:after {
                    background-color: rgba(white, .33);
                }
            }
            
            .rz-bar {
                background-color: #313f4d;
                &.rz-selection {
                    background-color: $disabled-color;
                }
            }
        }
    }
}