.toggle-button-group {
	display: inline-flex;

	> button {
		margin: 0;
		border-radius: 0;
		border-width: 2px 1px 2px 1px;

		&:first-child {
			border-radius: 4px 0 0 4px;
			border-left-width: 2px;
		}

		&:last-child {
			border-radius: 0 4px 4px 0;
			border-right-width: 2px;
		}

		&.selected {
			background-color: #8EB8CF;
			color: white;
			border-color: #8BAEC1;
		}
	}
}