﻿@import "../variables";
@import "../mixins/material-deisgn-icons";

#items-stock-warehouse-stocktake-view .page-content {
	max-width: 1015px;

	h1 {
		margin-bottom: 70px;
	}

	#general {
		> div {
			display: flex;

			> .warehouse {
				width: 300px;
				margin-right: 40px;
			}

			> .duration {
				width: unset;

				> datepicker {
					> input {
						width: 130px;
					}

					&:first-of-type {
						margin-right: 6px;
					}

					&:last-of-type {
						margin-left: 6px;
					}
				}
			}
		}
	}

	section > div {
		margin: 0;
	}

	#table-headline {
		margin-bottom: 30px;
		overflow: hidden;

		h2 {
			float: left;
		}

		.filter {
			float: right;

			> .search-box {
				margin-left: 30px;

				> input {
					padding-right: 30px;
					transition: width .3s;
					width: 70px;

					&.ng-not-empty, &:focus {
						width: 150px;
					}
				}

				@include mdi-icon(magnify, 18px, 0, middle, #606060);

				&:before {
					position: absolute;
					right: 8px;
					top: 10px;
				}
			}
		}
	}

	td.right-aligned {
		width: 100px;
	}

	td.hidden-items-warning {
		background: #f0f0f0;
		color: silver;
		font-weight: 300;
		text-align: center;
		padding: 15px
	}

	thead > tr > th {
		&:nth-child(1) { width: 120px; }
	}

	.button-row {
		margin-top: 60px;
		padding: 0;
	}
}