﻿@import "variables";
@import 'mixins/_animations.scss';

form {
	$section-headline-width: 200px;

	--section-headline-width: #{$section-headline-width};
	$main-column-width: calc(100% - #{$section-headline-width});

	&.on-white {
		// Ge en form klassen 'on-white' så anpassas utseendet på alla fält
		input, .selectize-control > .selectize-input, .selectize-control.single > .selectize-input {
			@include input-on-white;
		}

		hr {
			background-color: #e8e8e8;
		}
	}

	&.two-cols {
		> section {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-gap: 10px 20px;
			margin: 2rem 0;

			> .form-field {
				> input[type=text]:nth-of-type(2) {
					margin-top: 8px;
				}

				&.full-width {
					grid-column: 1 / 3;
				}
			}
		}

		> hr {
			margin: 2em 0;
		}

		> .button-row {
			padding: 0;
		}
	}

	> section {
		position: relative;
		margin: 50px 0;
		clear: both;

		&:not(.compact) {
			min-height: 40px; // Exempel på när det inte är snyggt annars: levfaktura med Fakturan betald ikryssad
		}

		> h1 {
			position: absolute;
			font-weight: 500;
			color: $bright-colors_black-text;
			font-size: 17px;
			width: var(--section-headline-width);
			margin-top: -4px; // pga line-height
			text-align: left;
			line-height: 1.2;
			font-family: $raleway;

			> i {
				color: #999;
				font-size: 14px;
				display: block;
				font-weight: 300;
				margin-top: 7px;
				padding-right: 30px;
				font-family: $muli;
			}
		}

		> div {
			margin-left: var(--section-headline-width);

			&.fields-container {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				&:last-child {
					margin-bottom: -23px; // .form-field har bottom-margin: 23px
				}
			}

			&.split > .form-field {
				width: calc(50% - 15px);
			}

			&:not(.split) > .form-field {
				width: 100%; // Om labels blir klickbara på för stor yta, är .form-field kanske inte rätt klass?
			}

			> .form-field {
				&.full-width {
					width: 100%;

					> textarea {
						resize: vertical;
					}
				}

				&:not(.full-width) > textarea {
					resize: none;
				}
			}
		}
	}

	> .button-row {
		text-align: left;
		padding-left: var(--section-headline-width);
	}

	> .button-row-flyup {
		background: white;
		padding: 30px;
		box-shadow: 0 0 0 100vw rgba(black, .04), 0 0 10px rgba(black, .04);
		z-index: 10;
		position: relative;
		margin-top: 40px;
		@include animate-slide-up;
		display: flex;
		justify-content: space-between;
		align-items: center;

		> div > button:not(:last-of-type) {
			margin-right: 10px;
		}
	}
}