﻿@import "variables";
@import "mixins/dashed-underline";
@import "font-awesome";
@import "mixins/ng-animate";
@import "mixins/material-deisgn-icons";
@import "mixins/center-content";

html, body, input, textarea, select, * {
    font-family: $muli;
    font-size: 14px;
}

body {
	overflow-y: scroll;
	background-color: $main-background-color;
    color: $bright-colors_black-text;
}

input[type=text], input[type=password], input[type=number], input[type=search], input[type=email], input[type=url], textarea {
	background: white;
	border: none;
	border-bottom: solid 1px #e4e4e4;
	color: $bright-colors_black-text;
	padding: 8px 12px 10px;
	line-height: 1.3em;
	outline: none;
	-webkit-appearance: none;
	display: initial; // Annars hamnar sök- och kalenderikoner nedanför av någon anledning

	@at-root {
		@-moz-document url-prefix() {
			& {
				padding: 9px 12px 9px;
			}
		}
	}

	&.on-white {
		@include input-on-white;
	}

	&[disabled] {
		background-color: #fafafa;
		color: silver;
	}

	&[readonly] {
		background-color: #fafafa;
		color: $bright-colors_dark-gray-text;

		&:focus {
			border-bottom-color: #e4e4e4;
		}
	}

	&:focus {
		border-bottom-color: #bfe2f8;
	}

	@include placeholder {
		color: #bababa;
		font-weight: 300;
		opacity: 1;
	}

	&:-webkit-autofill {
		background: white !important;
		-webkit-box-shadow: inset 0 0 0px 9999px white;
	}
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
    -webkit-appearance: none;
}

input[type=number] {
	 -moz-appearance:textfield;
}

a, a:visited, a:active {
	@include link;

	&[disabled] {
		pointer-events: none;
		color: silver;
	}

	@mixin link-button {
		color: $bright-colors_dark-gray-text;
		display: inline-block;

		&:hover {
			color: $bright-colors_black-text;
		}

		&[disabled], &[disabled]:before {
			color: silver;
		}

		&.contrast:visited {
			color: white;
		}
	}

	&.link-button, &.button {
		@include link-button;
	}

	&.add, &.create {
		@include mdi-icon(plus-circle-outline, 21px, $vertical-align: -3px);
	}

	&.configure {
		@include mdi-icon(wrench, 18px, $vertical-align: -2px);
	}

	&.more {
		width: 42px;
		padding: 8px 0;
		text-align: center;

		@include mdi-icon(dots-horizontal, 22px, 0);
	}

	&.arrow-link {
		@include mdi-icon(arrow-right, 16px, 6px, -2px, gray, 'after');
	}
}


h1, h2, h3, h4 {
	font-family: $raleway;
	color: #212121;
	margin-bottom: .8em;

	&.thin, &.light {
		font-weight: 500;
	}
}

h1 { 
	font-size: 36px; 
    font-weight: 600;
	text-align: center;
}

h2 { 
	font-size: 21px;
    font-weight: 600;
}

h3 {
	font-size: 17px;
	font-weight: 500;
}

h4 {
	font-size: 15px;
	font-weight: 600;
	color: #232323;
}

p {
	line-height: 1.5;

	&.low-contrast {
		font-weight: 300;
		color: $bright-colors_dark-gray-text;
	}

	&.max-width {
		max-width: 55ch;
	}
}

hr {
    height: 1px;
    background-color: #dedede;
    border: none;
    margin: 50px 0;
}

strong {
	font-weight: 700;
	line-height: 100%;
}

.animate-if, .animate-hide {
	@include animate-show();
}

// Användbar t ex vid popup-on-click, om elementet med popup-direktivet inte alltid är inladdat i DOMen
.hidden {
	display: none;
}

.center-contents {
	@include center-content;
}

.flex-center {
	display: flex;
	gap: 20px;

	&.column {
		flex-direction: column;

		> p {
			text-align: center;
		}

		> .button-glow {
			margin-top: 1em;
		}
	}
	
	align-items: center;
	justify-content: center;
}

.button-glow {
	display: inline-block;
	background: #f0f0f0;
	padding: 23px 52px;
	border-radius: 100px;
}

.patch-info, .patch-alert, .patch-warning, .patch-subtle, .patch-success {
	text-align: center;
	margin: 1.5em 0;

	> :first-child {
		display: inline-block;
		padding: 15px 38px;
		border-radius: 50px;
		line-height: 1.4;

		&:not(a) {
			color: $bright-colors_dark-gray-text;
		}
	}
}

.patch-subtle {
	> :first-child {
		background-color: #f0f0f0;
	}
}

.patch-warning {
	> :first-child {
		background-color: $bright-colors_salmon;
		
		&:not(a) {
			color: $bright-colors_black-text;
		}
	}
}

.patch-success {
	> :first-child {
		background-color: $bright-colors_turquoise;
		
		&:not(a) {
			color: $bright-colors_black-text;
		}

		> .check {
			@include mdi-icon(check-circle, 24px, 8px, -4px, $bright-colors_mint-green);
			display: inline-block;
			margin-right: 5px;
			margin-left: -15px;
		}
	}

	&.subtle > :first-child {
		background-color: #EDF3F1;
		color: #42a089;
	}
}

.patch-info {
	> :first-child {
		background-color: $bright-colors_baby-blue-1;
	}

	&.video {
		> :first-child {
			color: $bright-colors_blue-text-on-whitesmoke;
			@include mdi-icon(play, 24px, 8px, -4px, #5A95BA);
			cursor: pointer;
			padding-left: 30px;

			&:hover { 
				background-color: darken($bright-colors_baby-blue-1, 3%);
			}
		}
	}

	&.clickable > a:hover {
		background-color: darken($bright-colors_baby-blue-1, 4%);
	}
}

.patch-alert {
	> :first-child {
		font-size: 15px;
		font-weight: 600;
		background-color: $bright-colors_red;
		//line-height: 47px; // Vet inte var denna används men den gör att quarantined order blir för hög

		&:not(a) {
			color: white;
		}
	}
}

.no-animate {
   transition: none !important;
}

.no-animate-out {
	&.ng-leave, &.ng-hide-add {
		transition: none !important;
	}
}

.no-animate-in {
	&.ng-enter, &.ng-hide-remove {
		transition: none !important;
	}
}

datepicker {
	display: inline-block;

	&:not(.disabled) > input:not(:focus) {
		cursor: pointer;
	}

	&:hover:after {
		opacity: .7;
	}

	&:after {
		content: '';
		display: inline-block;
		vertical-align: -3px;
		background-image:url('../images/icons/calendar.svg');
		height: 16px;
		width: 14px;
		background-size: 14px 16px;
		margin-left: -23px;
		color: #969696;
		cursor: pointer;
		pointer-events: none; // Så att den släpper igenom klick. Behövs när den inte ligger i ett <label>-element (see Senast-såld-filtret på Alla Artiklar)
		opacity: .5;
	}

	&.disabled:after {
		opacity: .2;
	}
}

.empty.center-contents {
	margin-top: -50px; // Eftersom dessa normalt sett har en paragraf med text ovanför en knapp, och det är knappen som ska centreras
	
	> .low-contrast {
		margin-bottom: 50px;
	}
}

strong.copy {
	background-color: #d4e6f1;
    border-radius: 6px;
	display: inline-flex;
	overflow: hidden;
	color: $bright-colors_black-text;
	box-sizing: border-box;
	cursor: pointer;
    font-weight: 600;
	
	margin: -2px 0; // Kompensera för att inline-flex inte är riktig inline

	&:hover {
		background-color: #a2cae1;
	}

	> span {
		padding: 3px 2px 3px 5px;

		@include mdi-icon(content-copy, 16px, $location: 'after');

		&:after {
			margin: 0;
			padding: 0px 4px 1px 6px;
		}
	}
}

ol.big-numbers {
	list-style-type: decimal;
	padding-left: 20px;
	line-height: 21px;

	> li {
		padding-left: 15px;
		margin: 16px 0;

		&::marker {
			font-weight: 600;
			font-size: 22px;
			line-height: 26px;
			font-family: $raleway;
			color: #5c5c5c;
		}
	}
}