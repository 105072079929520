﻿
@import "../mixins/material-deisgn-icons";
@import "../mixins/multiline-ellipsis";
@import "../variables";

#items-purchase-home-view .page-content {
	position: relative;
	max-width: 1000px;

	> h1 {
		margin-bottom: 60px;
	}

	#new-order {
		position: absolute;
		top: 50px;
	}

	.call-to-actions {
		text-align: center;

		.big-button {
			// Specifik för detta ställe
			margin-left: 7px;
		}

		#outbox > i {
			background: #5babff;
			@include mdi-icon(inbox-arrow-up, 25px, 0, 8px);

			&::before {
				position: absolute;
				left: 6px;
				top: 5px;
			}
		}

		#replenish > i {
			@include mdi-icon(elevation-decline, 25px, 0, 8px);

			&::before {
				position: absolute;
				left: 6px;
				top: 4px;
			}
		}
	}

	.pending-purchases {
		margin: 60px auto;

		> h2 {
			border-bottom: solid 1px $bright-colors_silver-stroke-on-smoke;
			padding-bottom: 17px;
			margin-bottom: 40px;

			.more {
				width: 42px;
				padding: 8px 0;
				padding-left: 8px;
				float: right;
				margin-top: -20px;

				@include mdi-icon(plus, 22px, 0);
			}
		}

		> table {
			> thead > tr > th {
				white-space: nowrap;

				&.right-aligned {
					text-align: right;
				}
			}

			> tbody {
				> tr {

					&:hover > td:last-child > i { color: gray; }

					> td {
						> .copy {
							@include mdi-icon(content-copy);
							margin-left: 5px;
							color: #B0B0AF;

							&:hover {
								color: #707070;
							}
						}

						&:last-child {
							text-align: right;
							position: relative;

							> i {
								color: #d0d0d0;
								@include mdi-icon(dots-horizontal, 25px, 0, -9px);
								position: absolute;
								left: 25px;
								padding: 0 4px;
								height: 25px;
								border-radius: 4px;
								top: 4px;
								text-align: center;

								&:before {
									line-height: 0;
								}

								&:hover {
									color: black;
									background-color: #D2E0E1;
								}
							}
						}
					}
				}
			}
		}

		#no-pending-purchases {
			font-weight: 300;
			color: $bright-colors_dark-gray-text;
			padding-bottom: 40px;
			border-bottom: solid 1px $bright-colors_silver-stroke-on-smoke;
		}

		table#waiting-for-delivery {
			@extend .pending-purchases;
		}

		> button {
			margin-left: 10px;

			&:first-of-type {
				margin-left: 30px;
			}
			
			margin-top: 40px;
		}
	}

	> .history {
		@include mdi-icon(history, 20px, $vertical-align: -3px);
		margin: 0 auto;
		display: block;
		width: 250px;
		text-align: center;
		padding: 5px;
	}
}

#invoiced-purchase-actions {
	@extend .popup-menu;
}