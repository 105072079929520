﻿@import "../mixins/callout-arrow";
@import "../mixins/torn-paper";
@import "../variables";
@import "../mixins/responsive";

#sales-registerreturn-view > .page-content {
	padding: 0;
	padding-bottom: 50px;

	> header {
		$background: #f1f1f1;
		padding: 60px 0 0;

		z-index: 2;
		position: sticky;
		top: -100px;
		box-shadow: 0px 13px 27px 8px #f5f5f5cf;

		&.wizard-visible {
			background: $background;
			> .breadcrumb {
				--breadcrumb-background: #f6f6f6;
				--breadcrumb-hover-background: #fcfcfc;
			}
		}

		> .breadcrumb {
			margin-left: -$left-menu-width;
			margin-top: -50px;

			@include when-left-menu-collapsed {
				margin-left: -$collapsed-left-menu-width;
			}
		}

		> h1 {
			margin-bottom: 60px;
		}

		> .wizard-step-indicator {
			width: 377px;

			&.steps-3 {
				width: 480px;
			}

			margin: 0 auto;
			position: relative;
			top: 10px;

			--background-color: #{$background};
		}
	}

	> section {
		margin: 0 auto;
		padding: 40px 20px 0;

		> form {
			--section-headline-width: 230px;


		}

		&.items-step {
			max-width: 1060px;
			min-width: 900px;
		}

		&.exchange-items {
			max-width: 1100px;
			min-width: 980px;
			padding-top: 90px;

			> form {
				> .low-contrast {
					margin: 1em 0 3em;
				}

				> .table {
					--right-aligned-cell-padding: 35px;
				}

				> table + h2 {
					margin: 3.5em 0 1.5em;
					font-weight: 600;
				}

				> h3 {
					font-weight: 600;
					margin-bottom: 17px;
				}

				> .book-shipment {
					margin-top: 10px;
					transition: padding .2s, background-color .2s, margin-bottom .2s;
					margin-bottom: -60px;

					display: flex;
					align-items: center;

					span {
						white-space: nowrap;
						color: $bright-colors_dark-gray-text;
					}

					&.active {
						background-color: #f0f0f0;
						padding: 25px 30px;
						margin-bottom: 70px;
						margin-top: 15px;
					}

					> :not(:first-child) {
						transition: opacity .2s;
						margin-left: 1.5ch;
					}

					&:not(.active) {
						> :not(:first-child) {
							opacity: 0;
						}
					}

					> datepicker > input {
						width: 110px;
					}

					> .selectize-control {
						width: 240px;
    					display: inline-block;
					}
				}

				> .shipping-cost {
					width: 100%;
					margin-top: -50px;
					border-collapse: separate;
					border-spacing: 0 9px;

					th {
						color: $bright-colors_dark-gray-text;
						text-align: right;
						padding-right: 15px;
					}

					td {
						width: 130px;

						> input {
							width: 100%;
							box-sizing: border-box;
						}

						> label > span {
							color: $bright-colors_black-text;
						}
					}
				}

				.replacement-items {
					margin-bottom: 2.5em;

					tr > :first-child {
						width: 400px;

						.item-name-cover {
							padding-left: 7px;
						}
					}

					.total {
						font-weight: 600;
						font-size: 18px;
					}

					tr > :last-child {
						width: 1px;
					}

					tbody > tr:not(:last-child):hover > td:last-child > .remove-row {
						transition: right .3s .1s, width .3s .1s;
						right: -30px;
						width: 30px;
					}

					tbody > tr:not(:last-child) > td:last-child {
						position: relative;

						> .remove-row {
							@include mdi-icon(trash-can-outline, $font-size: 20px, $margin-right: 0, $color: #696969, $vertical-align: -2px);
							position: absolute;
							top: 0;
							right: 0;
							width: 0;
							height: 36px;
							background: #e8e8e8;
							border-radius: 0 10px 10px 0;
							text-align: center;
							overflow: hidden;
							transition: right .2s, width .2s;
							line-height: 36px;
							cursor: pointer;

							&:hover {
								background: #e0e0e0;
							}

							&:hover:before {
								color: #444;
							}
						}
					}

					.qty, .unit-price {
						width: 80px;
					}
				}
			}
		}

		&.shipping-step {
			width: 960px;
		}

		&.refund {
			width: 690px;
			padding-top: 80px;
			text-align: center;

			h3 {
				font-weight: 600;
				margin-bottom: 1.1em;
			}

			> .diff-calc-wrapper {
				@include torn-paper(50, 7.4px);
			}

			> .diff-calc-wrapper > .diff-calc {
				background-color: #fbfbfb;
				padding: 37px 0;
				width: 386px;
				box-sizing: border-box;
				margin: 0 auto 60px;

				table {
					margin: 0 auto;
					max-width: 298px;
					min-width: 280px;

					th, td {
						text-align: right;
						padding: 4px;
						line-height: 1.3;
						vertical-align: middle;
					}

					th {
						padding-right: 15px;
						padding-left: 11px;
						color: $bright-colors_dark-gray-text;
					}

					> tbody {
						td {
							padding-right: 20px;
							color: $bright-colors_black-text;
							font-weight: 600;
						}

						> tr:last-of-type {
							> td, th {
								padding-bottom: 20px;
							}
						}
					}

					> tfoot {
						border-top: solid 2px #909090;

						th, td {
							padding-top: 16px;
						}

						td {
							font-weight: 700;
							font-size: 16px;
							padding-right: 5px;
	
							> span {
								border-radius: 20px;
								background-color: $bright-colors_baby-blue-1;
								padding: 8px 15px 10px;
								display: inline-block;

								&.negative {
									background-color: $bright-colors_salmon;
								}
							}
						}
					}
				}
			}

			.low-contrast {
				width: 40ch;
				margin: 0 auto;
			}
		}

		&.customer-message {
			width: 510px;
			transition: width .2s;
			
			&.expanded {
				width: 710px;
			}
			
			> form > p {
				margin: 20px 0 40px;
				max-width: 60ch;
			}

			.compose-customer-email {
				background: #f0f0f0;
				padding: 20px;
				margin-top: 30px;
		
				.email-editor > .part:last-child {
					// Body + footer
					height: 250px;
				}
			}

			.button-row {
				margin-top: 40px;
			}
		}

		&.done {
			> div {
				> i {
					@include mdi-icon(check-circle-outline, 120px, 0, 0, #d2e4ef);
					margin-bottom: 20px;
					height: 132px;
				}

				> h1 {
					font-size: 24px;
				}
			}
		}

		.occasion {
			max-width: 800px;
		}

		.button-row,
		> form > .button-row {
			text-align: right;
			padding: 0;

			> button {
				margin-right: 0;
				margin-left: 14px;
			}
		}
	}

	#items {
		margin: 50px 0 60px;

		> h3 {
			margin-bottom: 1.2em;
		}
	}

	#shipping-refund {
		.patch-info {
			text-align: left;
		}
	}

	#return-shipping-cost {
		#return-shipping-cost-amount {
			margin-top: 2em;
		}

		h4 {
			font-weight: 400;
			margin-bottom: 2em;
			margin-top: 3em;
			font-family: $muli;
			font-size: 14px;
		}

		.radio-line > span {
			color: $bright-colors_dark-gray-text;
		}

		> div > .form-field {
			background: #f0f0f0;
			border-radius: 15px;
			padding: 20px 23px;
			max-width: 570px;
			box-sizing: border-box;

			> input {
				width: 120px;
			}
		}
	}

	.additional-costs > .fields-container {
		justify-content: flex-start;
		height: 106px;

		.form-field {
			max-width: 180px;

			> input {
				width: 100px;
			}
		}
	}

	table.items {
		margin: 0 20px;
		width: calc(100% - 40px);
		transition: none;

		tr.unreturnable > td, tr.unreturnable > td > label {
			color: #a9a9a9;
		}

		.quantity-shipped { width: 50px; }
		.quantity-returned { width: 60px; }
		.cause { width: 155px; }
		.internal-handling { width: 160px; }
		.settlement { width: 150px; }

		.selectize-input.full {
			text-overflow: ellipsis;
		}

		tbody > tr.exchange-item-row:last-child > td {
			border-bottom: none;
		}
	}
}

.selectize-dropdown.register-return.single {
	margin-top: 1px;

	 > .selectize-dropdown-content {
		white-space: normal;

		.option {
			white-space: normal;
		}
	}
}

.popup-overlay.returned-item-exchange {
	background: rgba(black, .04);
}