﻿@import '../mixins/_gauge.scss';
@import '../variables.scss';
@import "../mixins/material-deisgn-icons";

#items-stock-home-view .page-content {
	max-width: 1100px;

	> .buttons {
		text-align: right;
	}

	.stock-transfer {
		background: #fcfcfc;
		padding: 32px 30px 35px;
		margin: 50px 0;

		> h3 {
			margin-bottom: 30px;

			> i {
				@include mdi-icon(truck-delivery, $color: white, $font-size: 26px, $margin-right: 0);
				background: $bright-colors_vibrant-blue;
				padding: 8px;
				border-radius: 40px;
				display: inline-block;
				margin-right: 8px;
				vertical-align: -2px;
			}
		}
	}

	> hr {
		margin-top: 0;
	}

	> .metrics {
		text-align: center;

		> gauge {
			@include gauge(160px, $background-color: #dfdfdf, $value-text-size: 150%);
		}
	}

	> .tiles > li > span {
		font-weight: 300;
		color: $bright-colors_dark-gray-text;
	}

	#cogs-popup > .popup-appearance {
		> h2 {
			margin-bottom: 3rem;
		}

		width: 450px;

		.dates {
			display: flex;
			gap: 27px;
			margin: 2em 0;
		}

		> .button-row > .contrast {
			padding-top: 10.5px;

			&:before{
				font-size: 18px;
				vertical-align: -2px;
			}
		}
	}

	#inventory-value-popup > .popup-appearance {
		> h2 {
			margin-bottom: 3rem;
		}

		width: 450px;

		.difference {
			padding: 0;
			border-radius: 4px;
			margin: 2.5em 0px 4em;
			transition: padding .25s ease-in-out;

			&.expanded {
				background: rgba(238, 242, 244, 0.55);
				padding: 24px;

				> .switch > span {
					color: $bright-colors_black-text
				}
			}

			> p {
				margin: 2em 0;
				color: $bright-colors_dark-gray-text;
				max-width: 35ch;
			}

			> .form-field {
				margin-bottom: 0;
			}

			input {
				background: white;
			}
		}

		> .button-row > .contrast {
			padding-top: 10.5px;

			&:before{
				font-size: 18px;
				vertical-align: -2px;
			}
		}
	}
}