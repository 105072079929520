﻿@mixin icon-tag-multiple() {
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI4LjEwMSAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4gIDxwYXRoIGQ9Ik01LjUsOUExLjUsMS41IDAgMCwwIDcsNy41QTEuNSwxLjUgMCAwLDAgNS41LDZBMS41LDEuNSAwIDAsMCA0LDcuNUExLjUsMS41IDAgMCwwIDUuNSw5TTE3LjQxLDExLjU4QzE3Ljc3LDExLjk0IDE4LDEyLjQ0IDE4LDEzQzE4LDEzLjU1IDE3Ljc4LDE0LjA1IDE3LjQxLDE0LjQxTDEyLjQxLDE5LjQxQzEyLjA1LDE5Ljc3IDExLjU1LDIwIDExLDIwQzEwLjQ1LDIwIDkuOTUsMTkuNzggOS41OCwxOS40MUwyLjU5LDEyLjQyQzIuMjIsMTIuMDUgMiwxMS41NSAyLDExVjZDMiw0Ljg5IDIuODksNCA0LDRIOUM5LjU1LDQgMTAuMDUsNC4yMiAxMC40MSw0LjU4TDE3LjQxLDExLjU4TTEzLjU0LDUuNzFMMTQuNTQsNC43MUwyMS40MSwxMS41OEMyMS43OCwxMS45NCAyMiwxMi40NSAyMiwxM0MyMiwxMy41NSAyMS43OCwxNC4wNSAyMS40MiwxNC40MUwxNi4wNCwxOS43OUwxNS4wNCwxOC43OUwyMC43NSwxM0wxMy41NCw1LjcxWiIgc3R5bGU9ImZpbGw6IHJnYigyNTUsIDI1NSwgMjU1KTsiLz4gIDxwYXRoIGQ9Ik0gMTcuNTIxIDUuNzEgTCAxOC41MjEgNC43MSBMIDI1LjM5MSAxMS41OCBDIDI1Ljc2MSAxMS45NCAyNS45ODEgMTIuNDUgMjUuOTgxIDEzIEMgMjUuOTgxIDEzLjU1IDI1Ljc2MSAxNC4wNSAyNS40MDEgMTQuNDEgTCAyMC4wMjEgMTkuNzkgTCAxOS4wMjEgMTguNzkgTCAyNC43MzEgMTMgTCAxNy41MjEgNS43MSBaIiBzdHlsZT0iZmlsbDogcmdiKDI1NSwgMjU1LCAyNTUpOyIvPjwvc3ZnPg==);
	display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
}

@mixin hand() {
	background-image: url('../../images/icons/hand.svg');
	display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
}

@mixin hand-gray() {
	background-image: url('../../images/icons/hand_gray.svg');
	display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
}

@mixin truck-phone {
	background-image: url('../../images/icons/truck-phone.svg');
	display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
}

@mixin icon-reply {
	background-image: url('../../images/icons/reply.svg');
	display: inline-block;
    background-repeat: no-repeat;
    background-position: center center;
}

@mixin list-bulleted {
	background-image: url('../../images/icons/list-bulleted.svg');
	display: inline-block;
    background-repeat: no-repeat;
	background-position: center center;
	background-size: 22px 22px;
}

@mixin table-checkable {
	background-image: url('../../images/icons/table_checkable.svg');
	display: inline-block;
    background-repeat: no-repeat;
	background-position: center center;
	background-size: 18px;
	transform: translateY(.5px);
}