﻿@import "../variables";

.switch {
    --track-height: 12px;
    --track-width: 32px;
    --knob-size: 18px;
    --track-top: 4px;
    --knob-top: 1px;
    --label-spacing: 41px;

    &.inline {
        --track-height: 10px;
        --track-width: 29px;
        --track-top: 5px;
        --knob-size: 16px;
        --knob-top: 2px;
        --label-spacing: 38px;
    }

    break-inside: avoid;

    > input {
        display: none;
    }

    > :checked ~ label, > :checked ~ span {
        &:before {
            background: $bright-colors_vibrant-blue;
        }

        &:after {
            left: calc(var(--track-width) - var(--knob-size) + 1px);
            border-color: #62acd6;
        }
    }

	&:hover > :not(:checked):not(:disabled) ~ label,
    &:hover > :not(:checked):not(:disabled) ~ span {
		&:before {
			transition: background-color 0s ease-out;
			background: #a8a8a8;
		}

        &:after {
            transition: left .2s ease-out, border-color 0s ease-out;
            border-color: darken(#adadad, 6%);
        }
	}

	&:hover > :checked:not(:disabled) ~ label,
    &:hover > :checked:not(:disabled) ~ span {
		&:before {
			transition: background-color 0s ease-out;
			background: darken($bright-colors_vibrant-blue, 7%);
        }
        
        &:after {
            transition: left .2s ease-out, border-color 0s ease-out;
            border-color: darken($bright-colors_vibrant-blue, 9%);
        }
	}

	> :disabled ~ label, > :disabled ~ span {
        color: #b6b6b6;
        
        &:before {
            background: #e3e3e3;
        }

        &:after {
            background: #f5f5f5;
            box-shadow: 1px 2px 2px rgba(black, .09);
            border-color: #dadada;
        }
	}

    > label, > span {
        position: relative;
        padding-left: var(--label-spacing);
        height: 19px;
        display: inline-block;
        line-height: 18px;
        -moz-user-select: none;
        user-select: none;
        color: $bright-colors_dark-gray-text;

        &:before {
            content: '';
            position: absolute;
            display: inline-block;
            width: var(--track-width);
            height: var(--track-height);
            background: #bbbbbb;
            border-radius: var(--track-height);
            top: var(--track-top);
            left: 0;
            transition: background-color .2s ease-out;
        }

        &:after {
            box-sizing: border-box;;
            position: absolute;
            content: '';
            height: var(--knob-size);
            width: var(--knob-size);
            border-radius: var(--knob-size);
            left: -1px;
            top: var(--knob-top);
            background-color: white;
            transition: left .2s ease-out, border-color .2s ease-out;
            box-shadow: 1px 2px 2px rgba(black,.13);
            border: solid 2px #adadad
        }
    }
}