﻿@mixin mdi-icon($icon, $font-size: 14px, $margin-right: 7px, $vertical-align: 0px, $color: '', $location: 'before') {
    &:#{$location} {
        content: mdi($icon);

        display: inline-block;
        font: normal normal normal #{$mdi-font-size-base}/1 '#{$mdi-font-name}';
        font-size: $font-size;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        transform: translate(0, 0);

		vertical-align: $vertical-align;

		@if $color != '' {
			color: $color;
		}

        @if $location == 'before' {
            margin-right: $margin-right;
        }
        @else {
            margin-left: $margin-right;
        }
    }
}