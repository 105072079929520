﻿@import "../font-awesome";
@import "../variables";


// I tabell

.table .selectize-control.single.item-auto-complete {
    $input-padding: 8px 10px; // Ändrar eftersom texten hamnar långt ned när man väljer utbytesartikel på sidan Ny Retur
	position: absolute;
	top: 0 !important;

	> .selectize-input {
		padding: $input-padding;
		border-radius: 0;
		box-shadow: none !important;
		
		&.dropdown-active {
			z-index: 20;
			//border: solid 1px #cbcbcb !important; Kommenterar ut denna så att purchase form ser bra ut. Om det blir fult nån annanstans, tänk om
		}

		border-top: none;
		border-left: none;
		line-height: 1.4;
	}

	> .selectize-dropdown {
		border: solid 1px #bababa;
		margin-top: -1px; // Egentligen ärver den denna från selectize, men skrivs över i selectorn nedan
	}
}

// Fristående och i tabell

.selectize-control.single.item-auto-complete {
	$input-padding: 5px;
	position: relative;
	background-color: white;

	&.loading {
		@include fa-icon($fa-var-spinner, after, 12px, true);
		&:after {
			@extend .fa-pulse;
			position: absolute;
			top: 13px;
			right: 9px;
			color: $bright-colors_dark-gray-text;
		}
	}

	&:not(.has-item):not(.loading) {
		@include fa-icon($fa-var-search, after, 14px, true);
		&:after {
			position: absolute;
			top: 11px;
			right: 12px;
			color: darkgrey;
		}
	}

	> .selectize-input {
		background: none;
		cursor: text;

		&::after {
			right: 10px;
			top: $input-padding + 7px;
			color: rgba(0,0,0,0.4);
			border: none;
		}
	}
}

.selectize-dropdown.single.item-auto-complete {
	min-width: 600px !important;
	z-index: 20;
	box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.06), 1px 1px 1px rgba(1, 1, 0, 0.06);
	border: none;
	border-radius: 0;
	font-size: 14px;
	margin-top: 1px; // Ser bra ut på purchase form

	> .selectize-dropdown-content {
		> .result-item {
			padding: 10px;

			> .path {
				color: gray;
				float: right;
			}

			> .supplier {
				color: gray;
				float: right;
				margin-right: 20px;
			}

			&.active {
				> .path, > .supplier {
					color: white;
				}
			}
		}
	}
}