﻿@import "../variables";
@import "../mixins/impromptu-animation";

.webshop-acidtest-output {
	font-family: inconsolata;
	background: #465160;
	display: flex;
	color: #e2e8ec;
	flex-direction: column;
	justify-content: flex-end;
	padding: 20px;

	.error {
		color: #ff8a84;
		font-weight: 700;
		white-space: pre-line;
	}

	.status {
		color: #929ea7;
		margin-top: 1em;

		&.current:after {
			content: '';
			display: inline-block;
			//background: #aebbc5;
			width: 12px;
			height: 12px;
			border-top: solid 2px;
			border-left: solid 2px;
			border-radius: 18px;
			margin-left: 2px;
			vertical-align: -1px;

			@include impromptu-animation(1.2s, $repeat: infinite, $timing-function: linear) {
				0% { transform: rotate(0deg) }
				100% { transform: rotate(360deg) }
			}
		}
	}

	.warning {
		color: #d4ba3a;
		font-weight: 700;
	}

	.success {
		margin-top: 1em;
		color: $dark-colors_mint-green-text;
		font-weight: 700;
	}
}