@import '../mixins/impromptu-animation';
@import '../mixins/animations';

#dashboard-view > .page-content {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(530px, 1fr));
	max-width: 1400px;
	min-width: 780px;
	margin: 0 auto;
	gap: 35px;

	> h1 {
		grid-column: 1 / -1;
	}

	> div {
		background-color: #fcfcfc;
		padding: 30px;

		transition: background .1s;

		&.clickable:hover {
			background: white;
			cursor: pointer;
		}

		> h3 {
			font-size: 18px;
			font-weight: 600;

			> svg {
				margin-right: 13px;
				vertical-align: -12px;
				width: 38px;
				height: 38px;
			}
		}

		> p.low-contrast {
			margin-left: 50px;

			> strong {
				background: $bright-colors_red;
				color: white;
				border-radius: 15px;
				text-align: center;
				display: inline-block;
				height: 19px;
				padding: 2px 6px 0;
				line-height: 17px;
			}
		}

		> .table {
			margin: 2em 0;
		}
	}

	> .low-stock {
		> .table.fadeout {
			mask-image: linear-gradient(to bottom,#000 50%,transparent 100%);
			-webkit-mask-image: linear-gradient(to bottom,#000 50%,transparent 100%);
		}
	}

	#onboarding {
		grid-column: 1 / -1;
		background-color: #fcfcfc;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 58px 0;

		@include impromptu-animation(.8s) {
			0% { opacity: .1; }
			100% { opacity: 1; }
		}

		> h2 {
			margin-bottom: 2.1em;

			@include impromptu-animation(.4s, 1s) {
				0% { opacity: 0; }
				100% { opacity: 1; }
			}
		}

		> #map {
			position: relative;
			height: 120px;
			white-space: nowrap;

			@include impromptu-animation(.4s, 1.2s) {
				0% { opacity: 0; }
				100% { opacity: 1; }
			}

			> .line {
				display: inline-block;
				vertical-align: top;
				position: relative;
				top: 37px;
				pointer-events: none;
			}
		
			> span:not(.label) {
				margin: 0 -18px;
				cursor: pointer;
				z-index: 2;
			}
		
			> .completed {
				margin: 0 -6px 0 -5px;
				vertical-align: top;
				margin-top: 8px;
			}
		
			.label {
				font-family: Muli;
				position: absolute;
				top: 85px;
				font-weight: 600;
				pointer-events: none;
				text-align: center;
				width: 90px;
				color: #333333;
				z-index: 0;
			}
		
			.label.completedLabel {
				color: #A3A3A3;
			}
		
			.label#items-text { left: 7px; }
			.label#webshop-text { left: 149px; }
			.label#payment-text { left: 292px; }
			.label#transport-text { left: 291px; }
			.label#accounting-text { left: 433px; }

			&.completed {
				> .line, >.label {
					transition: opacity .2s .7s;
					opacity: 0;
				}

				> .completed {
					transition: margin .5s 1s;
					transition-timing-function: ease-out;
					margin: 0 -94px 0 -59px;
				}
			}
		}
	}

	#inspiration {
		@include animate-fade-in(.5s, .5s);
		animation-fill-mode: both;

		grid-column: 1 / -1;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 5em 0 3em;

		> div {
			display: flex;
			flex-direction: column;
			align-items: center;

			> img {
				width: 300px;
			}

			> h3 {
				white-space: pre-wrap;
				font-style: italic;
				margin-top: 1.5em;
				display: flex;
				flex-direction: column;
				align-items: center;
				line-height: 140%;
				font-size: 16px;
				font-weight: 500;
				max-width: 370px;
				text-align: center;
				max-width: 320px;
				letter-spacing: 0.6px;

				&.long {
					font-weight: 400;
					font-size: 14px;
				}

				&:before {
					content: '';
					width: 154px;
					height: 4px;
					display: block;
					background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 154 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M69 2.2c0 .5-.3.8-.8.8H0v-.4c4.7-1.4 61-1.2 68.2-1.1.5 0 .8.3.8.7ZM85 2.2c0 .5.3.8.8.8H154v-.4c-4.7-1.4-61-1.2-68.2-1.1-.5 0-.8.3-.8.7Z' fill='%23606060'/%3E%3Ccircle cx='77' cy='2' r='2' fill='%23606060'/%3E%3C/svg%3E");
					background-repeat: no-repeat;
					padding-bottom: 2.2em;
				}

				// &:after {
				// 	content: '';
				// 	width: 154px;
				// 	height: 4px;
				// 	display: block;
				// 	background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 154 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M69 2.2c0 .5-.3.8-.8.8H0v-.4c4.7-1.4 61-1.2 68.2-1.1.5 0 .8.3.8.7ZM85 2.2c0 .5.3.8.8.8H154v-.4c-4.7-1.4-61-1.2-68.2-1.1-.5 0-.8.3-.8.7Z' fill='%23606060'/%3E%3Ccircle cx='77' cy='2' r='2' fill='%23606060'/%3E%3C/svg%3E");
				// 	transform: scaleY(-1);
				// 	background-repeat: no-repeat;
				// 	padding-top: 2em;
				// }
			}
		}
	}
}