﻿@import '../mixins/_animations.scss';
@import "../variables";
@import "../mixins/material-deisgn-icons";

#sales-ongoingorder-registerdelivery-view .page-content > div[ui-view] {
	> form {
		> h2 {
			text-align: center;
			margin-top: 40px;
			margin-bottom: 30px;
		}

		max-width: 1000px;
		margin: 70px auto;

		section#occasion {
			width: 780px;
		}

		> #booked-shipments {
			> div {
				> .low-contrast {
					width: 40ch;
					margin-bottom: 1.5em;
				}

				.label-radio {
					display: table;
					margin: 10px 0;

					> span {
						padding: 5px 2ch;
						display: inline-block;
						width: 40ch;

						> strong {
							margin-left: -2px;
						}
	
						> p {
							margin-left: 27px;
							margin-top: -8px;
							margin-bottom: 9px;
						}
					}
				}
			}
		}

		table {
			margin-bottom: 40px;

			input {
				width: 60px;
			}

			tr > :last-child {
				width: 1px;
				white-space: nowrap;
			}
		}

		.button-row {
			margin-top: 50px;
			padding-left: 0;
		}

		.customer-notification {
			background: #f0f0f0;
			padding: 20px;
			margin: 30px 0;

			label.radio {
				margin-left: 10px;
			}
		}

		.under-delivery-confirmation {
			display: flex;
			column-gap: 20px;
			background: #fafafa;
			padding: 33px;
			box-shadow: 0 0 40px rgba(black, 0.04), 0 0 11px rgba(black, 0.03), 0 0 0 #{'max(100vw, 100vh)'} rgba(black, 0.01);
			z-index: 10;
			position: relative;
			@include animate-slide-up;

			> p {
				flex: 1;
				padding: 0 0 5px;
				color: $bright-colors_dark-gray-text;

				> strong {
					color: $bright-colors_black-text;
					display: block;
					margin-top: 1em;
					font-weight: 600;
				}
			}

			> div {
				display: flex;
				flex-direction: row;
				align-items: flex-end;
				column-gap: 15px;
			}
		}
	}
}