﻿@mixin impromptu-animation($duration, $delay: 0s, $repeat: 1, $timing-function: ease, $fill-mode: both) {
	$keyframes-name: unique-id();

	@at-root {
		@keyframes #{$keyframes-name} {
			@content;
		}
	}

	animation: $keyframes-name $duration $delay;
	animation-timing-function: $timing-function;
	animation-iteration-count: $repeat;
	animation-fill-mode: $fill-mode;
}