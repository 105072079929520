@import '../mixins/material-deisgn-icons';

all-items-list {
	max-width: 1300px;
	min-width: 800px;
    margin: 0 auto;
    display: block;

	> h1 {
		margin-bottom: 1.5em;
	}

	#table-headline {
		scroll-margin-top: 65px;
		
		.filter {
			user-select: none;
			display: flex;
			align-items: baseline;
			justify-content: flex-end;
			column-gap: 13px;
			border-bottom: solid 1px $bright-colors_silver-stroke-on-smoke;
			padding-bottom: 16px;
			margin-bottom: 40px;

			> h3 {
				color: $bright-colors_dark-gray-text;
				font-family: $muli;
				font-size: 14px;
				font-weight: 600;
				margin: 0;
				margin-right: 12px;

				img {
					width: 18px;
					height: 18px;
					vertical-align: sub;
					margin-right: 10px;
					opacity: .6;
				}
			}

			> button {
				font-weight: 400;
				height: 40px;
				padding: 2px 9px 0 15px;
				border-color: transparent;
				
				@include mdi-icon(chevron-down, 18px, $location: 'after', $margin-right: 4px, $vertical-align: -3px);

				&.active {
					background-color: #8AC2DF;
					color: white;

					&.open {
						background-color: #60a2c4;
						color: white;
					}

					&:hover {
						background-color: #6ea9c7;
					}

					&:active, &:focus {
						background-color: #5c8ea7;
						color: white;
					}
				}

				&.open, &:focus {
					background-color: #C7C7C7;
					color: $bright-colors_black-text;
				}
			}

			> .search-box {
				margin-left: 9px;
				position: relative;

				> input {
					padding-right: 30px;
					transition: width .3s;
					width: 100px;

					&.ng-not-empty, &:focus {
						width: 160px;
					}
				}

				@include mdi-icon(magnify, 18px, 0, middle, #606060);

				&:before {
					position: absolute;
					right: 9px;
					top: 9px;
				}
			}
		}
	}

	.multi-options {
		margin-top: -10px;
	}

	.table {
		scroll-margin-top: 140px; // Så att när vi kör scrollIntoView vid paginering så hamnar vi lite ovanför så att headern syns
	}

	.filter-form {
		padding: 10px;
		display: grid;
		gap: 16px;
		box-sizing: border-box;

		> label {
			grid-column: 1;
			grid-row: 1;
			line-height: 140%;
			color: $bright-colors_dark-gray-text;
			max-width: 27ch;
		}

		> .inputs {
			grid-column: 1;
			grid-row: 2;
		}

		> .buttons {
			grid-column: 2;
			grid-row: 2;
			text-align: right;

			> button:last-child {
				margin-left: 10px;
			}
		}

		&[name='stockFilterForm'] {
			width: 465px;

			> .inputs {
				> label {
					> input {
						width: 4ch;
						margin: 0 4px;
					}
				}
			}
		}

		&[name='supplierFilterForm'] {
			width: 545px;
			align-items: end;

			> .inputs {
				width: 290px;
				grid-row: 1;

				> .form-field {
					margin: 0;
				}
			}

			> .buttons {
				grid-row: 1;
			}
		}

		&[name='lastSoldFilterForm'] {
			width: 546px;
			align-items: end;

			> .inputs {
				display: flex;
				gap: 20px;
				margin-top: 8px;

				> .form-field {
					margin: 0;
					
					input[type="text"] {
						width: 13ch;
					}
				}
			}
		}
	}
}