﻿@import "../variables";
@import "responsive";

@mixin center-content($position: absolute, $backdrop-top: $top-header-height) {
	position: $position;
	top: $backdrop-top;
	bottom: 0;
	left: $left-menu-width;
	@include when-left-menu-collapsed {
		left: $collapsed-left-menu-width;
	}
	right: 0;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

@mixin center-content-in-main-area-when-fits($element-width, $backdrop-top: $top-header-height) {
	@include center-content(fixed, $backdrop-top);
	left: 0;

	& > * {
		@at-root html:not(.left-menu-collapsed) & {
			@media screen and (min-width: ($left-menu-width + $element-width + 40px)) {
				margin-left: $left-menu-width;
			}
		}
	
		@at-root html.left-menu-collapsed & {
			@media screen and (max-width: ($collapsed-left-menu-width + $element-width + 40px)) {
				margin-left: $collapsed-left-menu-width * -1;
			}
		}
	}
}