﻿@import "../mixins/multiline-ellipsis";
@import "../variables";
@import "../mixins/material-deisgn-icons";
@import "../mixins/webkitscrollbar";
@import "../mixins/responsive";
@import "../mixins/animations";

#items-purchase-archive-details-view .page-content > div,
#items-purchase-details-view .page-content {
	max-width: 1200px;
	min-width: 1085px;
	margin: 0 auto;

	> h1 {
		margin-bottom: 70px;
		margin-left: -30px;
	}

	.loading .invoice-view {
		opacity: .2;
	}

	.invoice-view-container-backdrop {
		@include center-content-in-main-area-when-fits(980px);

		.items {
			max-height: 349px;
			overflow-y: auto;
			@include webkit-scrollbar;
		}
	}

	> article {
		min-width: 640px;
		overflow: hidden; // Så att hela elementet påverkas av float (loggen) och inte bara delar av det

		> menu {
			margin: 50px 0;

			button {
				margin-right: 10px;
			}

			.more {
				width: 42px;

				@include mdi-icon(dots-horizontal, 22px, 0, -3px);
			}

			.patch-info {
				margin-right: 16px;
				position: relative;
				top: -2px;
			}
		}

		> section#items {
			margin-top: 58px;

			h2 {
				margin-bottom: 27px;
			}

			> table {
				> tfoot {
					> tr {
						> td {
							text-align: right;
							padding-top: 30px;

							span {
								display: block;
								margin-bottom: 4px;
								color: $bright-colors_dark-gray-text;
							}

							output {

							}
						}

						&.total > td {
							padding-top: 18px;

							output {
								font-weight: 600;
								font-size: 22px;
								letter-spacing: -.3px;
							}
						}
					}
				}
			}
		}
	}
	
	> aside {
		float: right;
		width: 360px;
		margin-left: 80px;
		margin-bottom: 30px;

		> .patch-info {
			margin-left: -5px;

			.write {
				padding: 15px 29px 14px 26px;
				margin-bottom: 40px;
				color: #557066;
				border-radius: 18px;
				background: #e1efeb;

				&:hover {
					background: darken(#e1efeb, 4%);
					color: darken(#557066, 4%);
				}
	
				> svg {
					vertical-align: -5px;
					margin-right: 6px;
					color: #779c8f;
				}
			}
		}
	}

	.event-log > li {
		&.Saved {
			@include mdi-icon(email-open, 22px);
		}

		&.Sent {
			@include contrastedEventLogEntry;
			@include mdi-icon(send, 22px);
		}

		&.Resent {
			@include mdi-icon(send, 22px);
		}

		&.SupplierMessageReceived {
			@include mdi-icon(email, 22px);
			@include contrastedEventLogEntry;
		}

		&.SupplierMessageSent {
			@include mdi-icon(send, 22px);
			@include contrastedEventLogEntry;
		}

		&.PricesAdjusted {
			@include mdi-icon(pencil, 20px);
		}

		&.OrderConfirmedBySupplier {
			@include contrastedEventLogEntry;
			@include mdi-icon(check, 22px);
		}

		&.GoodsReceived {
			@include contrastedEventLogEntry;
			@include mdi-icon(truck, 22px);
		}

		&.SupplierInvoiceRegistered {
			&:before {
				background-position: center 6px;
				content: ' ';
				background-image: url('/images/icons/invoice.svg');
				background-repeat: no-repeat;
				background-size: 26px;
			}
		}

		&.PurchasePaid {
			@include contrastedEventLogEntry;
			@include mdi-icon(coin, 24px);
		}
	}

	.confirm-order-dialog {
		> h2 {
			margin-bottom: 40px;
		}

		> .friendly {
			@include mdi-icon(alert-decagram, 24px, $color: #8ecdf5);
			
			&:before {
				margin-right: 6px;
				float: left;
				margin-bottom: 30px;
				margin-top: -5px;
			}
		}

		> .warning {
			@include mdi-icon(alert, 24px, $color: #ff8a84);
			
			&:before {
				margin-right: 10px;
				float: left;
				margin-bottom: 50px;
				margin-top: -5px;
			}

			> p {
				font-weight: 300;
				margin-top: 15px;
			}
		}

		> .button-row {
			margin-top: 40px;
		}
	}
}

.adjust-prices-dialog {
	width: 800px;
	display: flex;
    flex-direction: column;

	&.send-new-mail {
		@include animate-fade-in();
	}

	> .form-field {
		margin: 30px 0 0;
		display: inline-block;
		align-self: flex-start;
	}

	.table-wrapper {
		overflow-y: auto;
		@include webkit-scrollbar;

		.table {
			margin: 40px 0 20px;

			.call-to-input {
				background: #{'hsl(201 47% 97% / 1)'};

				> span {
					padding: 0 7px;
				}
			}

			th.call-to-input {
				width: 86px;
			}

			th:not(.call-to-input) + th.call-to-input {
				width: 66px;
			}

			> thead > tr > th,
			> tbody > tr > td {
				border-color: $bright-colors_silver-stroke-on-white;
			}

			tr > td:not(:last-child).call-to-input {
				padding-right: 1px; // Så att box-shadow syns
			}

			tr.variant > td:first-child {
				padding-left: 40px;
			}

			tr.zero {
				color: silver;
			}

			> tbody > tr:last-child {
				td {
					border: none;
				}

				> td.call-to-input {
					padding: 20px;
					box-shadow: none;

					input {
						box-sizing: border-box;
						width: 100px;
						margin-left: 8px;
						text-align: right;
					}
				}
			}
		}
	}

	.button-row {
		margin-top: 20px;
	}
}