
@import "../mixins/callout-arrow";
@import "../mixins/webkitscrollbar";
@import "../mixins/material-deisgn-icons";
@import "../variables";
@import "../mixins/ng-animate";

.popover {
	z-index: 20;
	@include animate-show(.1s, $fade-out-duration: .2s);

	&[x-placement="bottom-start"] {
		@include callout-arrow(top-left, 13px);
		margin-top: 10px;
	}

	&[x-placement="top-start"] {
		@include callout-arrow(bottom-left, 13px);
		margin-bottom: 10px;
	}

	&[x-placement="top-end"] {
		@include callout-arrow(bottom-right, 13px);
		margin-bottom: 10px;
	}

	&[x-placement="bottom-end"] {
		@include callout-arrow(top-right, 13px);
		margin-top: 10px;

		&:after {
			z-index: 1;
		}
	}

	padding: 20px;
	background-color: #fff;
    box-shadow: 2px 3px 13px rgba(black,.16);
    max-height: calc(100vh - 90px);

	> .popup-menu {
		margin: -15px;
	}
}

.popupized {
	display: none;
}

.popup-appearance {
	background-color: white;
	box-shadow: 2px 3px 13px rgba(black, 0.16);
	position: relative;

	&:not(.no-padding) {
		padding: 40px;
	}

	max-height: calc(100vh - 90px);
    overflow-y: auto;
	@include webkit-scrollbar($thumb-color: rgba(black, .3));

	> h2:not(.light) {
		font-weight: 600;
	}

	> .close {
		@include mdi-icon(close, 20px, 0);

		float: right;
		padding: 5px;
		border-radius: 100%;
		position: absolute;
		top: 17px;
		right: 17px;
		color: gray;

		&:hover {
			background: $bright-colors_baby-blue-1;
			color: #444;
		}

		&:active {
			background: darken($bright-colors_baby-blue-1, 7%);
		}
	}

	.form-field {
        font-size: 14px;
        display: block;
        margin-bottom: 10px;
        color: #353535;
			
		input {
			background: #fafafa;

			&:focus {
				background: #f7fafc;
			}
		}

		.selectize-control > .selectize-input, .selectize-control.single > .selectize-input {
			background: #fafafa;
			&.focus {
				background: #f7fafc;
			}
		}
    }
}

%popup {
	z-index: 20;
	visibility: hidden;
	opacity: 0;

	margin-top: 13px;
	transform-origin: top left;

	&.tether-element-attached-top.tether-element-attached-left {
		@include callout-arrow(top-left, 13px);
	}

	&.tether-element-attached-bottom.tether-element-attached-left {
		@include callout-arrow(bottom-left, 13px);
		margin-top: -13px;
	}

	&.tether-element-attached-top.tether-element-attached-right {
		@include callout-arrow(top-right, 13px);
	}

	&.tether-element-attached-bottom.tether-element-attached-right {
		@include callout-arrow(bottom-right, 13px);
		margin-top: -13px;
	}

	&.tether-element-attached-top.tether-element-attached-left.tether-target-attached-right {
		@include callout-arrow(left-top, 13px);
		margin-left: 13px;
		margin-top: -27px;
	}

	&.no-arrow:before, &.no-arrow:after { display: none; }

	position: absolute;
	top: 0;
	left: -100%;
	transition: opacity 0.2s ease, visibility 0s 0.2s;

	&.visible {
		visibility: visible;
		opacity: 1;
		transition: opacity 0.2s ease;

		left: unset;
	}

	> .popup-content {
		background-color: white;
		box-shadow: 1px 2px 2px rgba(0,0,0,0.1);
		overflow: hidden;
		padding: 10px;

		.form-field {
			color: #353535;
			
			input {
				background: #fafafa;

				&:focus {
					background: #f7fafc;
				}
			}

			.selectize-control > .selectize-input, .selectize-control.single > .selectize-input {
				background: #fafafa;
				&.focus {
					background: #f7fafc;
				}
			}
		}
	}
}

.popup {
	@extend %popup;
}

.popup-overlay {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 19;
	background-color: rgba(0,0,0,0.07);
	transition: opacity ease .2s, visibility 0s .2s;
	visibility: hidden;
	opacity: 0;

	&.visible {
		visibility: visible;
		opacity: 1;
		transition: opacity ease .2s, visibility 0s 0s;
	}
	
	@include animate-show(.1s, $fade-out-duration: .2s);

	> .popup-appearance, > .popup-content {
		transition: opacity ease .1s;
		background-color: white;
		box-shadow: 2px 3px 13px rgba(black, 0.16);
	}

	&.transparent {
		background-color: transparent;
	}
}