﻿#organizationSettings-suppliers-view {
	> .page-content {
		max-width: 1100px;

		.add {
    	    width: 42px;
	        padding: 8px 0;
	        padding-left: 7px;
	        float: right;
	        margin-top: -20px;
            margin-bottom: 20px;
	        @include mdi-icon(plus, 22px, 0);
        }
	}
}