﻿
@import "../variables";
@import "../mixins/responsive";
@import "../mixins/ng-animate";
@import "../mixins/webkitscrollbar";
@import "../mixins/material-deisgn-icons";
@import "../throbbers/throbber-1";

$expanded-search-width: 456px;
$full-search-width: 742px;
$d: .2s;

form#search .search-backdrop {
	position: fixed;
	top: 0;
	left: $left-menu-width;
	bottom: 0;
	right: 0;
	background: rgba(black, .04);
	transition: left $d, background $d, visibility 0s $d, opacity $d;

	visibility: hidden;
	opacity: 0;

	@include when-left-menu-collapsed {
		left: $collapsed-left-menu-width;
	}

	&.visible {
		visibility: visible;
		opacity: 1;
		transition: left $d, background $d, visibility 0s, opacity $d;
	}
}

form#search .search-container {
    position: fixed;
	left: $left-menu-width;
	top: 0;
	border-right: solid 1px #EBEBEB;
	transition: left $d, background $d, width $d, box-shadow $d, min-height .1s $d;
	width: 354px;
	background: transparent;
	box-shadow: none;
	min-height: $top-header-height;
	display: flex;
	flex-direction: column;
	max-height: 100%;

	@include when-page-atmost(900px) {
		width: 280px;
	}

	&.results-visible {
		box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.05), 1px 2px 2px rgba(0, 0, 0, 0.07);
		background: white;
		border-right-color: #d0d0d0;

		&:not(.full) {
			width: $expanded-search-width;
		}
	}

	&.full {
		box-shadow: 1px 1px 6px rgba(black, .08);
		border-right-color: #ebebeb;
		width: $full-search-width;
		min-height: 100%;
		background: white;

		// Transition out:
		transition: left $d, background $d, width $d, box-shadow $d;

		.close {
			opacity: 1;
			visibility: visible;
			transition: opacity $d, background-color .2s;
		}

		.search-box {
			margin: 25px 30px 15px;
			flex-shrink: 0;

			> svg {
				left: 6px;
			}

			> input {
				box-shadow: 0 1px 0 0px rgb(191, 226, 248);
				padding-left: 32px;
				height: 40px;
			}

			> input:hover {
				background: white;
			}
		}

		.results {
			padding: 10px 30px;

			> nav {
				width: 226px;
				max-height: none;
			}
		}
	}

	@include when-left-menu-collapsed {
		left: $collapsed-left-menu-width;
	}

	.close {
		@include mdi-icon(close, 18px, 0, 1px);
		display: block;
		width: 40px;
		height: 40px;
		position: absolute;
		top: 3px;
		right: 3px;
		vertical-align: middle;
		line-height: 46px;
		text-align: center;
		opacity: 0;
		cursor: pointer;
		visibility: hidden;
		transition: opacity $d, visibility 0s $d;
		border-radius: 100%;
		background-color: transparent;
		border: solid 4px transparent;
		z-index: 1;

		&:hover {
			background-color: $bright-colors_baby-blue-2;	
			border-color: white;
		}
	}

	.search-box {
		transition: margin $d;
		height: $top-header-height;
		margin-bottom: 1px;
		position: relative;

		&:hover > span {
			color: #849298;
		}

		&:hover > svg {
			color: #849298;
		}

		> svg {
			pointer-events: none;
			position: relative;
			left: 30px;
			width: 16px;
			height: 16px;
			top: 5px;
			color: #999;

			transition: left $d;
		}

		> input {
			color: black;
			border: none;
			padding: 0 13px 0 55px;
			font-size: 14px;
			box-sizing: border-box;
			width: 100%;
			vertical-align: middle;
			height: $top-header-height;
			margin-left: -16px;
			box-shadow: none;

			overflow: hidden;
			text-overflow: ellipsis;

			transition: height $d, padding-left $d, background-color .1s;

			@include placeholder {
				font-weight: 300;
				opacity: 1;
			}

			&:hover {
				&:not(:focus) {
					background-color: #f7fbfd;
				}

				box-shadow: 0 1px 0 0px desaturate(darken(#f7fbfd, 7%), 15%);

				@include placeholder {
					color: #97abb5;
				}
			}

			&:focus {
				box-shadow: 0 1px 0 0px rgb(191, 226, 248);
			}
		}

		> .throbber {
			position: absolute;
			right: 6px;
			top: 18px;
			transform: scale(.7);
			@include throbber-1(8px, 3px);

		}
	}

	.results {
		display: flex;
		flex: 1;
		overflow-y: auto;
		@include webkit-scrollbar($on-hover-only: true);

		> nav {
			width: 0;
			max-height: 0;
			font-weight: bold;
			border-right: solid 1px #e5e5e5;
			overflow: hidden;
			transition: width $d, max-height 0 $d;
			position: sticky;
			top: 0;

			> a {
				height: 47px;
				line-height: 47px;
				display: block;
				border-bottom: solid 1px #e8e8e8;
				color: #7a7a7a;
				padding-left: 15px;
				border-left: solid 4px transparent;
				transition: background .1s, border-left-color .1s;

				&.selected {
					border-left-color:  #7bbae3;
					background: #{'hsl(202deg 59% 95%)'};
					color: $bright-colors_black-text;
				}

				&:not(.selected):hover {
					background: #{'hsl(202deg 46% 98%)'};
				}

				> span {
					float: right;
					padding-right: 15px;
					color: #9e9e9e;
				}
			}
		}

		> .categories {
			flex: 1;

			> li {
				> h3 {
					font-family: $raleway;
					font-size: 13px;
					font-weight: bold;
					color: #6e6e6e;
					text-transform: uppercase;
					background-color: #f7f7f7;
					padding: 14px 20px 14px 20px;
					margin: 0;
				}

				> .hits {
					> .hit {
						padding: 12px 20px 14px 28px;
						line-height: 1.3;
						cursor: pointer;

						&:hover {
							background: #{'hsl(204deg 54% 96%)'};

							&:first-child {
								box-shadow: inset 0px 1px 0px #{'hsl(200deg 33% 93%)'};
							}
						}

						&:not(:last-child) {
							border-bottom: solid 1px #ebebeb;
						}

						> .title {
							font-family: $muli;
							font-size: 14px;
							font-weight: 400;
							color: $bright-colors_black-text;
							margin: 0;
						}

						> .description {
							font-size: 14px;
							font-weight: 300;
							color: #8e8e8e;
						}
					}

					> .more {
						padding: 15px 25px;
						cursor: pointer;
						@include mdi-icon(chevron-right, 18px, 5px, -2px);
						color: #6faff0;

						&:hover {
							background: #{'hsl(204deg 54% 96%)'};
						}
					}
				}

				&:not(:last-child) > .hits > li:last-child:hover {
					box-shadow: inset 0px -1px 0px #{'hsl(200deg 33% 93%)'};
				}

				&:not(:last-child) > .hits > li:last-child:first-child:hover {
					box-shadow: inset 0px -1px 0px #{'hsl(200deg 33% 93%)'},
					inset 0px 1px 0px #{'hsl(200deg 33% 93%)'};
				}
			}

			&.solo-category > li > .hits > .more {
				@include mdi-icon(chevron-down, 18px, 5px, -2px);
			}
		}
	}

	.no-matches {
		background-color: white;
		flex: 1;
		padding: 16px 53px;
	}
}