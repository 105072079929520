﻿@import "../treecontrol";
@import "../mixins/material-deisgn-icons";
@import "../mixins/ng-animate";

.item-group-picker {
	position: relative;

	> .treecontrol {
		@extend %treecontrol;

		> ul {
			max-height: 500px;
			min-height: 90px;
			overflow-y: auto;
		}

		.tree-label > a > .add {
			@include mdi-icon(subdirectory-arrow-right, 14px, 0);
			padding-right: 4px;
			vertical-align: top;	
			display: inline-block;
			margin-right: -8px;

			&:before {
				line-height: 18px;
				color: #dcdcdc;
                transition: opacity .2s;

				vertical-align: -1px;
				margin-right: 0;
				margin-left: 4px;
				border-radius: 18px;
				width: 18px;
				text-align: center;
				padding: 2px;
			}
				
			&:hover::before {
				background: $bright-colors_vibrant-blue;
				color: white;
			}
		}

		.label-selected > a > .add {
			&:hover:before {
				background: white;
				color: $bright-colors_vibrant-blue;
			}
			&:before {
				color: #8fc8e8;
			}
		}
	}

	> .filter {
		position: absolute;
		top: 13px;
		right: 14px;

		> input {
			position: absolute;
			top: 0;
			right: 0;
			box-shadow: 0 0 5px #e2e2e2;
			width: 160px;
			padding-right: 29px;

			@include animate-show(.3s);
		}

		> a {
			transition: background .2s, line-height .2s, right .2s;

			position: absolute;
			top: 0;
			right: 0;
			background: #f0f0f0;
			display: inline-block;
			border-radius: 50%;
			width: 42px;
			height: 42px;
			line-height: 40px;
			text-align: center;
			@include mdi-icon(magnify, 22px, 0, -3px, #606060);

			&:before {
				transition: color .2s, font-size .2s;
			}

			&:hover {
				background: $bright-colors_baby-blue-2;
				&:before {
					color: $bright-colors_vibrant-blue;
				}
			}
		}

		&.active {
			> a {
				background: transparent;
				line-height: 37px;
				right: -4px;

				&:before {
					font-size: 18px;
					color: #8e8e8e;
					vertical-align: -2px;
				}
			}
		}
	}

	> .new-root {
		@include mdi-icon(plus);

		display: block;
		border-top: solid 1px #ECECEC;
		padding: 15px 30px;
		color: #92B7C7;
		background-color: #F6FDFF;

		&:hover {
			background-color: #E0EEF2;
			color: #4B616A;
		}
	}
}