﻿@import "../font-awesome";
@import "../variables";

.address-editor {
    .address {
		width: 300px;
		margin: 20px;

		> label {
			font-size: 14px;
			display: block;
			margin-bottom: 10px;
			color: #353535;

			&:nth-of-type(3) {
				width: 30%;
				display: inline-block;
			}
			&:nth-of-type(4) {
				width: calc(70% - 15px);
				margin-left: 10px;
				display: inline-block;
			}

			> span {
				display: block;
				margin-bottom: 6px;
				margin-top: 14px;
			}

			input {
				box-sizing: border-box;
				width: 100%;
				background: #fafafa;

				&:focus {
					background: #f7fafc;
				}
			}

			.selectize-control > .selectize-input, .selectize-control.single > .selectize-input {
				background: #fafafa;
				box-sizing: border-box;
				height: 38px;
				line-height: 36px;
				padding: 0 12px;

				&:after, &.dropdown-active:after {
					margin-top: 0px;
				}

				&.focus {
					background: #f7fafc;
				}
			}
		}
	}
}