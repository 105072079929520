﻿

#organizationSettings-warehousesForm-view {
	h1 {
		margin-bottom: 5rem;
	}
	form {
        width: 710px;
        margin: 70px auto;

		[name=name] {
			width: 30ch;
		}

		section#address {
			.address-view {
				box-sizing: border-box;
				min-width: 310px;
				background: #f0f0f0;
				border-radius: 10px;
				display: inline-block;
				line-height: 1.5;
				padding: 20px 24px;
				white-space: pre-line;
			}
		}

		.trolley-settings {
			> .fields-container.split {
				width: 310px;

				.number-of-slots {
					width: 12ch;
				}

				.slot-identifiers {
					width: 22ch;
				}
			}

			.slot-colors-container {
				margin-top: 1.5em;

				.slot-colors {
					columns: 2;
					column-gap: 30px;
					margin: 2em 0;

					&.ng-leave-active > li {
						visibility: hidden;
					}

					> li {
						break-inside: avoid;
						margin: .5em 0;
						display: inline-block; // Så att marginalen appliceras på alla
						width: 100%;

						> label {
							display: flex;
							flex-direction: row;
							align-items: center;

							> span {
								width: 7ch;
							}

							> .selectize-control {
								flex: 1;
							}
						}
					}
				}
			}
		}
	}
}