﻿#organizationSettings-saleschannels_index-view > .page-content {
	max-width: 1100px;

	.empty {
		margin-top: -50px;

		> p {
			margin-bottom: 50px;
		}
	}

	.add {
		float: right;
	}
}