﻿	
@import "../font-awesome";
@import "../mixins/material-deisgn-icons";
@import "../mixins/callout-arrow";
@import "../mixins/ng-animate";
@import "../mixins/webkitscrollbar";
@import "../variables";

#items-purchase-form-view {

	.page-content {
		> h1 {
			margin-bottom: 60px;
		}

		> form {
			max-width: 1280px;
			margin: 0 auto;
			min-width: 1000px;

			> #supplier {
				width: 740px;

				.form-field.contact {
					position: relative;

					.add-contact-person {
						position: absolute;
					}

					.add-contact-person-email {
						position: absolute;
						left: calc(100% + 15px);
						width: 300px;
						top: 22px;
						font-weight: 600;

						@include mdi-icon(plus-circle, 22px, 7px, -3px, $color: #64b2de);
					}
				}

				.date {
					input {
						width: 182px;
					}
				}
			}

			> section {
				> div {
					margin-left: 170px;
				}
			}

			.items > div {
				> table {
					> thead {
						> tr {
							> th {
								// 860

								&:nth-child(1) { width: 39%; }
								
								&:nth-child(3) { width: 85px; }
								&:nth-child(4) { width: 50px; }
								&:nth-child(5) { width: 80px; }
								&:nth-child(6) { width: 80px; }
							}
						}
					}

					> tbody {
						> tr {
							&.animate-repeat.ng-enter {
								transition: opacity ease 0.2s 0.1s;
								opacity: 0;
							}

							&.animate-repeat.ng-enter.ng-enter-active {
								opacity: 1;
							}

							&.animate-repeat.ng-leave {
								transition: opacity ease 0.2s;
								opacity: 1;
							}

							&.animate-repeat.ng-leave.ng-leave-active {
								opacity: 0;
							}

							> td {
								input[readonly] {
									cursor: pointer;
								}

								&.item {
									position: relative;

									> .variants-callout {
										cursor: pointer;
										position: absolute;
										min-width: 24px;
										background-color: #8cbddf;
										color: white;
										height: 24px;
										border-radius: 24px;
										text-align: center;
										box-sizing: border-box;
										overflow: visible;
										font-size: 13px;
										margin-left: 11px;
										top: 7px;
										left: -45px;
										display: flex;
										align-items: center;
										justify-content: center;
										padding-bottom: 1px;

										&:after {
											content: '';
											position: absolute;
											top: 9px;
											right: -10px;
											border: solid 3px #8cbddf;
											border-right-color: transparent;
											border-top-color: transparent;
											border-bottom-color: transparent;
											display: inline-block;
											transform: scalex(2.6);
										}
									}
								}

								&.item.item-selected {
									position: relative;

									> span:not(.variants-callout) {
										background: white;
										cursor: default;
										position: absolute;
										top: 0;
										left: 0;
										bottom: 0;
										right: 0;
										padding: 9px 7px 8px;
										overflow: hidden;
										white-space: nowrap;
										text-overflow: ellipsis;

										> .stock-quantity {
											background: $bright-colors_baby-blue-2;
											padding: 2px 6px 3px;
											border-radius: 2em;
											color: lighten($bright-colors_dark-gray-text, 20%);
											margin-left: 2px;
										}
									}

									.selectize-control {
										display: none;
									}
								}

								&:nth-child(6) {
									> input {
										cursor: help;
									}
								}
							}

							&.variants-expanded {
								> td > input, > td > output, > td > div, > td:first-child > .selectize-control.item-auto-complete .selectize-input {
									border-bottom-color: #eae0e8;
								}

								> td:nth-child(2) {
									background-color: #FFF6FC;

									> div > span > i {
										@include mdi-icon(chevron-up, 18px, 0, -3px);
									}
								}
							}

							&.variants-row {
								> td {
									border: none;
									position: static;
									text-align: center;
									padding: 0;

									> div {
										position: relative;
										background-color: #fafafa;

										&.expanded {
											&:before { 
												content: '';
												display: block;
												position: absolute;
												left: 411px;
												background-color: #fafafa;
												width: 20px;
												height: 20px;
												transform: rotate(45deg);
												box-shadow: 0 0 12px rgba(0, 0, 0, 0.13);
												top: -10px;
											}

											&:after { 
												content: '';
												display: block;
												position: absolute;
												left: 402px;
												background-color: #fafafa;
												width: 38px;
												height: 16px;
												top: 0;
											}
										}

										> div {
											@include webkit-scrollbar($on-hover-only: true);

											max-height: 400px;
											padding: 30px;
											overflow: auto;
											margin: 20px 0;
											visibility: visible;
											box-sizing: border-box;
											text-align: left;
											border-left: solid 4px #92c4e6;
											box-shadow: 0 0 12px rgba(0, 0, 0, 0.13);

											@include animate-expand(400px, .3s, $extra-transition: (padding .3s, margin .3s));

											&.ng-hide-remove {
												padding: 0 30px;
												margin: 0;

												&.ng-hide-remove-active {
													padding: 30px;
													margin: 20px 0;
												}
											}

											&.ng-hide-add.ng-hide-add-active {
												padding: 0 30px;
												margin: 0;
											}

											> .close {
												color: $bright-colors_black-text;
												position: absolute;
												right: 15px;
												top: 15px;
												@include mdi-icon(close, $color: $bright-colors_dark-gray-text, $font-size: 18px, $vertical-align: -2px, $location: 'after');

												padding: 10px 18px;
												border-radius: 20px;
												background: transparent;

												transition: background .2s;
												background: #fafafa;

												&:hover {
													background: #ececec;
												}
											}

											> table {
												width: auto;
												margin-top: 25px;

												tr > th:last-child {
													width: 80px;
												}

												tr > th:nth-child(2) {
													width: 300px;
												}
											}
										}
									}
								}
							}
						}
					}
				}

				> #add-shipping-cost {
					position: relative;

					@include animate-show();

					> div {
						position: absolute;
						background-color: white;
						width: 300px;
						left: 500px;
						top: 10px;
						z-index: 2;
						box-shadow: 1px 2px 2px rgba(0,0,0,0.2);
						padding: 25px;
						@include callout-arrow(top-right, 12px, white);

						> i {
							position: absolute;
							top: 12px;
							right: 12px;
							height: 18px;
							width: 18px;
							@include mdi-icon(close, $font-size: 18px, $color: #aaa);
							cursor: pointer;
						}

						> h3 {
							font-weight: 600;
						}

						> p {
							color: $bright-colors_dark-gray-text;
							line-height: 1.4;
							margin-bottom: 20px;
						}
					}
				}

				> #total-sum {
					text-align: right;
					padding: 8px;
					padding-bottom: 0;
					color: gray;
					margin-top: 30px;
					padding-left: 0;
					overflow: hidden; // float-container

					.currency {
						width: 100px;
						text-align: left;
						float: left;
						margin-bottom: 0;
					}

					.vat {
						float: left;
						margin-left: 30px;
						margin-top: 34px;
					}

					.add-acquisition-cost {
						@include mdi-icon(plus-circle, 22px, 7px, -3px, $color: #64b2de);
						font-weight: 600;
						margin-bottom: 10px;
						display: inline-block;
					}

					#shipping-cost-amount {
						display: inline-block;
						padding-bottom: 10px;
						cursor: pointer;
						padding-top: 6px;

						@include mdi-icon(pencil, $color: $bright-colors_vibrant-blue);

						&:hover {
							color: black;
							@include mdi-icon(pencil, $color: $bright-colors_vibrant-blue);
						}

						> span {
							color: black;
							font-size: 14px;
							padding-left: 5px;
						}
					}

					> #total {
						display: inline-block;
						min-width: 190px;
						border-top: dotted 1px #dcdcdc;
						padding-top: 9px;
						margin-top: 5px;

						> output {
							color: black;
							font-weight: 700;
							font-size: 22px;
							letter-spacing: -1px;
							text-transform: none;
							margin-left: 8px;
							white-space: nowrap;
						}
					}
				}
			}

			> #addresses {
				> div {
					display: flex;
					flex-direction: row;
					justify-content: space-between;

					> div {
						flex: 1;

						&:first-child {
							margin-right: 20px;
						}

						&:last-child {
							margin-left: 20px;
						}

						> h2 {
							margin-bottom: 20px;
							font-family: $raleway;
							font-size: 14px;
							font-weight: 500;
							color: $bright-colors_dark-gray-text;
						}

						> .selectize-control {
							width: 250px;
							margin-bottom: 15px;
						}

						> .address {
							line-height: 1.3;
							color: #808080;
							font-size: 14px;

							> span {
								display: inline-block;
								float: left;

								&:first-child {
									display: block;
									float: none;
								}
							}
						}

						> .edit-address-button {
							@include mdi-icon(pencil, $margin-right: 0);
							display: block;
							float: left;
							clear: both;
							margin-top: 10px;
							font-size: 14px;
						}
					}
				}
			}

			> .button-row {
				margin-top: 60px;
				padding-left: 170px;

				>  confirm-button {
					float: right;
				}
			}
		}
	}
}

#edit-shipping-cost > form {
	padding: 25px;
	width: 240px;
	margin-bottom: 0;

	> label {
		display: block;
		font-size: 14px;
		font-weight: 500;
		margin-bottom: 25px;
		color: #6D6D6D;
		font-family: $muli;
	}

	> input {
		padding: 5px;
		width: 81px;
		line-height: 29px;
		margin-right: 22px;
	}

	> button {
		@extend .contrast;
	}
}

#supplier-contact-person-form > form {
	> div {
		width: 355px;

		> label {
			&:first-of-type {
				display: inline-block;
				width: 140px;
			}

			&:nth-of-type(2) {
				display: inline-block;
				width: 200px;
				margin-left: 10px;
			}

			&:nth-of-type(3) { margin: 10px 0 20px; }

			> input {
				box-sizing: border-box;
				width: 100%;
				margin-top: 4px;
			}
		}
	}
}

#purchase-order-new-supplier-form > form,
#purchase-order-new-warehouse-form > form {
	> div {
		width: 355px;
	}

	> .loading {
		> .throbber {
			margin: 38px auto 0;
		}

		> p {
			text-align: center;
			color: #5E5E5E;
			font-size: 15px;
			margin-top: 10px;
		}
	}
}