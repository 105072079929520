﻿@import "../variables";

.tabs {
	border-bottom: solid 1px $bright-colors_silver-stroke-on-white;
	padding-bottom: 12px;

	> a {
		color: $bright-colors_dark-gray-text;
		font-weight: 600;
		padding-bottom: 9px;

		transition: color .1s, border-bottom .1s;

		&:not(:last-child) {
			margin-right: 22px;
		}

		&:hover {
			border-bottom: solid 2px #d8d8d8;
		}

		&:active, &.active {
			color: $bright-colors_black-text;
			border-bottom: solid 2px $bright-colors_dim-blue;
			font-weight: 700;
		}
	}
}